<template>
    <div class="firm-candidate-view" data-spm-page-id="P_talent_search">
        <candidate-search
            ref="candidateSearch"
            :isSelectAll="isSelectAll"
            :checkedCandidates="checkedCandidates"
            :newCandidateList="newCandidateList"
            :isFirmSearch="true"
            :searchConditions="searchConditionList"
            :candidateTotal="pager.total"
            :titleSearchList="titleSearchList"
            :recommendTitleSearchList="recommendTitleSearchList"
            :companyNameList="companyNameList"
            @add-search-param="addSearchParam"
            @remove-search-label="removeSearchLabel"
            @clear-search="clearSearch"
            @handle-sort="handleSort"
            @get-checked-options="getCheckedOptions"
        ></candidate-search>
        <div class="candidate-content">
            <div class="candidates-left">
                <div
                    class="candidate-list" 
                    data-spm-area-id="A_search_result_list"
                    v-loading="candidateListLoading"
                >
                    <template v-if="newCandidateList.length > 0">
                        <candidate-item
                            v-for="(candidate, index) in newCandidateList"
                            :key="index + '-' + candidate.id"
                            :data-spm-e-data="listItemSpmData(candidate, index)"
                            :data-spm-e-id="`L_search_result_list_item.${pager.size}xP${pager.current}-${index+1}`"
                            :candidate="candidate"
                            :pager="pager"
                            :candidateIndex="index"
                            :searchWord="lightWordList"
                            :skillTagsSearchParams="searchParams.skillStandardTags"
                            @view-candidate-detail="handleViewCandidate"
                            @add-search-param="addSearchParam"
                            @remove-search-label="removeSearchLabel"
                            @recommend-candidate="recommendCandidate"
                            @communication-job="communicationJob"
                            @handleCheck="(value) => toggleCandidateSelect(candidate, value)"
                        ></candidate-item>
                            <!-- <el-checkbox-group class="batch-check" v-model="checkedCandidates" @change="handleCheckedCandidatesChange">
                                <el-checkbox v-for="(candidate, index) in newCandidateList" :label="candidate" :key="index + '-' + candidate.id">
                                    <candidate-item
                                        :data-spm-e-data="listItemSpmData(candidate, index)"
                                        :data-spm-e-id="`L_search_result_list_item.${pager.size}xP${pager.current}-${index+1}`"
                                        :candidate="candidate"
                                        :candidateIndex="index"
                                        :searchWord="lightWordList"
                                        :skillTagsSearchParams="searchParams.skillStandardTags"
                                        @view-candidate-detail="handleViewCandidate"
                                        @add-search-param="addSearchParam"
                                        @remove-search-label="removeSearchLabel"
                                        @recommend-candidate="recommendCandidate"
                                        @communication-job="communicationJob"
                                    ></candidate-item>
                                </el-checkbox>
                            </el-checkbox-group> -->
                        <!-- <candidate-item
                            :candidate="candidate"
                            :candidateIndex="index"
                            :searchWord="lightWordList"
                            v-for="(candidate, index) in newCandidateList"
                            :key="index + '-' + candidate.id"
                            :skillTagsSearchParams="searchParams.skillStandardTags"
                            @view-candidate-detail="handleViewCandidate"
                            @add-search-param="addSearchParam"
                            @remove-search-label="removeSearchLabel"
                            @recommend-candidate="recommendCandidate"
                            @communication-job="communicationJob"
                        ></candidate-item> -->
                    </template>
                    <div class="page-empty" v-else>
                        <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                        <p class="des">没有搜索到任何人才</p>
                    </div>
                    <!-- <div class="empty" v-else>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div> -->
                </div>
                <el-pagination
                    :current-page="pager.current"
                    :page-sizes="[20, 30, 50]"
                    :page-size="pager.size"
                    class="candidate-list-pagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pager.total"
                    v-if="newCandidateList.length > 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                    <!-- <span class="pagination-text">
                        <span>
                            前往
                            <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                            页
                        </span>
                        <span @click="handlePagerJump(pagerJump)">
                            跳转
                        </span>
                    </span> -->
                </el-pagination>
            </div>
            <div
                class="candidates-right"
            >
                <candidate-filter
                    v-loading="candidateListLoading"
                    ref="filterArea"
                    :filterType="filterType"
                    :filterItems="filterItems"
                    :filterObj="resultFilterMap"
                    :filterTransParams="resultFilterSelect"
                    :isShowCompanyNameFilter="isShowCompanyNameFilter"
                    v-show="AggregateType !== 3"
                ></candidate-filter>
            </div>
        </div>

        <recommend-dialog ref="recommendDialog" :type="0"></recommend-dialog>
        <add-communication-dialog ref="addCommunicationDialog"></add-communication-dialog>
    </div>
</template>
<script>
import CandidateSearch from '../component/candidate-search.vue';
import CandidateItem from '../component/candidate-item.vue';
import CandidateFilter from '../component/candidate-filter.vue';
import RecommendDialog from '#/component/common/dialog/recommendDialog.vue';
import AddCommunicationDialog from '#/component/common/dialog/add-communication-dialog.vue';
import { candidate as candidateUrl } from '#/js/config/api.json';
import moment from 'moment';
const resumeContextFilter = [
    {
        title: "locations",
        multiple: true
    }, 
    {
        title: "degree",
        multiple: false
    }, {
        title: "yearOfExperienceRegions",
        multiple: true
    }, {
        title: "skillStandardTags",
        multiple: true
    }, {
        title: "workExperienceCompaniesName",
        multiple: true
    }];
const recommentViewFilter = [
    {
        title: "customer",
        multiple: false
    }, {
        title: "recommendJobName",
        multiple: false
    }, {
        title: "recommendLocation",
        multiple: false
    }, {
        title: "recommendStatus",
        multiple: false
    }];

const defaultSearchLabels = {
    keywords: {
        name: "keywords",
        title: "关键词",
        value: ""
    },
    title: {
        name: "title",
        title: "职位",
        value: ""
    },
    notAnalyzedTitle: {
        name: "notAnalyzedTitle",
        title: "职位",
        value: ""
    },
    companyName: {
        name: "companyName",
        title: "公司",
        value: ""
    },
    notAnalyzedCompanyName: {
        name: "notAnalyzedCompanyName",
        title: "公司",
        value: ""
    },
    realName: {
        name: "realName",
        title: "姓名",
        value: ""
    },
    mobile: {
        name: "mobile",
        title: "手机",
        value: ""
    },
    email: {
        name: "email",
        title: "邮箱",
        value: ""
    },
    label: {
        name: "label",
        title: "标签",
        value: ""
    },
    creators: {
        name: "creators",
        title: "创建者",
        value: ""
    },
    updatedTime: {
        name: "updatedTime",
        title: "创建时间",
        value: ""
    },
    minAge: {
        name: "minAge",
        title: "最小年龄",
        value: ""
    },
    maxAge: {
        name: "maxAge",
        title: "最大年龄",
        value: ""
    },
    lastViewTime: {
        name: "lastViewTime",
        title: "我未浏览",
        value: ""
    },
    excludeCondition: {
        name: "excludeCondition",
        title: "排除情况",
        value: ""
    },
    backgroundCondition: {
        name: "backgroundCondition",
        title: "背景情况",
        value: ""
    },
    recommendTime: {
        name: "recommendTime",
        title: "推荐时间",
        value: ""
    },
    recommendJobName: {
        name: "recommendJobName",
        title: "推荐职位",
        value: ""
    },
    recommendLocation: {
        name: "recommendLocation",
        title: "推荐城市",
        value: ""
    },
    customer: {
        name: "customer",
        title: "客户",
        value: ""
    },
    recommenders: {
        name: "recommenders",
        title: "推荐人",
        value: ""
    },
    inquiryLogTime: {
        name: "inquiryLogTime",
        title: "寻访时间",
        value: ""
    },
    inquiryLogContent: {
        name: "inquiryLogContent",
        title: "寻访内容",
        value: ""
    },
    inquiryLogCreators: {
        name: "inquiryLogCreators",
        title: "寻访人",
        value: ""
    },
    jobHoppingOccasion: {
        name: "jobHoppingOccasion",
        title: "寻访意愿",
        value: ""
    },
    locations: {
        name: "locations",
        title: "城市",
        value: ""
    },
    expectationLocations: {
        name: "expectationLocations",
        title: "期望城市",
        value: ""
    },
    degree: {
        name: "degree",
        title: "学历",
        value: ""
    },
    yearOfExperienceRegions: {
        name: "yearOfExperienceRegions",
        title: "工作经验",
        value: ""
    },
    skillStandardTags: {
        name: "skillStandardTags",
        title: "技能",
        value: ""
    },
    workExperienceCompaniesName: {
        name: "workExperienceCompaniesName",
        title: "公司",
        value: ""
    },
    recommendStatus: {
        name: "recommendStatus",
        title: "推荐状态",
        value: ""
    },
    expectationTypeIds: {
        name: "expectationTypeIds",
        title: "看新一线机会",
        value: ""
    },
    expectationNewCityLocations: {
        name: "expectationNewCityLocations",
        title: "看新一线机会",
        value: ""
    },
    gender: {
        name: "gender",
        title: "性别",
        value: ""
    },
};

export default {
    componentName: 'FirmCandidate',
    components:{
        CandidateSearch,
        CandidateItem,
        RecommendDialog,
        AddCommunicationDialog,
        CandidateFilter
    },
    props:{},
    data() {
        return{
            isSelectAll: false,
            checkedCandidates: [],

            tbdResumeIds: [],
            resumesCompletionsList: [],
            newCandidateList: [],

            searchId: "",
            candidateList: [],
            pager: {
                current: 1,
                size: 20,
                total: 0
            },
            pagerJump: 0,
            isbyHotSearch: false,
            searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            searchParams: {
                locations: [],                      //结果筛选-城市
                degree: "",                         //结果筛选-学历，未知学历/高中及以下
                minDegree: "",                      //结果筛选-学历，高中以上学历
                yearOfExperienceRegions: [],        //结果筛选-工作经验
                skillStandardTags: [],              //结果筛选-技能
                workExperienceCompaniesName: [],    //结果筛选-公司
                recommendStatus: ""                 //结果筛选-推荐状态
            },
            searchConditionList: [],
            filterType: 1,
            filterItems: resumeContextFilter || [],
            resultFilterMap: {},
            resultFilterSelect: {
                locations: [],
                degree: "",
                yearOfExperienceRegions: [],
                skillStandardTags: [],
                workExperienceCompaniesName: [],
                customer: "",
                recommendJobName: "",
                recommendLocation: "",
                recommendStatus: ""
            },
            lightWordMap: {
                keywords: "",
                title: "",
                companyName: ""
            },
            titleSearchList: [],
            recommendTitleSearchList: [],
            companyNameList: [],
            candidateListLoading: false,
            isFromSort: false,
            isFirstSearch: true,
            isShowCompanyNameFilter: true,
            AggregateType: 1
        }
    },
    computed:{
        lightWordList() {
            let list = [];
            Object.values(this.lightWordMap).forEach(item => {
                if(item) {
                    item = item.trim();
                    if(/\s/g.test(item)) {
                        item = item.replace(/\s{2,}/g, " ");
                        item = item.split(" ");
                    } else if(/\//g.test(item)) {
                        item = item.split("/");
                    }
                    list = list.concat(item);
                }
            });
            return list;
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isTalent() {
            return this.userInfo.privilegeCodeList.includes('Talent');
        },
    },
    mounted(){
        if(!this.isAdministrator && !this.isTalent){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        this.$on('add-search-param', item => {
            this.addSearchParam([item], 3);
        });
        this.isFirstSearch = true;
        this.search();
    },
    methods: {
        listItemSpmData(candidate, index) {
            // 搜索查询条件不变更，点击人才项传的跟踪id（searchTraceId）不变，为上一个搜索查询的searchTraceId
            let searchTraceId = '';
            if(this.$store.getters.getSearchTraceId) {
                searchTraceId = this.$store.getters.getSearchTraceId;
            } else {
                searchTraceId = _generateUUID();
            }
            let listItemUrl = '';
            listItemUrl = `https://${window.location.host}/#/candidateDetail/${candidate.tbdResumeId}`;
            return `{
                "search_trace_id": "${searchTraceId}",
                "pagination_current": ${this.pager.current},
                "pagination_size": ${this.pager.size},
                "list_item_num": ${index+1},
                "list_item_url": "${listItemUrl}",
                "list_item_real_name": "${candidate.realName}"
            }`;
        },
        getCheckedOptions(data) {
            this.checkedCandidates = data;
            if(data.length == 0) {
                this.isSelectAll = false;

                this.newCandidateList.forEach(item => {
                    item.isSelected = false;
                });
            } else {
                this.isSelectAll = true;

                this.newCandidateList.forEach(item => {
                    item.isSelected = true;
                });
            }
        },
        toggleCandidateSelect(candidate, value) {
            candidate.isSelected = value;

            this.handleCheckedCandidatesChange();
        },
        handleCheckedCandidatesChange() {
            let list = [];
            this.newCandidateList.forEach(item => {
                if (item.isSelected) {
                    list.push(item);
                }
            })

            this.checkedCandidates = list;

            if(list.length == this.newCandidateList.length) { // 全选了
                this.isSelectAll = true;
            } else {
                this.isSelectAll = false;
            }
        },
        // handleCheckedCandidatesChange(value) {
        //     console.log(value);
        //     this.checkedCandidates = value;

        //     if(value.length == this.newCandidateList.length) { // 全选了
        //         this.isSelectAll = true;
        //     } else {
        //         this.isSelectAll = false;
        //     }
        // },

        // 获取简历完整度
        getResumesCompletions(){
            let params = {
                ResumeIds: this.tbdResumeIds
            }
            _request({
                url: candidateUrl.get_resumes_completions,
                method: "POST",
                baseURL: "LbdOpenApi",
                data: params
            }).then(res => {
                this.resumesCompletionsList = res;

                if(this.resumesCompletionsList.length > 0) {
                    this.candidateList.forEach(item => {
                        this.resumesCompletionsList.forEach(el => {
                            if(item.tbdResumeId.indexOf(el.key) != -1) {
                                item.resumesCompletions = el.value;
                            }
                        })
                    })
                    this.newCandidateList = this.candidateList;
                } else {
                    this.newCandidateList = this.candidateList;
                }
            }).catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.candidateListLoading = false;
            });
        },

        search(silent, isClearSearch = false) {
            this.checkedCandidates = [];
            this.newCandidateList.forEach(item => {
                item.isSelected = false;
            });
            this.isSelectAll = false;

            let searchJson = Object.assign({}, this.searchParams),
                candidateSearch = this.$refs.candidateSearch,
                tabType = this.$refs.candidateSearch.tabType;
            searchJson.AggregateType = tabType;
            if(!silent) {
                this.pager.current = 1;
            }
            searchJson.isFreshSearch = !silent;
           
            searchJson.sort = candidateSearch.candidateSearch.sort;
            if(!silent) {
                if(!this.isFirstSearch && !this.isFromSort){
                    candidateSearch.candidateSearch.sort = 0;
                    candidateSearch.sortText = '综合排序';
                    searchJson.sort = 0;
                } else if(this.isFirstSearch){
                    searchJson.sort = 1;
                }
            }
            searchJson.take = this.pager.size;
            searchJson.start = (this.pager.current - 1) * this.pager.size;
            searchJson.isNewSearch = true;
            searchJson.positionQueryType = 0;
            

            // 埋点
            // console.log(1111111111111111111111);
            // console.log('searchConditionList==========');
            // console.log(this.searchConditionList);
            // console.log(searchJson);
            let params = this.searchConditionList;
            if(params.length > 0 || isClearSearch || silent) {
                // console.log('执行啦----------');
                let searchTraceId = '';
                let eventId;
                let obj;
                // 搜索查询埋点
                if(!silent && (params.length > 0 || isClearSearch)) {
                    // console.log('aaaaaaaaaaa');
                    // 更新记录搜索查询的跟踪id（searchTraceId）在缓存中
                    searchTraceId = _generateUUID();
                    this.$store.dispatch('asyncUpdateSearchTraceId',searchTraceId)

                    eventId = 'E_enterprise_talent_search';
                    let searchFirmNameType;
                    if(searchJson.companyQueryType == '1') {
                        searchFirmNameType = '最近公司';
                    } else if(searchJson.companyQueryType == '2') {
                        searchFirmNameType = '曾在职公司';
                    } else {
                        searchFirmNameType = '全部公司';
                    }

                    let searchNotReadFilterMonth = '';
                    let searchExcludeSetting = [];
                    let searchTalentBackground = [];
                    let searchNewFirstTierCityOpportunityTiming = [];
                    let searchNewFirstTierCityOpportunityLocation = [];

                    let searchRecommendCity = '';
                    let searchRecommendCustomerName = '';
                    let searchRecommendJobName = '';

                    let filterLocation = [];
                    let filterDegree = '';
                    let filterYearExperience = [];

                    let filterCustomer = '';
                    let filterRecommendLocation = '';
                    let filterRecommendStatus = '';
                    params?.forEach(param => {
                        switch(param.title) {
                            case 'lastViewTime':
                                searchNotReadFilterMonth = param.value;
                                break;
                            case 'excludeCondition':
                                searchExcludeSetting = param.value.split(',');
                                break;
                            case 'backgroundCondition':
                                searchTalentBackground = param.value.split(',');
                                break;
                            case 'expectationTypeIds':
                                searchNewFirstTierCityOpportunityTiming = param.value.split(',');
                                break;
                            case 'expectationNewCityLocations':
                                searchNewFirstTierCityOpportunityLocation = param.value.split(',');
                                break;

                            case 'recommendLocation':
                                searchRecommendCity = param.value;
                                filterRecommendLocation = param.value;
                                break;
                            case 'customer':
                                searchRecommendCustomerName = param.value;
                                filterCustomer = param.value;
                                break;

                            case 'locations':
                                filterLocation = param.value.split(',');
                                break;
                            case 'degree':
                                filterDegree = param.value;
                                break;
                            case 'yearOfExperienceRegions':
                                filterYearExperience = param.value.split(',');
                                break;
                            
                            case 'recommendStatus':
                                filterRecommendStatus = param.value;
                                break;
                        }
                    });

                    obj = {
                        element_spm: "P_talent_search@A_query_search@L_enter_cmd",
                        // search_trace_id: _generateUUID(),
                        search_trace_id: searchTraceId,
                        // 关键词类型
                        search_keyword_type: searchJson.keywordFilterType == '1' ? '全部关键字' : '任一关键字',
                        // 关键词
                        search_keyword: searchJson.keywords,
                        // 职位名称
                        search_job_title: searchJson.notAnalyzedTitle || searchJson.title,
                        // 公司名称类型
                        search_firm_name_type: searchFirmNameType,
                        // 公司名称
                        search_firm_name: searchJson.notAnalyzedCompanyName || searchJson.companyName,
                        // 姓名
                        search_real_name: searchJson.realName,
                        // 手机号
                        search_mobile: searchJson.mobile,
                        // 邮箱
                        search_email: searchJson.email,
                        // 标签
                        search_tag: searchJson.label,
                        // 创建者
                        search_creator: searchJson.creatorIds?.length > 0 ? searchJson.creatorIds : ['全部成员'],
                        // 创建时间范围
                        search_create_date_range: [searchJson.updatedStartTime, searchJson.updatedEndTime],
                        // 年龄范围
                        search_age_range: [String(searchJson.minAge), String(searchJson.maxAge)],
                        // 性别
                        search_gender: searchJson.gender == '1' ? '男' : (searchJson.gender == '2' ? '女' : '不限'),
                        // 看新一线城市机会时机
                        // search_new_first_tier_city_opportunity_timing: searchJson.expectationTypeIds,
                        search_new_first_tier_city_opportunity_timing: searchNewFirstTierCityOpportunityTiming,
                        // 搜索看新一线城市机会所在城市
                        // search_new_first_tier_city_opportunity_location: searchJson.expectationNewCityLocations,
                        search_new_first_tier_city_opportunity_location: searchNewFirstTierCityOpportunityLocation,
                        // 未浏览的时间范围
                        // search_not_read_filter_month: searchJson.lastViewTime,
                        search_not_read_filter_month: searchNotReadFilterMonth,
                        // 排除设置
                        // search_exclude_setting: [String(searchJson.isExcludeOnboardGuarantee), String(searchJson.isExcludeEmptyMobile)],
                        search_exclude_setting: searchExcludeSetting,
                        // 背景情况
                        search_talent_background: searchTalentBackground,
                        // 排序
                        search_sort_by: searchJson.sort == 0 ? '综合排序' : '更新时间排序',
                        // 求职中的
                        search_only_in_hunting: searchJson.isJobHunting,

                        // 推荐时间
                        search_recommend_time: [searchJson.recommendStartTime, searchJson.recommendEndTime],
                        // 推荐职位名称
                        search_recommend_job_name: searchJson.recommendJobName,
                        // 推荐城市
                        search_recommend_city: searchRecommendCity,
                        // 推荐客户名称
                        search_recommend_customer_name: searchRecommendCustomerName,
                        // 推荐人
                        search_referrer: searchJson.recommendCreatorIds?.length > 0 ? searchJson.recommendCreatorIds : ['全部成员'],

                        // 寻访时间
                        search_inquire_time: [searchJson.inquiryLogStartTime, searchJson.inquiryLogEndTime],
                        // 寻访内容
                        search_inquire_content: searchJson.inquiryLogContent,
                        // 寻访人
                        search_referrer: searchJson.inquiryLogCreatorIds?.length > 0 ? searchJson.inquiryLogCreatorIds : ['全部成员'],
                        // 求职意愿
                        search_job_intention: searchJson.jobHoppingOccasion,

                        // 现居城市筛选
                        filter_location: filterLocation,
                        // 学历筛选
                        filter_degree: filterDegree,
                        // 工作经验筛选
                        filter_year_experience: filterYearExperience,
                        // 技能筛选
                        filter_skill: searchJson.skillStandardTags,
                        // 公司筛选
                        filter_company_name: searchJson.workExperienceCompaniesName,

                        // 客户筛选
                        filter_customer: filterCustomer,
                        // 推荐职位名称筛选
                        filter_recommend_job_name: searchJson.recommendJobName,
                        // 推荐城市筛选
                        filter_recommend_location: filterRecommendLocation,
                        // 推荐状态筛选
                        filter_recommend_status: filterRecommendStatus,
                    }
                }
                // 分页埋点
                if(silent) {
                    // console.log('bbbbbbbbbbb');
                    // 搜索查询条件不变更，点击分页埋点传的跟踪id（searchTraceId）不变，为上一个搜索查询的searchTraceId
                    if(this.$store.getters.getSearchTraceId) {
                        searchTraceId = this.$store.getters.getSearchTraceId;
                    } else {
                        searchTraceId = _generateUUID();
                    }

                    eventId = 'E_enterprise_talent_pagination_search';
                    obj = {
                        element_spm: "P_talent_search@A_enterprise_talent_search_pagination@L_enterprise_talent_pagination",
                        // search_trace_id: _generateUUID(),
                        search_trace_id: searchTraceId,
                        pagination_current: this.pager.current,
                        pagination_size: this.pager.size,
                    }
                }
                let json = {
                    eventId: eventId,
                    extendProperties: obj,
                }
                console.log(json);
                window.BazaCommonTrack.generateCustomEvent(json);
            }
            
            this.candidateListLoading = true;
            _request({
                url: candidateUrl.firm_candidate_search,
                method: "POST",
                baseURL: "LbdOpenApi",
                headers: {
                    "Content-Type": "application/json"
                },
                data: searchJson
            }).then(res => {
                this.filterType = tabType;
                if(tabType == 1) {
                    this.filterItems = resumeContextFilter;
                } else if(tabType == 2) {
                    this.filterItems = recommentViewFilter;
                }
                if(this.lightWordList && this.lightWordList.length > 0) {
                    let keywordReg = "",
                        lightWords = this.lightWordList;
                    res.list.forEach(item => {
                        for(let k = 0; k < lightWords.length; k++) {
                            keywordReg = lightWords[k].replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
                            keywordReg = new RegExp(keywordReg, 'ig');
                            for(let i = 0; i < 2; i++) {
                                if(item.educationExperiences && item.educationExperiences[i]) {
                                    item.educationExperiences[i] = item.educationExperiences[i].replace(keywordReg, `<span class="text-warning">${lightWords[k]}</span>`);
                                }
                                if(item.workExperienceInfo && item.workExperienceInfo[i]) {
                                    item.workExperienceInfo[i] = item.workExperienceInfo[i].replace(keywordReg, `<span class="text-warning">${lightWords[k]}</span>`);
                                }
                            }
                        }
                    })
                }
                this.pager.total = res.total;
                if (res.list && res.list.length > 0) {
                    res.list.forEach(item => {
                        item.isSelected = false;
                    });
                }
                this.candidateList = res.list || [];
                this.resultFilterMap = res.categoryGroupedItemsDic;
                this.titleSearchList = res.titleGroupedItems;
                this.companyNameList = res.companyGroupedItems;
                this.recommendTitleSearchList = res.categoryGroupedItemsDic && res.categoryGroupedItemsDic.recommendJobName ? res.categoryGroupedItemsDic.recommendJobName : [];
                this.AggregateType = tabType;
                if(!silent) {
                    this.searchId = res.searchId;
                }
                if(this.isFirstSearch) {
                    this.isFirstSearch = false;
                }

                // 每次查找之后都把tbdResumeIds置空
                this.tbdResumeIds = [];
                this.candidateList.forEach( el => {
                    if (el.completion == 0 || el.completion == null ) {
                        this.tbdResumeIds.push(el.tbdResumeId);
                    } else {
                        el.resumesCompletions = el.completion;
                    }
                })
                this.getResumesCompletions();

            }).catch(err => {
                this.candidateListLoading = false;
                console.log(err);
            })
            .finally(() => {
                
            });
        },
        addSearchParam(params, searchType, isSearch = true) {
            let candidateSearch = this.$refs.candidateSearch;
            params.forEach(param => {
                switch(param.title) {
                    case 'keywords':
                    case 'title':
                    case 'companyName':
                        this.lightWordMap[param.title] = param.value;
                        break;
                }
                this.searchLabels[param.title].value = param.value;
                if(param.data) this.searchLabels[param.title].data = param.data;
            });
            
            // 修复现城市和推荐城市互斥问题
            const _tempCandidateSearch = JSON.parse(JSON.stringify(candidateSearch.candidateSearch));
            delete _tempCandidateSearch.locations;
            Object.assign(this.searchParams, _tempCandidateSearch);
            this.searchParams.gender = this.searchParams.gender == '' ? null: +this.searchParams.gender;
            if(searchType == 3) {
                //右侧搜索
                this.scrollListTop();

                this.updateInputSearchLabel(params[0].title);
                if(params[0].title == 'customer') {
                    candidateSearch.$refs.customerSelect.customerId = this.searchParams.customerId = candidateSearch.candidateSearch.customerId = params[0].data.toString() ? params[0].data : "";
                    candidateSearch.customerName = params[0].value;
                } else if(params[0].title == 'recommendJobName') {
                    this.searchParams.recommendJobName = candidateSearch.candidateSearch.recommendJobName = candidateSearch.recommendJobName = params[0].data.toString() ? params[0].data : "";
                } else if(params[0].title == 'recommendLocation') {
                    candidateSearch.$refs.citySelect.setDefaultCity(+params[0].data);
                    this.searchParams.recommendLocation = candidateSearch.candidateSearch.recommendLocation = params[0].data.toString() ? parseInt(params[0].data) : "";
                } else if(params[0].title == 'degree') {
                    if(parseInt(params[0].data) > 1) {
                        this.searchParams.minDegree = params[0].data;
                        this.searchParams.degree = "";
                    } else {
                        this.searchParams.minDegree = "";
                        this.searchParams.degree = params[0].data;
                    }
                } else {
                    this.searchParams[params[0].title] = params[0].data;
                }
                this.$set(this.resultFilterSelect, params[0].title, params[0].data);
            }
            if(this.searchParams.companyName || this.searchParams.notAnalyzedCompanyName) {
                this.isShowCompanyNameFilter = false;
            } else {
                this.isShowCompanyNameFilter = true;
            }
            if(isSearch) {
                this.updateSearchLabel(searchType == 1);
                this.search(false);
            }
        },
        
        updateSearchLabel(isClearFilter) {
            let list = [],
                params = this.searchParams;
            if(isClearFilter) {
                // let filterItemTitles = ["degree", "recommendStatus", "locations", "yearOfExperienceRegions", "skillStandardTags", "workExperienceCompaniesName"];
                // filterItemTitles.forEach(title => {
                //     if(title == 'degree' || title == 'recommendStatus') {
                //         this.$set(this.searchParams, title, "");
                //     } else {
                //         this.$set(this.searchParams, title, []);
                //     }
                // })
            }
            // console.log(params);
            Object.keys(params).forEach(item => {
                if(!params[item] || params[item].toString() == "") {
                    switch(item) {
                        case 'keywords':
                            this.lightWordMap.keywords = "";
                            this.removeSearchLabel('keywords');
                        case 'title':
                            if(!params.title) {
                                this.lightWordMap.title = "";
                                this.removeSearchLabel('title');
                            }
                            break;
                        case 'notAnalyzedTitle':
                            if(!params.notAnalyzedTitle) {
                                this.lightWordMap.title = "";
                                this.removeSearchLabel('notAnalyzedTitle');
                            }
                            break;
                        case "companyName":
                            if(!params.companyName) {
                                this.lightWordMap.companyName = "";
                                this.removeSearchLabel('companyName');
                            }
                            break;
                        case 'notAnalyzedCompanyName':
                            if(!params.notAnalyzedCompanyName) {
                                this.lightWordMap.companyName = "";
                                this.removeSearchLabel('notAnalyzedCompanyName');
                            }
                            break;
                        case 'degree':
                        case 'minDegree':
                            if(params.degree === "" && params.minDegree === "") {
                                this.removeSearchLabel('degree');
                            }
                            break;
                        case 'creatorIds':
                            this.removeSearchLabel('creators');
                            break;
                        case 'updatedStartTime':
                        case 'updatedEndTime':
                            this.removeSearchLabel('updatedTime');
                            break;
                        case 'isExcludeOnboardGuarantee':
                            if(this.searchLabels.excludeCondition.value.indexOf('在保护期的人选') > -1) {
                                this.searchLabels.excludeCondition.value = this.searchLabels.excludeCondition.value.replace('在保护期的人选', "");
                                this.searchLabels.excludeCondition.value = this.searchLabels.excludeCondition.value.replace(', ', "");
                            }
                            break;
                        case 'isExcludeEmptyMobile':
                            if(this.searchLabels.excludeCondition.value.indexOf('无效号码') > -1) {
                                this.searchLabels.excludeCondition.value = this.searchLabels.excludeCondition.value.replace('无效号码', "");
                            }
                            break;
                        case 'isFamousCompany':
                            if(this.searchLabels.backgroundCondition.value.indexOf('名企') > -1) {
                                this.searchLabels.backgroundCondition.value = this.searchLabels.backgroundCondition.value.replace('名企', "");
                                this.searchLabels.backgroundCondition.value = this.searchLabels.backgroundCondition.value.replace(', ', "");
                            }
                            break;
                        case 'educationTags':
                            if(this.searchLabels.backgroundCondition.value.indexOf('985/211') > -1) {
                                this.searchLabels.backgroundCondition.value = this.searchLabels.backgroundCondition.value.replace('985/211', "");
                            }
                            break;
                        case 'recommendStartTime':
                        case 'recommendEndTime':
                            this.removeSearchLabel('recommendTime');
                            break;
                        case 'customerId':
                            this.removeSearchLabel('customer');
                            break;
                        case 'recommendCreatorIds':
                            this.removeSearchLabel('recommenders');
                            break;
                        case 'inquiryLogStartTime':
                        case 'inquiryLogEndTime':
                            this.removeSearchLabel('inquiryLogTime');
                            break;
                        case 'inquiryLogCreatorIds':
                            this.removeSearchLabel('inquiryLogCreators');
                            break;
                        // case 'expectationTypeIds':
                        //     this.removeSearchLabel('expectationTypeIds');
                        //     break;
                        case 'expectationNewCityLocations':
                            // expectationNewCityAddress不为null 说明新一线下拉选择了'其他'选项，就算expectationNewCityLocations为[]时，也不去removeSearchLabel，只有在expectationNewCityAddress为null时才去removeSearchLabel
                            if(params.expectationNewCityAddress == null) {
                                this.removeSearchLabel('expectationNewCityLocations');
                            }
                            break;
                        default:
                            // console.log('zhixing--------');
                            // console.log(item);
                            this.removeSearchLabel(item);
                    }
                }
                if(item == 'customerId' && params[item]) {
                    this.resultFilterSelect.customer = params[item];
                }
                if((item == 'recommendJobName' || item == 'recommendLocation') && params[item]) {
                    this.resultFilterSelect[item] = params[item];
                }
            });
            Object.values(this.searchLabels).forEach(label => {
                if(label.value) {
                    if(label.name == 'companyName' || label.name == 'notAnalyzedCompanyName') {
                        list.push({
                            title: label.name,
                            text: params.companyQueryType == 3 ? '全部公司' : (params.companyQueryType == 2 ? '曾在职公司' : '最近公司'),
                            value: label.value,
                            data: label.data,
                        })
                    } else if(label.name == 'keywords') {
                        list.push({
                            title: label.name,
                            text: params.keywordFilterType == 1 ? '全部关键字' : '任一关键字',
                            value: label.value,
                            data: label.data,
                        })
                    } else {
                        list.push({
                            title: label.name,
                            text: label.title,
                            value: label.value,
                            data: label.data,
                        })
                    }
                }
            });
            // console.log(list);
            this.searchConditionList = list;
        },
        removeSearchLabel(title) {
            switch(title){
                case 'customer':
                    this.resultFilterSelect.customer = "";
                    this.searchParams.customerId = "";
                    this.$refs.candidateSearch.$refs.customerSelect.customerId = "";
                    break;
                case 'degree':
                    this.resultFilterSelect.degree = "";
                    this.searchParams.degree = "";
                    this.searchParams.minDegree = "";
                    break;
                case 'recommendJobName':
                case 'recommendLocation':
                case 'recommendStatus':
                    this.resultFilterSelect[title] = "";
                    this.searchParams[title] = "";
                    break;
                case 'locations':
                case 'yearOfExperienceRegions':
                case 'skillStandardTags':
                case 'workExperienceCompaniesName':
                    this.resultFilterSelect[title] = [];
                    this.searchParams[title] = [];
                    break;
            }
            if(this.searchLabels.hasOwnProperty(title)) {
                this.searchLabels[title].value = "";
            }
        },
        clearSearch(isSearch = true) {
            this.searchLabels = JSON.parse(JSON.stringify(defaultSearchLabels));
            let candidateSearch = this.$refs.candidateSearch;
            Object.assign(this.searchParams, candidateSearch.candidateSearch, {
                locations: [],                      //结果筛选-城市
                degree: "",                         //结果筛选-学历，未知学历/高中及以下
                minDegree: "",                      //结果筛选-学历，高中以上学历
                yearOfExperienceRegions: [],        //结果筛选-工作经验
                skillStandardTags: [],              //结果筛选-技能
                workExperienceCompaniesName: [],    //结果筛选-推荐状态
                recommendStatus: ""
            });
            this.searchConditionList = [];
            this.resultFilterSelect = {
                locations: [],
                degree: "",
                yearOfExperienceRegions: [],
                skillStandardTags: [],
                workExperienceCompaniesName: [],
                customer: "",
                recommendJobName: "",
                recommendLocation: "",
                recommendStatus: ""
            };
            // if(isSearch) this.search(false);
            if(isSearch) this.search(false, true);
        },
        handleViewCandidate(index) {
            let logData = {
                searchId: this.searchId,
                pageIndex: this.pager.current,
                itemRank: index,
                candidateId: this.candidateList[index].id,
                tbdResumeId: this.candidateList[index].tbdResumeId
            };
            _request({
                url: candidateUrl.log_search,
                method: "POST",
                // headers: window._pageViewId,
                data: logData,
                throwBusinessError: true
            });
            // lbdTrack.track('enterpriseViewDetail',{
            //     "candidateId": this.candidateList[index].id,
            //     "isbyHotSearch": this.isbyHotSearch,
            //     "type":"detail"
            // });
            _tracker.track('enterpriseViewDetail',{
                "candidateId": this.candidateList[index].id,
                "isbyHotSearch": this.isbyHotSearch,
                "type":"detail"
            });
        },
        recommendCandidate(id) {
            this.$refs.recommendDialog.show(id);
        },
        communicationJob(id) {
            this.$refs.addCommunicationDialog.show({
                candidateId: id
            });
        },
        scrollListTop() {
            if(document.querySelector(".search-param-container")) {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-content').offsetTop - document.querySelector(".search-param-container").clientHeight - 20 - 60;
            } else {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-content').offsetTop - 60;
            }

            // document.querySelector('.site-candidates').scrollTop = 0;
        },
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.$nextTick(() => {
                this.search(true);
                this.scrollListTop();
            })
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.scrollListTop();
            this.search(true);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
        handleSort() {
            this.isFromSort = true;
        },
        updateInputSearchLabel(title) {
            let inputItems = ['keywords', 'title', 'companyName', 'realName', 'mobile', 'email', 'recommendJobName', 'inquiryLogContent'],
                params = this.searchParams;
            if(inputItems.indexOf(title) > -1) {
                inputItems.splice(inputItems.indexOf(title), 1);
            }
            inputItems.forEach(item => {
                if(item == 'keywords') {
                    this.lightWordMap.keywords = params.keywords;
                }
                if(item == 'title') {
                    this.lightWordMap[item] = params.title ? params.title : params.notAnalyzedTitle;
                    if(!params.title && !params.notAnalyzedTitle) {
                        this.removeSearchLabel('title');
                    } else {
                        this.searchLabels.title.value = params.title ? params.title : params.notAnalyzedTitle;
                    }
                } else if(item == 'companyName') {
                    this.lightWordMap[item] = params.companyName ? params.companyName : params.notAnalyzedCompanyName;
                    if(!params.companyName && !params.notAnalyzedCompanyName) {
                        this.removeSearchLabel('companyName');
                    } else {
                        this.searchLabels.companyName.value = params.companyName ? params.companyName : params.notAnalyzedCompanyName;
                    }
                } else if(!params[item]) {
                    this.removeSearchLabel(item);
                } else {
                    this.searchLabels[item].value = params[item];
                }
            });
        }
    }
};

</script>

<style lang="scss" scope>
// @media screen and (max-width: 1400px) {
//     .candidates-left {
//         width: calc(100% - 350px) !important;
//     }
//     .candidates-right {
//         width: 330px !important;
//     }
// }
// @media screen and (min-width: 1400px) and (max-width: 1680px) {
//     .candidates-left {
//         width: calc(100% - 380px) !important;
//     }
//     .candidates-right {
//         width: 360px !important;
//     }
// }

.firm-candidate-view{
    .candidate-content {
        display: flex;
        position: relative;

        .candidates-left {
            flex: 1;
            // width: 855px;
            width: calc(100% - 380px);
            // margin-right: 20px;
            // background-color: #ffffff;
            // border-radius: 0 0 8px 8px;
            .candidate-list {
                // padding: 0 20px;
                .batch-check {
                    .el-checkbox {
                        width: 100%;
                        &:hover {
                            background-color: #F6F6F6;
                        }
                    }
                }
                .el-checkbox__label {
                    width: 100%;
                }

                .candidate-item-container {
                    background: #FFFFFF;
                    border-radius: 10px;
                    border: 1px solid #38BC9D;
                }
            }
            .el-loading-spinner {
                top: 21px;
            }

            .candidate-list-pagination.el-pagination {
                text-align: center;
                padding-top: 6px;

                button.btn-prev, button.btn-next {
                    background-color: transparent;
                }

                button:disabled {
                    background-color: transparent;
                }

                .el-pager li {
                    background-color: transparent;
                }
            }
            // .candidate-list-pagination.el-pagination {
            //     margin: 40px 0 20px 0;
            //     padding: 0 20px 32px;
            //     font-weight: normal;
            //     .btn-next, .btn-prev {
            //         height: 38px;
            //         border: 1px solid;
            //     }
            //     .btn-prev {
            //         border-right: none;
            //         border-radius: 4px 0 0 4px;
            //     }
            //     .btn-next {
            //         border-left: none;
            //         border-radius: 0 4px 4px 0;
            //     }
            //     .el-pagination__sizes .el-input{
            //         width: 87px;
            //         .el-input__inner {
            //             color: #666;
            //             .el-select__caret{
            //                 color: #999;
            //             }
            //         }
            //     }
            //     .el-pager {
            //         border-top: 1px solid;
            //         border-bottom: 1px solid;
            //         li {
            //             min-width: 36px;
            //             height: 36px;
            //             line-height: 36px;
            //             font-size: 14px;
            //         }
            //     }
            //     .btn-next, .btn-prev, .el-pager {
            //         border-color: #DDDDDD;
            //     }
            //     .el-input__inner, .el-pagination__total, .el-pagination__jump {
            //         height: 38px;
            //         line-height: 38px;
            //         font-size: 14px;
            //     }
            //     .pagination-text{
            //         color: #999;
            //         span{
            //             height: 38px;
            //             line-height: 38px;
            //             font-size: 14px;
            //             .el-input{
            //                 width: 48px;
            //                 margin: 0 5px;
            //                 &__inner{
            //                     height: 38px;
            //                     line-height: 38px;
            //                     padding: 0 5px;
            //                 }
            //             }
            //             &:nth-of-type(2){
            //                 margin-left: 5px;
            //                 color: $primary;
            //                 cursor: pointer;
            //             }
            //         }
            //     }
            //     // .el-pagination__total,
            //     // .el-pagination__sizes,
            //     // .pagination-text {
            //     //     float: right;
            //     // }
            // }
        }
        .candidates-right {
            flex-shrink: 0;
            // width: 325px;
            width: 370px;
            margin-left: 10px;
            height: min-content;
            // background: #f9f9f9;
            background-color: #ffffff;
            border-radius: 8px;
            // position: relative;
            // position: absolute;
            // top: -60px;
            // right: 0;
            float: right;
            // margin-top: -60px;
            .el-loading-spinner {
                display: none;
            }
        }
        .page-empty{
            width: 100%;
            // height: 383px;
            min-height: calc(100vh - 436px);
            background: white;
            border-radius: 10px;
            text-align: center;
            >img{
                width: 158px;
                height: 178px;
                margin: 73px auto 0;
            }
            .des{
                margin: 24px auto 0;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 20px;
            }
        }
    }
}
</style>

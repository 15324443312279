var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "firm-candidate-view",
      attrs: { "data-spm-page-id": "P_talent_search" },
    },
    [
      _c("candidate-search", {
        ref: "candidateSearch",
        attrs: {
          isSelectAll: _vm.isSelectAll,
          checkedCandidates: _vm.checkedCandidates,
          newCandidateList: _vm.newCandidateList,
          isFirmSearch: true,
          searchConditions: _vm.searchConditionList,
          candidateTotal: _vm.pager.total,
          titleSearchList: _vm.titleSearchList,
          recommendTitleSearchList: _vm.recommendTitleSearchList,
          companyNameList: _vm.companyNameList,
        },
        on: {
          "add-search-param": _vm.addSearchParam,
          "remove-search-label": _vm.removeSearchLabel,
          "clear-search": _vm.clearSearch,
          "handle-sort": _vm.handleSort,
          "get-checked-options": _vm.getCheckedOptions,
        },
      }),
      _c("div", { staticClass: "candidate-content" }, [
        _c(
          "div",
          { staticClass: "candidates-left" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.candidateListLoading,
                    expression: "candidateListLoading",
                  },
                ],
                staticClass: "candidate-list",
                attrs: { "data-spm-area-id": "A_search_result_list" },
              },
              [
                _vm.newCandidateList.length > 0
                  ? _vm._l(_vm.newCandidateList, function (candidate, index) {
                      return _c("candidate-item", {
                        key: index + "-" + candidate.id,
                        attrs: {
                          "data-spm-e-data": _vm.listItemSpmData(
                            candidate,
                            index
                          ),
                          "data-spm-e-id": `L_search_result_list_item.${
                            _vm.pager.size
                          }xP${_vm.pager.current}-${index + 1}`,
                          candidate: candidate,
                          pager: _vm.pager,
                          candidateIndex: index,
                          searchWord: _vm.lightWordList,
                          skillTagsSearchParams:
                            _vm.searchParams.skillStandardTags,
                        },
                        on: {
                          "view-candidate-detail": _vm.handleViewCandidate,
                          "add-search-param": _vm.addSearchParam,
                          "remove-search-label": _vm.removeSearchLabel,
                          "recommend-candidate": _vm.recommendCandidate,
                          "communication-job": _vm.communicationJob,
                          handleCheck: (value) =>
                            _vm.toggleCandidateSelect(candidate, value),
                        },
                      })
                    })
                  : _c("div", { staticClass: "page-empty" }, [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                        },
                      }),
                      _c("p", { staticClass: "des" }, [
                        _vm._v("没有搜索到任何人才"),
                      ]),
                    ]),
              ],
              2
            ),
            _vm.newCandidateList.length > 0
              ? _c("el-pagination", {
                  staticClass: "candidate-list-pagination",
                  attrs: {
                    "current-page": _vm.pager.current,
                    "page-sizes": [20, 30, 50],
                    "page-size": _vm.pager.size,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.pager.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "candidates-right" },
          [
            _c("candidate-filter", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.candidateListLoading,
                  expression: "candidateListLoading",
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.AggregateType !== 3,
                  expression: "AggregateType !== 3",
                },
              ],
              ref: "filterArea",
              attrs: {
                filterType: _vm.filterType,
                filterItems: _vm.filterItems,
                filterObj: _vm.resultFilterMap,
                filterTransParams: _vm.resultFilterSelect,
                isShowCompanyNameFilter: _vm.isShowCompanyNameFilter,
              },
            }),
          ],
          1
        ),
      ]),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 0 } }),
      _c("add-communication-dialog", { ref: "addCommunicationDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { candidate as candidateUrl } from '#/js/config/api.json'

export default {

    addSearchFilters(params) {
        return _request({
            url: candidateUrl.add_search_filters,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    getSearchRecordList(type) {
        return _request({
            url: candidateUrl.get_search_record_list.replace('%p', type),
            method: "GET",
            baseURL: "LbdOpenApi",
        });
    },

    deleteSearchFilters(id) {
        return _request({
            url: candidateUrl.delete_search_filters.replace('%p', id),
            method: "DELETE",
            baseURL: "LbdOpenApi",
        });
    },

    candidateSimpleResumeDetail(id) {
        return _request({
            url: candidateUrl.candidate_simple_resume_detail.replace('%p', id),
            method: "GET",
            throwBusinessError: true
        });
    },

    

};

<template>
    <div
        v-if="enableShow"
        class="filter-item"
    >
        <div class="item-header">
            <!-- <span class="item-title" :class="titleIcon"> -->
            <span class="item-title" v-if="headerTextMap[title].indexOf('(支持多选)') == -1">
                {{title | headerTextFilter}}
            </span>
            <span class="item-title" v-else>
                {{headerTextMap[title].replace('(支持多选)', '')}}
                <i>(支持多选)</i>
            </span>
            <!-- <span class="city-tip" v-if="enableShowCityTip">
                <span class="city-tip-prend"></span>
                新版城市搜索在这里！
                <span class="city-tip-close" @click="hideCityTip">×</span>
            </span> -->
        </div>
        <div class="item-content">
            <div class="item-list">
                <div
                    v-for="(item, index) in multipleSelectValue"
                    :key="index"
                    class="item-wrap"
                    :class="{'item-active': item.isSelect}"
                    @click="handleCandidateFilter(item)"
                >
                    <span class="item-name">
                        {{item.itemText}}
                    </span>
                    <span class="item-value">
                        ({{item.count | currency('', 0)}})
                    </span>
                </div>
            </div>
            <el-select
                filterable
                v-if="enableShowSelect"
                class="item-filter-select"
                :default-first-option="true"
                popper-class="filter-select-popper"
                :placeholder="handleFilterPlaceholder(title)"
                v-model="filterItemValue"
            >
                <el-option
                    class="temp-list"
                    v-for="(item, index) in tempList"
                    :key="index"
                    :label="item.itemText"
                    :value="item.itemValue"
                    @click.stop.native="handleCandidateFilter(item)"
                >
                    <span class="temp-name">
                        {{item.itemText}}
                    </span>
                    <span class="temp-value">
                        ({{item.count}})
                    </span>
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
import degreeTextMap from './degreeTextMap.json';
import yearOfExpTextMap from './yearOfExpTextMap.json';
import emitter from '#/js/mixins/emitter.js';
import moment from 'moment';
const headerTextMap = {
    locations: '城市(支持多选)',
    degree: '学历',
    yearOfExperienceRegions: '工作经验(支持多选)',
    skillStandardTags: '技能(支持多选)',
    workExperienceCompaniesName: '公司(支持多选)',
    customer: '客户',
    recommendJobName: '推荐去了什么职位',
    recommendLocation: '推荐去了什么城市',
    recommendStatus: '推荐状态'
};
const iconMap = {
    degree: 'degree-icon',
    locations: 'location-icon',
    yearOfExperienceRegions: 'experience-icon',
    skillStandardTags: 'skill-icon',
    workExperienceCompaniesName: 'company-icon',
    customer: 'customer-icon',
    recommendJobName: 'title-icon',
    recommendLocation: 'location-icon',
    recommendStatus: 'status-icon'
};
const filterPlaceholder = {
    locations: "输入城市名称",
    skillStandardTags: '输入技能名称',
    workExperienceCompaniesName: "输入公司名称",
    customer: '筛选更多推荐客户...',
    recommendJobName: '筛选更多推荐职位...',
    recommendLocation: '筛选更多推荐城市...',
    recommendStatus: '筛选更多推荐状态...'
};
const degreeMap = {
    3: 0,   // 本科以及上
    4: 1,   // 硕士及以上
    6: 2,   // 博士及以上
    2: 3,   // 大专及以上
    0: 4    // 未知
};
const yearOfExpMap = {
    1: 0,   // 1-3年
    2: 1,   // 3-5年
    3: 2,   // 5-10年
    4: 3,   // 10年以上
    0: 4    // 未知
};

export default {
    mixins: [emitter],
    props: {
        sortList: {
            type: Array,
            default: () => {
                return []
            }
        },
        currentParams: {
            type: Object
        },
        multiple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default:''
        },
    },
    data(){
        return{
            filterItemValue: "",
            selectLabel: [],
            enableShowCityTip: false,
            headerTextMap: headerTextMap
        }
    },
    computed:{
        selectList() {
            return this.currentParams[this.title];
        },
        enableShow(){
            return this.multipleSelectValue.length > 0;
        },
        titleIcon(){
            return iconMap[this.title];
        },
        multipleSelectValue(){
            let title = this.title,
                list = [],
                selects = this.selectList,
                firstList = [];
            this.selectLabel = [];
            if(title === 'degree' || title === 'yearOfExperienceRegions'){
                let itemMap = title === 'degree' ? degreeMap : yearOfExpMap,
                    itemTextMap = title === 'degree' ? degreeTextMap : yearOfExpTextMap;
                Object.keys(itemMap).forEach(item => {
                    if(this.sortList.length > 0) {
                        for(let i = 0; i < this.sortList.length; i ++){
                            if(item == this.sortList[i].itemValue){
                                return list.push({
                                    isSelect: false,
                                    itemValue: item,
                                    itemText: itemTextMap[item],
                                    count: this.sortList[i].count
                                })
                            } else if (item != this.sortList[i].itemValue && i === this.sortList.length - 1){
                                return list.push({
                                    isSelect: false,
                                    itemValue: item,
                                    itemText: itemTextMap[item],
                                    count: 0
                                })
                            }
                        }
                    } else {
                        list.push({
                            itemValue: item,
                            itemText : item != 0 ? itemTextMap[item] : '未知',
                            count : 0
                        })
                    }
                });

                if(title === 'degree') {
                    let listCopy = JSON.parse(JSON.stringify(list)),
                        itemCount = 0;
                    list.forEach((item, index) => {
                        if(item.itemValue == selects) {
                            item.isSelect = true;
                            this.selectLabel = item;
                        }
                        item.itemValue = parseInt(item.itemValue);
                        if(item.itemValue > 1 && item.itemValue != 5) {
                            itemCount = item.count;
                            for(let i = 0; i < listCopy.length; i++) {
                                if(parseInt(listCopy[i].itemValue) > item.itemValue && parseInt(listCopy[i].itemValue) != 5) {
                                    itemCount += listCopy[i].count;
                                }
                            }
                            item.count = itemCount;
                        }
                    });
                } else {
                    list.forEach((item, index) => {
                        for(let i = 0; i < selects.length; i++) {
                            if(item.itemValue === selects[i]) {
                                item.isSelect = true;
                                this.selectLabel.push(item);
                            }
                        }
                    })
                }
                list.sort((item1,item2) => {
                    return Number(itemMap[item1.itemValue]) - Number(itemMap[item2.itemValue]);
                })
            } else {
                list = this.title !== 'recommendStatus' && this.sortList.length > 5 ? this.sortList.slice(0, 5) : [].concat(this.sortList);
                if(!this.multiple && this.selectList) {
                    this.sortList.forEach((item, index) => {
                        if(item.itemValue == this.selectList) {
                            item.isSelect = true;
                            this.selectLabel = item;
                            if(this.title !== 'recommendStatus' && index >= 5) {
                                list.push(item);
                            }
                        } else {
                            item.isSelect = false;
                        }
                    });
                } else if(this.selectList.length > 0){
                    firstList = this.sortList.slice(0, 5);
                    list = [];
                    this.sortList.forEach(item => {
                        item.isSelect = false;
                        for(let i = 0; i < selects.length; i++) {
                            if(selects[i] == item.itemValue) {
                                item.isSelect = true;
                                this.selectLabel.push(item);
                                list.push(item);
                                break;
                            }
                        }
                    });
                    for(let j = 0; j < firstList.length; j++) {
                        let hasSelect = list.some(item => {
                            return item.itemValue == firstList[j].itemValue;
                        })
                        if(!hasSelect) {
                            list.push(firstList[j]);
                        }
                    }
                    list.sort((item1,item2) => {
                        return Number(item2.count) - Number(item1.count);
                    })
                }
            }
            return list;
        },
        tempList() {
            let title = this.title;
            if(title === 'degree' || title === 'yearOfExperienceRegions'){
                return [];
            } else if(this.sortList.length <= 5) {
                return [];
            }else {
                let list = [];
                if(this.multiple && this.selectList.length > 0) {
                    let selects = this.selectList,
                        selectsLastIndex = this.selectList.length - 1,
                        hasSelectItem = false;
                    this.sortList.map((item, index) => {
                        hasSelectItem = this.selectList.some(select => {
                            return item.itemValue == select;
                        })
                        if(!hasSelectItem && index >= 5) {
                            list.push(item);
                        }
                    })
                } else if(!this.multiple && this.selectList) {
                    this.sortList.map((item, index) => {
                        if(item.itemValue != this.selectList && index >= 5) {
                            list.push(item);
                        }
                    })
                } else {
                    list = this.sortList.slice(5);
                }
                return list;
            }
        },
        enableShowSelect(){
            return this.title !== 'recommendStatus' && this.tempList && this.tempList.length > 0;
        }
    },
    filters: {
        headerTextFilter(type) {
            return headerTextMap[type];
        }
    },
    mounted() {
        // if(this.title === 'locations') {
        //     if(localStorage.getItem('cityFilterTipDate')) {
        //         let tipDate = new Date(localStorage.getItem('cityFilterTipDate')).getTime(),
        //             today = new Date(new Date().setHours(0,0,0,0)).getTime(),
        //             endDate = new Date("2019-12-14 00:00:00").getTime();
        //         if(today >= endDate || tipDate >= today) {
        //             this.enableShowCityTip = false;
        //         } else if(today < endDate && tipDate < today) {
        //             this.enableShowCityTip = true;
        //         }
        //     } else {
        //         this.enableShowCityTip = true;
        //     }
        // }
    },
    methods: {
        handleFilterPlaceholder(title) {
            return filterPlaceholder[title];
        },
        handleCandidateFilter(item) {
            this.filterItemValue = '';
            let currentActiveVal = "",
                paramValue = [],
                paramText = [];

            if(this.title === 'degree') {
                paramValue = '';
            }

            if(this.multiple) {
                currentActiveVal = this.selectLabel;
                if(item.isSelect) {
                    let index = currentActiveVal.findIndex(select => {
                        return select.itemValue === item.itemValue;
                    });
                    currentActiveVal.splice(index, 1);
                } else {
                    if(currentActiveVal.length >= 5) {
                        shortTips('同一个筛选项最多选择5项');
                        return false;
                    }
                    item.isSelect = true;
                    currentActiveVal.push(item);
                }
            } else {
                if(item.isSelect) {
                    currentActiveVal = "";
                } else {
                    currentActiveVal = item;
                }
            }
            if(currentActiveVal && this.multiple) {
                currentActiveVal.forEach(item => {
                    paramValue.push(item.itemValue);
                    paramText.push(item.itemText);
                });
            } else if(currentActiveVal && !this.multiple) {
                paramValue = currentActiveVal.itemValue;
                paramText.push(currentActiveVal.itemText);
            }

            this.dispatch('FirmCandidate', 'add-search-param', {
                title: this.title,
                value: paramText.join(", "),
                data: paramValue
            });
        },
        hideCityTip() {
            this.enableShowCityTip = false;
            localStorage.setItem('cityFilterTipDate', moment(new Date()).format('YYYY-MM-DD'));
        }
    }
}
</script>

<style lang="scss" scope>
.filter-item {
    padding-bottom: 20px;

    &:last-child {
        padding-bottom: 2px;
    }

    .item-header {
        // margin-top: 16px;
        display: flex;
        align-items: center;
        height: 20px;
        position: relative;
        margin-bottom: 10px;

        .item-title {
            height: 20px;
            line-height: 20px;
            color: #333;
            font-size: 14px;
            // padding-left: 24px;
            position: relative;
            display: inline-block;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                width: 16px;
                height: 16px;
                content: '';
            }

            i {
                color: #999;
            }
        }
        span.degree-icon {
            &::before {
                width: 18px;
                height: 18px;
                background-size: contain;
                background: url('~@src/assets/images/icon/filter-area/degree.png') no-repeat;
            }
        }
        span.location-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/location.png') no-repeat;
            }
        }
        span.experience-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/experience.png') no-repeat;
            }
        }
        span.skill-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/skill.png') no-repeat;
            }
        }
        span.company-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/company.png') no-repeat;
            }
        }
        span.customer-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/customer.png') no-repeat;
            }
        }
        span.title-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/title.png') no-repeat;
            }
        }
        span.status-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/status.png') no-repeat;
            }
        }
        .city-tip {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            background: #fa765d;
            padding: 0 8px;
            font-size: 12px;
            color: #fff;
            line-height: 28px;
            height: 28px;
            border-radius: 2px;
            .city-tip-close {
                font-size: 16px;
                cursor: pointer;
            }
            .city-tip-prend {
                margin-left: -6px;
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                top: 9px;
                left: 1px;
                width: 5px;
                height: 10px;
                border: 5px solid transparent;
                border-left: none;
                border-right-color: #fa765d;
            }
        }
    }
    .item-content {
        .item-list {
            .item-wrap {
                display: inline-flex;
                align-items: center;
                height: 32px;
                line-height: 32px;
                color: #666;
                padding: 0 10px;
                margin-right: 8px;
                margin-bottom: 8px;
                background: #F6F6F6;
                border-radius: 5px;
                cursor: pointer;
                vertical-align: top;

                span {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                span.item-name {
                    max-width: 125px;
                }
                span.item-value {
                    max-width: 70px;
                }
                &:hover {
                    color: #38BC9C;
                    background-color: #e7f7f3;
                }
            }
            .item-active {
                color: #fff;
                background-color: #38BC9D;;
            }
        }
        .item-filter {
            margin-top: 5px;

            &-select.el-select {
                width: 100%;
                margin-bottom: 8px;

                .el-input--suffix {
                    .el-input__inner {
                        padding: 0 10px;
                        height: 34px;
                        line-height: 34px;
                    }
                    .el-input__suffix {
                        display: none;
                    }
                }
            }

        }
    }
}
</style>
<style lang="scss">
.filter-select-popper.el-select-dropdown {
    .temp-list {
        padding: 0 8px;
        &:hover {
            background: #e7f7f3;
            color: #38BC9C;
        }
        span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        span.temp-name {
            max-width: 185px;
        }
        span.temp-value {
            max-width: 70px;
        }
    }
}
</style>

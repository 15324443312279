var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-filter" }, [
    _c("div", { staticClass: "candidate-filter-header" }, [
      _vm._v("\n            按搜索结果筛选：\n            "),
      _c("span", { staticClass: "text-blue" }, [
        _vm._v(
          "（根据" +
            _vm._s(_vm.filterType == 1 ? "简历内容" : "推荐记录") +
            "）"
        ),
      ]),
    ]),
    _c(
      "div",
      _vm._l(_vm.filterItems, function (item) {
        return _c("filter-item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                item.title !== "workExperienceCompaniesName" ||
                (item.title === "workExperienceCompaniesName" &&
                  _vm.isShowCompanyNameFilter),
              expression:
                "item.title !== 'workExperienceCompaniesName' || (item.title === 'workExperienceCompaniesName' && isShowCompanyNameFilter)",
            },
          ],
          key: item.title,
          attrs: {
            "sort-list": _vm.filterItemSortList(item.title),
            "current-params": _vm.filterTransParams,
            multiple: item.multiple,
            title: item.title,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="candidate-filter">
            <div class="candidate-filter-header">
                按搜索结果筛选：
                <span class="text-blue">（根据{{filterType == 1 ? '简历内容' : '推荐记录'}}）</span>
            </div>
            <div>
                <filter-item
                    v-for="item in filterItems"
                    :key="item.title"
                    :sort-list="filterItemSortList(item.title)"
                    :current-params="filterTransParams"
                    :multiple="item.multiple"
                    :title="item.title"
                    v-show="item.title !== 'workExperienceCompaniesName' || (item.title === 'workExperienceCompaniesName' && isShowCompanyNameFilter)"
                ></filter-item>
            </div>
    </div>
</template>
<script>
import filterItem from './filter-item.vue'
import moment from 'moment'
export default {
    components: {
        filterItem,
    },
    data(){
        return{}
    },
    props:{
        filterType: {
            type: [Number, String],
            default: 1
        },
        filterObj: {
            type: Object,
            default: {}
        },
        filterTransParams: {
            type: Object,
            default: {}
        },
        filterItems: {
            type: Array,
            default: []
        },
        isShowCompanyNameFilter: {
            type: Boolean,
            default: true
        }
    },
    mounted(){},
    methods: {
        filterItemSortList(title) {
            if(this.filterObj !== null) {
                if(title == 'locations') {
                    return this.filterObj.location;
                } else if(title == 'yearOfExperienceRegions') {
                    return this.filterObj.yearOfExperienceRegion;
                } else if(title == 'customer') {
                    return this.filterObj.customerId;
                } else {
                    return this.filterObj[title]
                }
            } else {
                return [];
            }
        }
    }
}
</script>

<style lang="scss" scope>
// .text-blue {
//     color:  #4a90e2;
// }
.candidate-filter {
    // border: 10px solid #f8f8f8;
    padding: 0 12px 12px;
    &-header {
        color: #333;
        font-weight: bold;
        height: 54px;
        line-height: 54px;
    }
}
</style>

<template>
    <div>
        <div class="candidate-search">
            <div class="candidate-search-form">
                <el-radio-group
                    class="search-type-list radio-list"
                    v-if="isFirmSearch"
                    v-model="tabType"
                >
                    <el-radio :label="1">按简历内容查找</el-radio>
                    <el-radio :label="2">按推荐记录查找</el-radio>
                    <el-radio :label="3">按寻访记录查找</el-radio>
                </el-radio-group>
                <!-- <el-button
                    class="switch-page-btn"
                    type="primary"
                    @click="switchOldCandidatePage"
                >
                    返回旧版
                </el-button> -->
                <div class="form-container">
                    <el-form
                        size="mini"
                        class="search-form"
                        :model="candidateSearch"
                        ref="candidateSearchForm"
                        :rules="candidateSearchRules"
                        v-show="tabType == 1 || !isFirmSearch">
                        <el-form-item >
                            <div class="search-block">
                                <div class="search-select-box">
                                    <el-select
                                        class="form-item-label form-item-select search-select-key"
                                        v-model="candidateSearch.keywordFilterType"
                                        placeholder="全部关键字"
                                    >
                                        <el-option label="全部关键字" :value="1"></el-option>
                                        <el-option label="任一关键字" :value="2"></el-option>
                                    </el-select>
                                    <el-input
                                        v-model="candidateSearch.keywords"
                                        :maxlength="128"
                                        placeholder="多个关键词使用逗号“,”分隔，不区分中英文"
                                        class="search-input search-input-key"
                                        @keyup.enter.native="addParams"
                                    >
                                    </el-input>
                                    <span class="do-search-btn" @click="addParams">搜索</span>
                                </div>
                                <div class="more-select-btn">
                                    <span class="more-btn text-operate-btn" @click="showMoreSearch" v-if="tabType == '1'">
                                        <template v-if="isShowMoreSearch">
                                            收起
                                            <i class="el-icon-arrow-up"></i>
                                            <!-- <i class="icon-font icon-toggle-up-double"></i> -->
                                        </template>
                                        <template v-else>
                                            更多筛选条件
                                            <i class="el-icon-arrow-down"></i>
                                            <!-- <i class="icon-font icon-toggle-down-double"></i> -->
                                        </template>
                                    </span>
                                </div>
                            </div>
                        </el-form-item>

                        <div class="form-item-container">
                            <el-form-item>
                                <span class="form-item-label">职位</span>
                                <!-- @input.native="handleTitleInput" -->
                                <el-autocomplete
                                    class="search-input"
                                    v-model.trim="title"
                                    :maxlength="128"
                                    popper-class="search-autocomplete-popper"
                                    placeholder="请选择"
                                    :fetch-suggestions="getTitleSearchList"
                                    @select="handleTitleSelect"
                                    @input="handleTitleInput"
                                    @blur="handleTitleBlur"
                                    @keyup.enter.native="addParams"
                                >
                                    <template slot-scope="{item}">
                                        <div
                                            class="name"
                                            :class="{'selected':item.title == candidateSearch.notAnalyzedTitle}"
                                        >
                                            {{item.title}}
                                            (<i>{{item.count | currency('', 0)}}</i>)
                                        </div>
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item>
                                <el-select
                                    class="form-item-label form-item-select search-select-companyname"
                                    v-model="candidateSearch.companyQueryType"
                                    placeholder="全部公司"
                                >
                                    <el-option label="全部公司" :value="3"></el-option>
                                    <el-option label="最近公司" :value="1"></el-option>
                                    <el-option label="曾在职公司" :value="2"></el-option>
                                </el-select>
                                <!-- @input.native="handleCompanyNameInput" -->
                                <!-- placeholder="多家公司，以空格隔开" -->
                                <el-autocomplete
                                    class="search-input company-name"
                                    v-model.trim="companyName"
                                    :maxlength="128"
                                    popper-class="search-autocomplete-popper"
                                    placeholder="请输入"
                                    :fetch-suggestions="getCompanyNameSearchList"
                                    @select="handleCompanyNameSelect"
                                    @input="handleCompanyNameInput"
                                    @blur="handleCompanyNameBlur"
                                    @keyup.enter.native="addParams"
                                >
                                    <template slot-scope="{item}">
                                        <div
                                            class="name"
                                            :class="{'selected':item.company == candidateSearch.notAnalyzedCompanyName}"
                                        >
                                            {{item.company}}
                                            (<i>{{item.count | currency('', 0)}}</i>)
                                        </div>
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item>
                                <span class="form-item-label">姓名</span>
                                <el-input
                                    class="search-input"
                                    placeholder="请输入"
                                    :maxlength="20"
                                    v-model="candidateSearch.realName"
                                    @keyup.enter.native="addParams"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="mobile">
                                <span class="form-item-label">手机</span>
                                <el-input
                                    class="search-input"
                                    placeholder="请输入"
                                    v-model="candidateSearch.mobile"
                                    @keyup.enter.native="addParams"
                                ></el-input>
                            </el-form-item>
                            <template v-if="isShowMoreSearch">
                                <el-form-item prop="email">
                                    <span class="form-item-label">邮箱</span>
                                    <el-input
                                        class="search-input"
                                        placeholder="请输入"
                                        v-model="candidateSearch.email"
                                        @keyup.enter.native="addParams"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item class="form-select-item">
                                    <span class="form-item-label">标签</span>
                                    <el-select
                                        filterable
                                        placeholder="请选择"
                                        class="search-input"
                                        popper-class="form-select-popover"
                                        v-model="candidateSearch.label"
                                        @change="addParams"
                                        :filter-method="labelFilter"
                                    >
                                        <template v-for="category in labelFilterList">
                                            <p
                                                class="label-category"
                                                :key="'category-' + category.categoryId"
                                                v-if="category.tagDataList && category.tagDataList.length > 0">
                                                {{category.categoryName}}
                                            </p>
                                            <el-option
                                                v-for="tag in category.tagDataList"
                                                :key="'tag-' + tag.tagId"
                                                :label="tag.tagName"
                                                :value="tag.tagName"
                                            ></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="tabType == 1">
                                    <span class="form-item-label">创建者</span>
                                    <el-popover
                                        popper-class="team-member-popover"
                                        placement="bottom-start"
                                        width="700"
                                        trigger="click"
                                        v-model="teamMemberPopoverVisible"
                                    >
                                        <team-member-select
                                            ref="teamSelect"
                                            :isAllMembers="true"
                                            :isShowDimission="false"
                                            :visible="teamMemberPopoverVisible"
                                            :selected="selectedCreatorIds"
                                            @item-select-cb="membersSelect"
                                        ></team-member-select>
                                        <el-input
                                            slot="reference"
                                            :readonly="true"
                                            class="search-input has-team-select"
                                            v-model="creators"
                                        ></el-input>
                                    </el-popover>
                                </el-form-item>
                                <el-form-item class="form-select-item">
                                    <span class="form-item-label">创建时间</span>
                                    <el-date-picker
                                        align="left"
                                        size="small"
                                        type="daterange"
                                        format="yyyy-MM-dd"
                                        range-separator="-"
                                        class="search-input"
                                        v-model="updatedTime"
                                        @change="changeUpdatedTime"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :picker-options="candidateTimeOption"
                                        popper-class="candidate-data-range"
                                    ></el-date-picker>
                                </el-form-item>
                                <el-form-item class="form-select-item">
                                    <span class="form-item-label">年龄</span>
                                    <div class="el-age-editor el-date-editor el-range-editor el-input__inner search-input el-date-editor--daterange el-range-editor--small" :class="isAgeInputFocused ? 'is-active' : ''">
                                        <input 
                                            ref="minAgeInput"
                                            type="text" 
                                            autocomplete="off" 
                                            placeholder="岁" 
                                            name="" 
                                            class="el-range-input"
                                            @focus="isAgeInputFocused = true"
                                            @blur="e => handleAgeBlur(e, 'minAge')"
                                            @keyup.enter="e => handleAgeEnter(e, 'minAge')"
                                        >
                                        <span class="el-range-separator">-</span>
                                        <input 
                                            ref="maxAgeInput"
                                            type="text" 
                                            autocomplete="off" 
                                            placeholder="不限" 
                                            name="" 
                                            class="el-range-input"
                                            @focus="isAgeInputFocused = true"
                                            @blur="e => handleAgeBlur(e, 'maxAge')"
                                            @keyup.enter="e => handleAgeEnter(e, 'maxAge')"
                                        >
                                    </div>
                                </el-form-item>
                                <el-form-item class="form-select-item">
                                    <span class="form-item-label">性别</span>
                                    <!-- gender -->
                                    <el-select
                                        filterable
                                        class="search-input"
                                        popper-class="form-select-popover"
                                        v-model="candidateSearch.gender"
                                        placeholder="请选择"
                                        @change="addParams"
                                    >
                                        <el-option
                                            v-for="(genderIt, index) in genderList"
                                            :key="'gender-' + index"
                                            :label="genderIt.label"
                                            :value="genderIt.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="form-select-item new-first-city-form-select-item">
                                    <span class="form-item-label new-first-city-label">看新一线机会</span>
                                    <div class="el-range-city-select">
                                        <el-select 
                                        ref="thinkingTimeSelect" popper-class="city-select-shield" v-model="thinkingTime" multiple placeholder="选择时间" @visible-change="thinkingTimeVisibleChange" >
                                            <el-option
                                                v-for="item in timeOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                            <div class="select-btns-wrap">
                                                <div class="btn reset" @click="resetTime">重置</div>
                                                <div class="btn sure" @click="onSubmitTime">确定</div>
                                            </div>
                                        </el-select>
                                        <span class="el-range-separator">-</span>
                                        <el-select 
                                            ref="thinkingCitySelect" 
                                            popper-class="city-select-shield" 
                                            v-model="thinkingCity" 
                                            multiple 
                                            placeholder="选择城市" 
                                            @change="selectNewFirstCityChange"
                                            @visible-change="thinkingCityVisibleChange">
                                            <el-option
                                                v-for="item in cityList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                            <el-option :value="other">其他</el-option>
                                            <!-- <div class="other-city" v-if="this.showOtherCityInput">
                                                <el-input v-model="otherCityValue" placeholder="多个城市以空格隔开"></el-input>
                                            </div> -->
                                            <div class="select-btns-wrap">
                                                <div class="btn reset" @click="resetCity">重置</div>
                                                <div class="btn sure" @click="onSubmitCity">确定</div>
                                            </div>
                                        </el-select>
                                    </div>
                                </el-form-item>
                                <el-form-item class="form-select-item"></el-form-item>
                            </template>
                        </div>
                            
                            <!-- 城市地区 -->
                             <!-- style="margin-right:auto" -->
                            <!-- <el-form-item>
                                <span class="form-item-label">城市地区</span>
                                <el-popover
                                    popper-class="city-select-popver"
                                    placement="bottom"
                                    width="400"
                                    trigger="click"
                                    v-model="cityAreaSelectVisible">
                                    <el-input 
                                        slot="reference" 
                                        class="search-input" 
                                        v-model="cityArrValue"
                                        placeholder="请选择城市地区"
                                        >
                                    </el-input>
                                    
                                    <province-city-select
                                        ref="provinceCitySelect"
                                        @confirm-city-select="handleCitySelectConfirm"
                                        @reset-city-select="handleCitySelectReset">
                                    </province-city-select>

                                </el-popover>
                            </el-form-item> -->


                            <!-- <el-form-item>
                                <span class="form-item-label">城市地区</span>
                                <el-popover
                                    popper-class="city-select-popver"
                                    placement="bottom"
                                    width="400"
                                    trigger="click">
                                    <el-input slot="reference" class="search-input" v-model="cityArrValue"></el-input>
                                    
                                    <div class="city-select-popver-content">
                                        <div class="city-type">
                                            <el-checkbox v-model="checked">期望</el-checkbox>
                                            <el-checkbox v-model="checked">现居</el-checkbox>
                                        </div>
                                        <div class="selected-cities" v-if="true">
                                            <span>长沙 <i>×</i></span>
                                            <span>株洲 <i>×</i></span>
                                        </div>
                                        <div class="search-city">
                                            <el-input class="search-input" v-model="cityArrValue"></el-input>
                                        </div>
                                        <el-cascader-panel
                                            :props="props"
                                            :options="cities"
                                            :show-all-levels="false"
                                            :clearable="true"
                                            v-model="selectedCity"
                                            filterable
                                        ></el-cascader-panel>
                                    </div>

                                    <div class="city-select-popver-footer">
                                        <el-button type="info" plain>重置</el-button>
                                        <el-button type="primary">确定</el-button>
                                    </div>
                                </el-popover>
                            </el-form-item> -->
                            
                            
                        <div class="search-label-item">
                            <div class="search-label-item-left">
                                <el-form-item
                                    label="我未浏览："
                                    class="search-input-label"
                                >
                                    <el-dropdown @command="changeNoViewTime" placement="bottom-start">
                                        <span class="el-dropdown-link" :class="!lastViewTimeText ? 'el-dropdown-link-empty' : ''">
                                            {{lastViewTimeText || '请选择'}}<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :command="1">三个月内</el-dropdown-item>
                                            <el-dropdown-item :command="2">六个月内</el-dropdown-item>
                                            <el-dropdown-item :command="0">全部时间</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <!-- <el-button-group class="search-input">
                                        <el-button
                                            @click="changeNoViewTime(1)"
                                            :class="{'selected': lastViewTimeText == '三个月内'}"
                                        >
                                            三个月内
                                        </el-button>
                                        <el-button
                                            @click="changeNoViewTime(2)"
                                            :class="{'selected': lastViewTimeText == '六个月内'}"
                                        >
                                            六个月内
                                        </el-button>
                                        <el-button
                                            @click="changeNoViewTime(0)"
                                            :class="{'selected': lastViewTimeText == '全部时间'}"
                                        >
                                            全部时间
                                        </el-button>
                                    </el-button-group> -->
                                </el-form-item>
                                <el-form-item
                                    label="排除设置："
                                    class="search-input-label"
                                >
                                    <el-checkbox v-model="candidateSearch.isExcludeOnboardGuarantee" @change="changeExcludeCondition">排除在保护期的人选</el-checkbox>
                                    <el-checkbox v-model="candidateSearch.isExcludeEmptyMobile" @change="changeExcludeCondition">排除无效号码</el-checkbox>
                                    <!-- <bubble
                                        class="button-list"
                                        :enableCount="false"
                                        :isSelect="candidateSearch.isExcludeOnboardGuarantee"
                                        @click="changeExcludeCondition(0)"
                                    >
                                        排除在保护期的人选
                                    </bubble>
                                    <bubble
                                        class="button-list"
                                        :enableCount="false"
                                        :isSelect="candidateSearch.isExcludeEmptyMobile"
                                        @click="changeExcludeCondition(1)"
                                    >
                                        排除无效号码
                                    </bubble> -->
                                </el-form-item>
                                <el-form-item
                                    label="背景情况："
                                    class="search-input-label"
                                >
                                    <el-checkbox v-model="candidateSearch.isFamousCompany" @change="changeBackground(0)">名企</el-checkbox>
                                    <el-checkbox :value="candidateSearch.educationTags.length > 0" @change="changeBackground(1)">985/211</el-checkbox>
                                    <!-- <bubble
                                        type="blue"
                                        class="button-list"
                                        :enableCount="false"
                                        :isSelect="candidateSearch.isFamousCompany"
                                        @click="changeBackground(0)"
                                    >
                                        名企
                                    </bubble>
                                    <bubble
                                        type="blue"
                                        class="button-list"
                                        :enableCount="false"
                                        :isSelect="candidateSearch.educationTags.length > 0"
                                        @click="changeBackground(1)"
                                    >
                                        985/211
                                    </bubble> -->
                                </el-form-item>
                            </div>
                            <div class="search-label-item-right">
                                <el-popover
                                    placement="bottom"
                                    trigger="hover"
                                    popper-class="saved-search"
                                    >
                                    <span class="use-saved-btn" slot="reference">
                                        使用已保存搜索条件
                                    </span>
                                    <div class="list-dialog" :class="searchFiltersList.length == 0 ? 'list-dialog-empty' : ''">
                                        <!-- <div class="dialog-title">使用已保存搜索条件</div> -->
                                        <div class="list-dialog-body">
                                            <div class="body-scroll">
                                                <div class="search-item" 
                                                    v-for="(item, index) in searchFiltersList" 
                                                    :key="'searchFilters_' + index"
                                                    >
                                                    <div class="item-l">
                                                        <span
                                                            v-for="(filterItem, subIndex) in item.filters"
                                                            :key="'filterItem_' + subIndex"
                                                            v-show="isShowFilterTag(filterItem, item.filters)"
                                                            >
                                                            <template v-if="['locations', 'expectationLocations'].includes(filterItem.title)">
                                                                {{generateFilterLocationStr(item.filters)}}
                                                            </template>
                                                            <template v-else>
                                                                {{filterItem.text}}：{{filterItem.value}}
                                                            </template>
                                                        </span>
                                                        <!-- <span>职位：测试工程师</span>
                                                        <span>职位：测试工程师</span> -->
                                                    </div>
                                                    <div class="item-r">
                                                        <a href="javascript:;" @click="useSearchRecord(item)">使用</a>
                                                        <a href="javascript:;" @click="deleteSearchRecord(item.id)">删除</a>
                                                    </div>
                                                </div>
                                                <div class="empty-data" v-if="searchFiltersList.length == 0">
                                                    <!-- <span class="empty-img "></span> -->
                                                    <font-icon class="empty-data-img" 
                                                        href="#icon-weibaocunsousuotiaojian1">
                                                    </font-icon>
                                                    <p>暂无保存任何搜索条件</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                    </el-form>
                    
                    <el-form
                        size="mini"
                        :inline="true"
                        class="search-form search-form-normal"
                        :model="candidateSearch"
                        ref="candidateSearchForm"
                        :rules="candidateSearchRules"
                        v-if="isFirmSearch"
                        v-show="tabType == 2">
                        <div class="search-tip" v-show="isRecommendTipShow">
                            <span class="tip-title">
                                <i class="icon-font icon-tips"></i>
                                找人技巧
                            </span>
                            <p class="tip-content">
                                你可以在这里找到所有曾被推荐过的候选人。<br/>
                                根据他们被推荐的职位，找到你可能想要的人。<br/>
                                比如，试试看找一下去年被推荐到北京的JAVA工程师？
                            </p>
                            <i class="icon-font icon-quxiao" @click="setLocalStorage('recommendTip')"></i>
                        </div>
                        <el-form-item class="form-select-item">
                            <span class="form-item-label">推荐时间</span>
                            <el-date-picker
                                align="left"
                                size="small"
                                type="daterange"
                                format="yyyyMMdd"
                                range-separator="-"
                                class="search-input"
                                v-model="recommendTime"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="changeRecommendTime"
                                :picker-options="candidateTimeOption"
                                popper-class="candidate-data-range"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <span class="form-item-label">推荐职位</span>
                            <el-autocomplete
                                class="search-input"
                                v-model="recommendJobName"
                                popper-class="search-autocomplete-popper"
                                :fetch-suggestions="getRecommendJobNameSearchList"
                                @keyup.enter.native="addParams"
                                @input.native="handleRecommendJobNameInput"
                                @blur="handleRecommendJobNameBlur"
                                @select="handleRecommendJobNameSelect">
                                <template slot-scope="{item}">
                                    <div
                                        class="name"
                                        :class="{'selected':item.itemValue == candidateSearch.recommendJobName}">
                                        {{item.itemText}}
                                        (<i>{{item.count | currency('', 0)}}</i>)
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item class="form-select-item">
                            <span class="form-item-label">推荐城市</span>
                            <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
                        </el-form-item>
                        <el-form-item>
                            <span class="form-item-label">客户</span>
                            <customer-select
                                ref="customerSelect"
                                placeholder=""
                                @customerSelected="handleCustomerSelected"
                            ></customer-select>
                        </el-form-item>
                        <el-form-item v-if="tabType == 2">
                            <span class="form-item-label">推荐人</span>
                            <el-popover
                                popper-class="team-member-popover"
                                placement="bottom-start"
                                width="700"
                                trigger="click"
                                v-model="teamMemberPopoverVisible"
                            >
                                <team-member-select
                                    ref="teamSelect"
                                    :isAllMembers="true"
                                    :isShowDimission="false"
                                    :visible="teamMemberPopoverVisible"
                                    :selected="selectedCreatorIds"
                                    @item-select-cb="membersSelect"
                                ></team-member-select>
                                <el-input
                                    slot="reference"
                                    :readonly="true"
                                    class="search-input has-team-select"
                                    v-model="recommenders"
                                ></el-input>
                            </el-popover>
                        </el-form-item>

                        <el-form-item>
                            <div class="search-label-item-right inline">
                                <el-popover
                                    placement="bottom"
                                    trigger="hover"
                                    popper-class="saved-search"
                                    >
                                    <span class="use-saved-btn" slot="reference">
                                        使用已保存搜索条件
                                    </span>
                                    <div class="list-dialog" :class="searchFiltersList.length == 0 ? 'list-dialog-empty' : ''">
                                        <!-- <div class="dialog-title">使用已保存搜索条件</div> -->
                                        <div class="list-dialog-body">
                                            <div class="body-scroll">
                                                <div class="search-item" 
                                                    v-for="(item, index) in searchFiltersList" 
                                                    :key="'searchFilters_' + index"
                                                    >
                                                    <div class="item-l">
                                                        <span
                                                            v-for="(filterItem, subIndex) in item.filters"
                                                            :key="'filterItem_' + subIndex"
                                                            v-show="isShowFilterTag(filterItem, item.filters)"
                                                            >
                                                            <template v-if="['locations', 'expectationLocations'].includes(filterItem.title)">
                                                                {{generateFilterLocationStr(item.filters)}}
                                                            </template>
                                                            <template v-else>
                                                                {{filterItem.text}}：{{filterItem.value}}
                                                            </template>
                                                        </span>
                                                        <!-- <span>职位：测试工程师</span>
                                                        <span>职位：测试工程师</span> -->
                                                    </div>
                                                    <div class="item-r">
                                                        <a href="javascript:;" @click="useSearchRecord(item)">使用</a>
                                                        <a href="javascript:;" @click="deleteSearchRecord(item.id)">删除</a>
                                                    </div>
                                                </div>
                                                <div class="empty-data" v-if="searchFiltersList.length == 0">
                                                    <!-- <span class="empty-img "></span> -->
                                                    <font-icon class="empty-data-img" href="#icon-weibaocunsousuotiaojian1"></font-icon>
                                                    <p>暂无保存任何搜索条件</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </el-form-item>
                        <el-form-item></el-form-item>
                        <el-form-item></el-form-item>

                    </el-form>
                    <el-form
                        size="mini"
                        :inline="true"
                        class="search-form search-form-normal"
                        :model="candidateSearch"
                        ref="candidateSearchForm"
                        :rules="candidateSearchRules"
                        v-if="isFirmSearch"
                        v-show="tabType == 3">
                        <div class="search-tip" v-show="isRecordTipShow">
                            <span class="tip-title">
                                <i class="icon-font icon-tips"></i>
                                找人技巧
                            </span>
                            <p class="tip-content">
                                你可以在这里找到所有同事寻访过的候选人。<br/>
                                寻访记录里存在大量候选人的重要信息，<br/>
                                来看看哪些正是你需要的人吧！
                            </p>
                            <i class="icon-font icon-quxiao" @click="setLocalStorage('recordTip')"></i>
                        </div>
                        <el-form-item class="form-select-item">
                            <span class="form-item-label">寻访时间</span>
                            <el-date-picker
                                align="left"
                                size="small"
                                type="daterange"
                                format="yyyyMMdd"
                                range-separator="-"
                                class="search-input"
                                v-model="inquiryLogTime"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="changeInquiryTime"
                                :picker-options="candidateTimeOption"
                                popper-class="candidate-data-range"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <span class="form-item-label">寻访内容</span>
                            <el-input
                                class="search-input"
                                v-model="candidateSearch.inquiryLogContent"
                                @keyup.enter.native="addParams"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="tabType == 3">
                            <span class="form-item-label">寻访人</span>
                            <el-popover
                                popper-class="team-member-popover"
                                placement="bottom-start"
                                width="700"
                                trigger="click"
                                v-model="teamMemberPopoverVisible"
                            >
                                <team-member-select
                                    ref="teamSelect"
                                    :isAllMembers="true"
                                    :isShowDimission="false"
                                    :visible="teamMemberPopoverVisible"
                                    :selected="selectedCreatorIds"
                                    @item-select-cb="membersSelect"
                                ></team-member-select>
                                <el-input
                                    slot="reference"
                                    :readonly="true"
                                    class="search-input has-team-select"
                                    v-model="inquiryLogCreators"
                                ></el-input>
                            </el-popover>
                        </el-form-item>

                        <!-- 样式占位 -->
                        <!-- <el-form-item v-if="tabType != 3"></el-form-item> -->
                        <!-- <el-form-item></el-form-item> -->
                        <el-form-item>
                            <div class="search-label-item-right inline">
                                <el-popover
                                    placement="bottom"
                                    trigger="hover"
                                    popper-class="saved-search"
                                    >
                                    <span class="use-saved-btn" slot="reference">
                                        使用已保存搜索条件
                                    </span>
                                    <div class="list-dialog" :class="searchFiltersList.length == 0 ? 'list-dialog-empty' : ''">
                                        <!-- <div class="dialog-title">使用已保存搜索条件</div> -->
                                        <div class="list-dialog-body">
                                            <div class="body-scroll">
                                                <div class="search-item" 
                                                    v-for="(item, index) in searchFiltersList" 
                                                    :key="'searchFilters_' + index"
                                                    >
                                                    <div class="item-l">
                                                        <span
                                                            v-for="(filterItem, subIndex) in item.filters"
                                                            :key="'filterItem_' + subIndex"
                                                            v-show="isShowFilterTag(filterItem, item.filters)"
                                                            >
                                                            <template v-if="['locations', 'expectationLocations'].includes(filterItem.title)">
                                                                {{generateFilterLocationStr(item.filters)}}
                                                            </template>
                                                            <template v-else>
                                                                {{filterItem.text}}：{{filterItem.value}}
                                                            </template>
                                                        </span>
                                                        <!-- <span>职位：测试工程师</span>
                                                        <span>职位：测试工程师</span> -->
                                                    </div>
                                                    <div class="item-r">
                                                        <a href="javascript:;" @click="useSearchRecord(item)">使用</a>
                                                        <a href="javascript:;" @click="deleteSearchRecord(item.id)">删除</a>
                                                    </div>
                                                </div>
                                                <div class="empty-data" v-if="searchFiltersList.length == 0">
                                                    <!-- <span class="empty-img "></span> -->
                                                    <font-icon class="empty-data-img" href="#icon-weibaocunsousuotiaojian1"></font-icon>
                                                    <p>暂无保存任何搜索条件</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </el-form-item>

                        <el-form-item
                            label="求职意愿："
                            class="search-input-label job-hunting-will"
                        >
                            <el-radio-group
                                class="search-input radio-list"
                                v-model="candidateSearch.jobHoppingOccasion"
                                @change="changeJobHoppingOccasion"
                            >
                                <el-radio label="">全部</el-radio>
                                <el-radio label="看职业机会">看职业机会</el-radio>
                                <el-radio label="离职状态">离职状态</el-radio>
                                <el-radio label="6个月内不考虑">6个月内不考虑</el-radio>
                                <el-radio label="12个月内不考虑">12个月内不考虑</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>

                    <!-- <span class="more-btn text-operate-btn" @click="showMoreSearch" v-if="tabType == '1'">
                        <template v-if="isShowMoreSearch">
                            收起更多
                            <i class="icon-font icon-toggle-up-double"></i>
                        </template>
                        <template v-else>
                            展开更多
                            <i class="icon-font icon-toggle-down-double"></i>
                        </template>
                    </span> -->
                </div>

                <!-- <div class="search-param-container" v-if="isShowSearchCondition">
                    <div class="search-param-list">
                        您的搜索结果数：
                        <el-tag
                            closable
                            effect="plain"
                            class="search-param-item"
                            v-for="(search, index) in searchConditions"
                            :key="index"
                            @close="removeLabel(search)"
                            v-show="search.value"
                        >
                            {{search.text}}: {{search.value}}
                        </el-tag>
                        <span class="candidate-total">
                            共 <span class="text-primary">{{candidateTotal | currency('', 0)}}</span> 人
                        </span>
                        <span class="clear-search text-primary" @click="handleClearSearch">
                            <i class="el-icon-delete"></i>
                            清空搜索条件
                        </span>

                        <span class="clear-search text-primary" 
                            @click="handleSaveSearch" 
                            :disabled="saveSearchLoading">
                            <font-icon href="#icon-baocun"></font-icon>
                            保存当前搜索条件
                        </span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="search-param-container" v-if="isShowSearchCondition">
            <div class="search-param-list">
                您的搜索结果数：
                <el-tag
                    closable
                    effect="plain"
                    class="search-param-item"
                    v-for="(search, index) in searchConditions"
                    :key="index"
                    @close="removeLabel(search)"
                    v-show="isShowTag(search)"
                >   
                    <template v-if="['locations', 'expectationLocations'].includes(search.title)">
                        {{generateLocationStr()}}
                    </template>
                    <template v-else>
                        {{search.text}}: {{search.value}}
                    </template>
                </el-tag>
                <span class="candidate-total">
                    共 <span class="text-primary">{{candidateTotal | currency('', 0)}}</span> 人
                </span>
                <span class="clear-search" @click="handleClearSearch">
                    <i class="el-icon-delete"></i>
                    清空搜索条件
                </span>

                <span class="clear-search text-primary" 
                    @click="handleSaveSearch" 
                    :disabled="saveSearchLoading">
                    <font-icon href="#icon-baocun"></font-icon>
                    保存当前搜索条件
                </span>
            </div>
        </div>
        <div class="search-param-container" v-else></div>
        <div class="work-hunting">
            <div style="display: flex;">
                <div class="batch-option">
                    <el-checkbox v-model="checkAll" @change="handleCheckAllChange">
                        全选
                    </el-checkbox>

                    <span class="info-text">已选择<i>{{ checkedOptions.length }}</i>位候选人</span>

                    <el-button :class="{'batch-btn': true, 'batch-view': checkedOptions.length > 0}" @click="batchView" :disabled="checkedOptions.length == 0">
                        批量查看
                    </el-button>
                </div>

                <el-checkbox
                    v-model="candidateSearch.isJobHunting"
                    @change="addParams"
                >
                    只看有求职意向的人
                </el-checkbox>
            </div>

            <el-dropdown
                class="sort-dropdown"
                @command="handleSort"
                trigger="click"
            >
                <span>
                    排序方式：<i class="sort-text"></i>{{sortText}}
                    <i class="el-icon-arrow-down sort-icon"></i>
                </span>
                <el-dropdown-menu
                    slot="dropdown"
                    class="sort-dropdown-menu"
                >
                    <el-dropdown-item
                        :command="1"
                        :class="{'active': sortText=='更新时间排序'}"
                    >
                        更新时间排序
                    </el-dropdown-item>
                    <el-dropdown-item
                        :command="0"
                        :class="{'active': sortText=='综合排序'}"
                    >
                        综合排序
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import CustomerSelect from '#/component/common/customer-select.vue';
import CitySelect from '#/component/common/city-select.vue'
import Bubble from '#/component/common/bubble.vue'
import { internationalMobile as mobileTest, email as emailTest, telephone as telephoneTest } from '#/js/util/validators.js';
import provinceData from '#/js/config/provinceThreeData.json'
import newFirstCityData from '#/js/config/newFirstCityData.json';
import cityMap from '#/js/config/provinceCity.json'
import moment from 'moment';
import tagService from '#/js/service/tagService.js';
import candidateService from '#/js/service/candidateService.js';
// import ProvinceCitySelect from '#/component/common/province-city-select.vue';

export default {
    components: {
        // ProvinceCitySelect,
        TeamMemberSelect,
        CustomerSelect,
        Bubble,
        CitySelect
    },
    props: {
        isFirmSearch: {
            type: Boolean,
            default: true
        },
        searchConditions: Array,
        candidateTotal: Number,
        titleSearchList: Array,
        recommendTitleSearchList: Array,
        companyNameList: Array,
        isSelectAll: Boolean,
        checkedCandidates: Array,
        newCandidateList: Array
    },
    data() {
        return {
            checkAll: false,
            checkedOptions: [],
            
            cities: provinceData.province,
            props: {
                expandTrigger: 'hover',
                multiple: true 
            },
            selectedCity: [],
            cityArrValue: '',
            checked: true,

            teamMemberPopoverVisible: false,
            tabType: 1,
            title: "",
            isInputTitle: false,
            companyName: "",
            isInputCompanyName: false,
            creators: "全部成员",
            recommenders: "全部成员",
            inquiryLogCreators: "全部成员",
            customerName: "",
            lastViewTimeText: "",
            updatedTime: [],
            sortText: "更新时间排序",
            recommendTime: [],
            isAgeInputFocused: false,
            recommendJobName: "",
            inquiryLogTime: [],
            labelList: [],
            labelFilterList: [],
            genderList: [
                { value: '2', label: '女', },
                { value: '1', label: '男', },
            ], // 性别
            cityTypes: provinceData.province,
            isShowMoreSearch: false,
            memberSelectList: [],
            teamSelect: "",
            isRecommendTipShow: localStorage.getItem('recommendTipShowed') ? false : true,
            isRecordTipShow: localStorage.getItem('recordTipShowed') ? false : true,            
            candidateTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            candidateSearch: {
                keywords: "",
                keywordFilterType: 1,
                title: "",
                notAnalyzedTitle: "",
                companyName: "",
                notAnalyzedCompanyName: "",
                companyQueryType: 3,
                realName: "",
                mobile: "",
                email: "",
                label: "",
                gender: "", // 性别
                creatorIds: [],
                updatedStartTime: "",
                updatedEndTime: "",
                minAge: "",
                maxAge: "",
                lastViewTime: "",
                isExcludeOnboardGuarantee: false,
                isExcludeEmptyMobile: false,
                isFamousCompany: false,
                educationTags: [],
                isJobHunting: false,
                sort: 1,
                recommendStartTime: "",
                recommendEndTime: "",
                recommendJobName: "",
                recommendLocation: "",
                customerId: "",
                recommendCreatorIds: [],
                inquiryLogStartTime: "",
                inquiryLogEndTime: "",
                inquiryLogContent: "",
                inquiryLogCreatorIds: [],
                jobHoppingOccasion: "",

                locations: [],  // 现居
                expectationLocations: [], // 期望
                
                expectationTypeIds: [],
                expectationNewCityLocations: [],
                expectationNewCityAddress: null,
            },
            candidateSearchRules: {
                mobile: [
                    { validator: this.validateMobileValue, trigger: 'blur', },
                    ],
                email: [
                    { validator: this.validateEmailValue, trigger: 'blur', },
                ],
            },
            isSaveFilterParam: false,

            searchFiltersList: [], // 保存的搜索记录列表
            saveSearchLoading: false,

            cityAreaSelectVisible: false,
            newCityList: [], // 用来判断有没有选'其他'选项

            thinkingTime: [],
            thinkingCity: [],
            timeOptions: [
                {
                    value: '3',
                    label: '现在考虑'
                },
                {
                    value: '5',
                    label: '半年内'
                },
                {
                    value: '7',
                    label: '1年内'
                },
                {
                    value: '9',
                    label: '1-3年'
                },
                {
                    value: '11',
                    label: '3年以上'
                },
            ],
            cityList: newFirstCityData,
            other: '其他',
            otherCityValue: '',
            showOtherCityInput: false,
        }
    },
    computed: {
        isShowSearchCondition() {
            if(this.searchConditions && this.searchConditions.length > 0) {
                let showResult = this.searchConditions.some(item => {
                    return item.value;
                })
                return showResult;
            } else {
                return false;
            }
        },
        isFF() {
            return navigator.userAgent.indexOf('Firefox') > -1;
        },
        selectedCreatorIds() {
            let tabType = this.tabType;
            if(tabType == 1) {
                return this.candidateSearch.creatorIds;
            } else if(tabType == 2) {
                return this.candidateSearch.recommendCreatorIds;
            } else if(tabType == 3) {
                return this.candidateSearch.inquiryLogCreatorIds;
            }
        }
    },
    mounted() {
        if(this.isFirmSearch) {
            this.getLabelList();
        }
        this.getSearchRecordList();
    },
    watch: {
        isSelectAll: {
            handler(val){
                // console.log('isSelectAll-----------------------');
                // console.log(val);
                this.checkAll = val;
            },
            immediate: true
        },
        checkedCandidates: {
            handler(val){
                // console.log('checkedCandidates================');
                // console.log(val);
                this.checkedOptions = val;
            },
            immediate: true
        },
        tabType(val) {
            if(this.teamMemberPopoverVisible) {
                this.teamMemberPopoverVisible = false;
            }
        },
    },
    methods: {
        handleCitySelectConfirm(citiesObj, locationArray) {
            console.log(citiesObj, locationArray)
            const { cities, citiesText, } = citiesObj;

            this.cityArrValue = citiesText;
            this.candidateSearch.locations = [];
            this.candidateSearch.expectationLocations = [];
            
            if(locationArray.includes('期望')) {
                this.candidateSearch.expectationLocations = cities;
            }
            if(locationArray.includes('现居')) {
                this.candidateSearch.locations = cities;
            }

            this.addParams();
            this.cityAreaSelectVisible = false;
        },

        handleCitySelectReset() {
            this.cityArrValue = '';
            this.candidateSearch.locations = [];
            this.candidateSearch.expectationLocations = [];

            this.addParams();
            this.cityAreaSelectVisible = false;
        },
        // 新一线考虑时间选择下拉框显示与隐藏
        thinkingTimeVisibleChange(val) {
            // console.log(val);
            if(!val) { // 隐藏时
                // console.log(this.candidateSearch.expectationTypeIds);
                this.thinkingTime = this.candidateSearch.expectationTypeIds;
            }
        },
        // 新一线城市选择下拉框显示与隐藏
        thinkingCityVisibleChange(val) {
            console.log(val);
            if(!val) { // 隐藏时
                // console.log(this.candidateSearch.expectationNewCityLocations);
                // this.thinkingTime = this.candidateSearch.expectationNewCityLocations;
                this.thinkingCity = this.newCityList;
            }
        },

        selectNewFirstCityChange(val) {
            // // console.log(val);
            // // console.log(this.thinkingCity);
            // if(val.includes(this.other)) {
            //     this.showOtherCityInput = true;
            //     this.otherCityValue = '';
            // } else {
            //     this.showOtherCityInput = false;
            //     this.other = '其他';
            // }
        },

        // 新一线筛选重置
        resetTime() {
            this.thinkingTime = [];

            this.candidateSearch.expectationTypeIds = this.thinkingTime;
            this.$refs.thinkingTimeSelect.blur();
            this.addParams();
        },
        resetCity() {
            this.thinkingCity = [];
            
            this.newCityList = [];
            this.candidateSearch.expectationNewCityAddress = null;

            this.candidateSearch.expectationNewCityLocations = this.thinkingCity;
            this.$refs.thinkingCitySelect.blur();
            this.addParams();
        },
        // 新一线筛选提交
        onSubmitTime() {
            this.candidateSearch.expectationTypeIds = this.thinkingTime;
            this.$refs.thinkingTimeSelect.blur();
            this.addParams();
        },
        onSubmitCity() {
            // 选择'其他'时的特殊判断
            // if(this.thinkingCity.indexOf(this.other) > -1) {
            //     if(this.otherCityValue) {
            //         // 将空格替换为逗号(,)
            //         this.otherCityValue = this.otherCityValue.replace(/\s/g, ',');
                    
            //         var index = this.thinkingCity.indexOf(this.other);
            //         this.thinkingCity.splice(index,1);
            //         this.thinkingCity.push(this.otherCityValue);

            //         this.other = this.otherCityValue;
            //     } else {
            //         var index = this.thinkingCity.indexOf(this.other);
            //         this.thinkingCity.splice(index,1);
            //         this.thinkingCity.push('其他');

            //         this.other = '其他';
            //         console.log(this.thinkingCity);
            //     }
            // }

            // console.log(this.thinkingCity);
            // this.candidateSearch.expectationNewCityLocations = this.thinkingCity.map(item => {
            //     if(item == '其他') {
            //         item = '0';
            //     }
            //     return item;
            // })
            this.candidateSearch.expectationNewCityLocations = this.thinkingCity;
            if(this.thinkingCity.includes('其他')) {
                this.candidateSearch.expectationNewCityAddress = '';
            } else {
                this.candidateSearch.expectationNewCityAddress = null;
            }

            this.newCityList = this.thinkingCity;
            this.candidateSearch.expectationNewCityLocations = this.thinkingCity.filter(item => {
                return item != '其他';
            })
            // console.log(this.thinkingCity);
            // console.log(this.candidateSearch.expectationNewCityLocations);
            this.$refs.thinkingCitySelect.blur();
            this.addParams();
        },

        // 点击全选
        handleCheckAllChange(val) {
            this.checkedOptions = val ? this.newCandidateList : [];
            
            this.$emit('get-checked-options', this.checkedOptions);
        },
        // 批量查看
        batchView() {
            let resumeId = this.checkedOptions.map( item => {
                // return item.id; // 候选人id
                return item.tbdResumeId; // 简历id
            });
            // console.log(resumeId);
            // resumeId.forEach(el => {
            //     // console.log(el);
            //     window.open(`/#/candidateDetail/${el}`, el);
            // })
            // console.log(this.checkedOptions);
            if(this.checkedOptions.length > 1) {
                if(sessionStorage.getItem('batchCandidateSelectIndex')) {
                    sessionStorage.removeItem('batchCandidateSelectIndex');
                }

                sessionStorage.setItem('batchViewCandidate', JSON.stringify(this.checkedOptions));

                window.open(`/#/batchCandidateDetail`);
            } else {
                window.open(`/#/candidateDetail/${resumeId[0]}`);
            }
        },

        handleTitleInput(ev) {
            this.isInputTitle = true;
            // this.title = ev.srcElement.value;
            this.title = ev;
        },
        handleTitleBlur() {
            if(this.isInputTitle) {
                this.candidateSearch.title = this.title;
                this.candidateSearch.notAnalyzedTitle = "";
            }
        },
        handleTitleSelect(title) {
            this.isInputTitle = false;
            this.candidateSearch.title = "";
            if(this.candidateSearch.notAnalyzedTitle == title.title) {
                this.title = this.candidateSearch.notAnalyzedTitle = "";
            } else {
                this.title = this.candidateSearch.notAnalyzedTitle = title.title;
            }
            this.addParams();
        },
        getTitleSearchList(queryString, cb) {
            let titleSearchs = this.titleSearchList,
                results = [];
            if(queryString) {
                results = titleSearchs.filter(title => {
                    return title.title.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
                });
            } else if(titleSearchs.length > 0) {
                results = titleSearchs;
            } else {
                results = [];
            }
            cb(results);
        },
        handleCompanyNameInput(ev) {
            this.isInputCompanyName = true;
            // this.companyName = ev.srcElement.value;
            this.companyName = ev;
        },
        handleCompanyNameBlur() {
            if(this.isInputCompanyName) {
                this.candidateSearch.companyName = this.companyName;
                this.candidateSearch.notAnalyzedCompanyName = "";
            }
        },
        handleCompanyNameSelect(company) {
            this.isInputCompanyName = false;
            this.candidateSearch.companyName = "";
            if(this.candidateSearch.notAnalyzedCompanyName == company.company) {
                this.companyName = this.candidateSearch.notAnalyzedCompanyName = "";
            } else {
                this.companyName = this.candidateSearch.notAnalyzedCompanyName = company.company;
            }
            this.addParams();
        },
        getCompanyNameSearchList(queryString, cb) {
            let companyNameSearchs = this.companyNameList,
                results = [];
            if(this.candidateSearch.companyQueryType == 1) {
                if(queryString) {
                    results = companyNameSearchs.filter(company => {
                        return company.company.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
                    });
                } else if(companyNameSearchs.length > 0) {
                    results = companyNameSearchs;
                } else {
                    results = [];
                }
            }
            cb(results);
        },
        getRecommendJobNameSearchList(queryString, cb) {
            let titleSearchs = this.recommendTitleSearchList,
                results = [];
            if(queryString) {
                results = titleSearchs.filter(job => {
                    return job.itemText.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
                });
            } else if(titleSearchs.length > 0) {
                results = titleSearchs;
            } else {
                results = [];
            }
            cb(results);
        },
        handleRecommendJobNameInput(ev) {
            this.recommendJobName = ev.srcElement.value;
        },
        handleRecommendJobNameBlur() {
            this.candidateSearch.recommendJobName = this.recommendJobName;
        },
        handleRecommendJobNameSelect(job) {
            if(this.candidateSearch.recommendJobName == job.itemText) {
                this.recommendJobName = this.candidateSearch.recommendJobName = "";
            } else {
                this.recommendJobName = this.candidateSearch.recommendJobName = job.itemText;
            }
            this.addParams();
        },
        getLabelList() {
            tagService.getTagList()
                .then(res =>{
                    this.labelList = res.tagList;
                    this.labelFilterList = res.tagList;
                })
        },
        setTreeItems(list, level){
            let items = list;
            items.forEach((item) => {
                item.id = item.value = item.customerId || "";
                item.text = item.customerName || "";
                item.visible = level < 2 ? true : false;
                if(item.subCustomers) {
                    item.children = item.subCustomers;
                    if(item.subCustomers.length > 0) {
                        item.children = this.setTreeItems(item.children, level + 1);
                    }
                }
            })
            return items;
        },
        membersSelect(value, text) {
            let tabType = this.tabType;
            if(tabType == 1) {
                this.creators = text;
                this.candidateSearch.creatorIds = text == '全部成员' ? [] : value;
            } else if(tabType == 2) {
                this.recommenders = text;
                this.candidateSearch.recommendCreatorIds = text == '全部成员' ? [] : value;
            } else if(tabType == 3) {
                this.inquiryLogCreators = text;
                this.candidateSearch.inquiryLogCreatorIds = text == '全部成员' ? [] : value;
            }
            this.teamMemberPopoverVisible = false;
            this.addParams();
        },
        handleCustomerSelected(val){
            this.customerName = val.customerName;
            this.candidateSearch.customerId = val.customerId;
            this.addParams();
        },
        validateMobileValue(rule, mobile, callback) {
            if(mobile && !(mobileTest(mobile) || telephoneTest(mobile))) {
                callback(new Error("手机格式有误"));
            } else {
                callback();
            }
        },
        validateEmailValue(rule, email, callback) {
            if(email && !emailTest(email)) {
                callback(new Error("邮箱格式有误"));
            } else {
                callback();
            }
        },
        changeUpdatedTime(time) {
            this.candidateSearch.updatedStartTime = moment(time[0]).format("YYYY-MM-DD");
            this.candidateSearch.updatedEndTime =  moment(time[1]).format("YYYY-MM-DD");
            this.addParams();
        },
        handleAgeBlur(e, type) {
            let value = e.target.value;
            if(isNaN(value)) {
                e.target.value = this.candidateSearch[type];
            }else {
                if(value < 0 || value >= 100) {
                    e.target.value = this.candidateSearch[type];
                }else {
                    this.candidateSearch[type] = e.target.value = $.trim(value) === "" ? $.trim(value) : parseInt(value);
                }
            }
            this.isAgeInputFocused = false;
        },
        handleAgeEnter(e, type) {
            let value = e.target.value;
            if(isNaN(value)) {
                e.target.value = this.candidateSearch[type];
            }else {
                if(value < 0 || value >= 100) {
                    e.target.value = this.candidateSearch[type];
                }else {
                    this.candidateSearch[type] = e.target.value = $.trim(value) === "" ? $.trim(value) : parseInt(value);
                    this.addParams();
                }
            }
        },
        showMoreSearch() {
            this.isShowMoreSearch = !this.isShowMoreSearch;
        },
        changeNoViewTime(index) {
            switch(index) {
                case 1: 
                    // if(this.lastViewTimeText !== "三个月内") {
                        this.lastViewTimeText = "三个月内";
                        this.candidateSearch.lastViewTime = moment().subtract(3, 'month').format('YYYY-MM') + '-01';
                    // } else {
                    //     this.lastViewTimeText = "";
                    //     this.candidateSearch.lastViewTime = "";
                    // }
                    break;
                case 2:
                    // if(this.lastViewTimeText !== "六个月内") {
                        this.lastViewTimeText = "六个月内";
                        this.candidateSearch.lastViewTime = moment().subtract(6, 'month').format('YYYY-MM') + '-01';
                    // } else {
                    //     this.lastViewTimeText = "";
                    //     this.candidateSearch.lastViewTime = "";
                    // }
                    break;
                case 0:
                    // if(this.lastViewTimeText !== "全部时间") {
                        this.lastViewTimeText = "全部时间";
                        this.candidateSearch.lastViewTime = "1970-01-01";
                    // } else {
                    //     this.lastViewTimeText = "";
                    //     this.candidateSearch.lastViewTime = "";
                    // }
                    break;
            }
            this.addParams();
        },
        changeExcludeCondition(index) {
            // if(index == 1) {
            //     this.candidateSearch.isExcludeEmptyMobile = !this.candidateSearch.isExcludeEmptyMobile;
            // } else if(index == 0) {
            //     this.candidateSearch.isExcludeOnboardGuarantee = !this.candidateSearch.isExcludeOnboardGuarantee;
            // }
            this.addParams();
        },
        changeBackground(index) {
            if(index == 1) {
                this.candidateSearch.educationTags = this.candidateSearch.educationTags.length > 0 ? [] : ["985", "211"];
            } else if(index == 0) {
                // this.candidateSearch.isFamousCompany = !this.candidateSearch.isFamousCompany;
            }
            this.addParams();
        },
        handleSort(sort){
            if(sort !== this.candidateSearch.sort){
                this.candidateSearch.sort = sort;
                if (sort == 0) {
                    this.sortText ="综合排序"
                }else if(sort == 1){
                    this.sortText ="更新时间排序"
                }
                this.$emit('handle-sort');
                this.addParams();
            }
        },
        changeRecommendTime(time) {
            this.candidateSearch.recommendStartTime =  moment(time[0]).format("YYYY-MM-DD");
            this.candidateSearch.recommendEndTime = moment(time[1]).format("YYYY-MM-DD");
            this.addParams();
        },
        changeInquiryTime(time) {
            this.candidateSearch.inquiryLogStartTime = moment(time[0]).format("YYYY-MM-DD");
            this.candidateSearch.inquiryLogEndTime = moment(time[1]).format("YYYY-MM-DD");
            this.addParams();
        },
        changeJobHoppingOccasion(val) {
            this.candidateSearch.jobHoppingOccasion = val;
            this.addParams();
        },
        addParams() {
            let searchList = this.candidateSearch,
                params = [],
                excludeConditionText = [],
                backgroundConditionText = [];
            if(this.isInputTitle) {
                if(!this.title) {
                    searchList.title = searchList.notAnalyzedTitle = "";
                } else {
                    searchList.title = this.title;
                    searchList.notAnalyzedTitle = "";
                }
            }
            if(this.isInputCompanyName){
                if(!this.companyName) {
                    searchList.companyName = searchList.notAnalyzedCompanyName = "";
                } else {
                    searchList.companyName = this.companyName;
                    searchList.notAnalyzedCompanyName = "";
                }
            }
            if(searchList.recommendJobName !== this.recommendJobName) {
                searchList.recommendJobName = this.recommendJobName;
            }
            Object.keys(searchList).forEach(item => {
                const _itemNotEmpty = (searchList[item] instanceof Array) ? searchList[item].length >0 : searchList[item];
                if(_itemNotEmpty) {
                    switch(item) {
                        case 'keywordFilterType':
                        case 'companyQueryType':
                        case 'updatedStartTime':
                        case 'isExcludeOnboardGuarantee':
                        case 'isExcludeEmptyMobile':
                        case 'isFamousCompany':
                        case 'educationTags':
                        case 'isJobHunting':
                        case 'sort':
                        case 'recommendStartTime':
                        case 'inquiryLogStartTime':
                            break;
                        case 'keywords':
                            if(/^\s+$/.test(searchList.keywords)) {
                                searchList.keywords = searchList.keywords.trim();
                            } else {
                                params.push({
                                    title: 'keywords',
                                    value: searchList[item],
                                    data: this.candidateSearch.keywordFilterType,
                                });
                            }
                            break;
                        case 'notAnalyzedTitle': 
                            params.push({
                                title: 'notAnalyzedTitle',
                                value: searchList[item]
                            });
                            break;
                        case 'companyName': 
                            params.push({
                                title: 'companyName',
                                value: searchList[item],
                                data: this.candidateSearch.companyQueryType,
                            });
                            break;
                        case 'notAnalyzedCompanyName': 
                            params.push({
                                title: 'notAnalyzedCompanyName',
                                value: searchList[item],
                                data: this.candidateSearch.companyQueryType,
                            });
                            break;
                        case 'creatorIds':
                            if(this.creators !== '全部成员') {
                                params.push({
                                    title: 'creators',
                                    value: this.creators,
                                    data: this.candidateSearch.creatorIds,
                                });
                            }
                            break;
                        case 'updatedEndTime':
                            params.push({
                                title: 'updatedTime',
                                value: searchList.updatedStartTime + '至' + searchList.updatedEndTime,
                                data: [this.candidateSearch.updatedStartTime, this.candidateSearch.updatedEndTime ],
                            });
                            break;
                        case 'lastViewTime':
                            params.push({
                                title: 'lastViewTime',
                                value: this.lastViewTimeText
                            });
                            break;
                        case 'recommendEndTime':
                            params.push({
                                title: 'recommendTime',
                                value: searchList.recommendStartTime + '至' + searchList.recommendEndTime,
                                data: [this.candidateSearch.recommendStartTime, this.candidateSearch.recommendEndTime],
                            });
                            break;
                        case 'recommendLocation':
                            params.push({
                                title: 'recommendLocation',
                                value: cityMap[searchList[item]],
                                data: this.candidateSearch.recommendLocation,
                            });
                            break;
                        case 'customerId':
                            params.push({
                                title: 'customer',
                                value: this.customerName,
                                data: this.candidateSearch.customerId,
                            });
                            break;
                        case 'recommendCreatorIds':
                            if(this.recommenders !== '全部成员') {
                                params.push({
                                    title: 'recommenders',
                                    value: this.recommenders,
                                    data: this.candidateSearch.recommendCreatorIds,
                                });
                            }
                            break;
                        case 'inquiryLogEndTime':
                            params.push({
                                title: 'inquiryLogTime',
                                value: searchList.inquiryLogStartTime + '至' + searchList.inquiryLogEndTime,
                                data: [this.candidateSearch.inquiryLogStartTime, this.candidateSearch.inquiryLogEndTime],
                            });
                            break;
                        case 'inquiryLogCreatorIds':
                            if(this.inquiryLogCreators !== '全部成员') {
                                params.push({
                                    title: 'inquiryLogCreators',
                                    value: this.inquiryLogCreators,
                                    data: this.candidateSearch.inquiryLogCreatorIds,
                                });
                            }
                            break;

                        //  期待和现居
                        case 'locations':
                        case 'expectationLocations':
                            params.push({
                                title: item,
                                value: this.cityArrValue,
                                data: searchList[item],
                            });
                            break;

                        case 'expectationTypeIds':
                            // console.log(searchList[item]);
                            // console.log(this.candidateSearch.expectationTypeIds);
                            // 选中的是value数组，转为label数组显示文字
                            let newFirstTimeText = [];
                            searchList[item].forEach(el => {
                                this.timeOptions.forEach(option => {
                                    if(option.value == el) {
                                        newFirstTimeText.push(option.label);
                                    }
                                })
                            })
                            params.push({
                                title: 'expectationTypeIds',
                                value: newFirstTimeText.join(','),
                                data: this.candidateSearch.expectationTypeIds,
                            });
                            break;
                        case 'expectationNewCityLocations':
                            // console.log(searchList[item]);
                            // 选中的是value数组，转为label数组显示文字
                            let newFirstCityText = [];
                            searchList[item].forEach(el => {
                                // // 深拷贝一份cityList数组
                                // let cityListTemp = this.cityList.slice();
                                // cityListTemp.push({value: '0', label: '其他'});
                                // cityListTemp.forEach(city => {
                                //     if(city.value == el) {
                                //         newFirstCityText.push(city.label);
                                //     }
                                // })
                                this.cityList.forEach(city => {
                                    if(city.value == el) {
                                        newFirstCityText.push(city.label);
                                    }
                                })
                            })
                            if(!newFirstCityText.includes('其他') && this.newCityList.includes('其他')) {
                                newFirstCityText.push('其他')
                            }
                            params.push({
                                title: 'expectationNewCityLocations',
                                value: newFirstCityText.join(','),
                                data: this.candidateSearch.expectationNewCityLocations,
                            });
                            break;
                        case 'gender': // 性别
                            params.push({
                                title: 'gender',
                                value: this.genderList.find(it => it.value == this.candidateSearch.gender)?.label,
                                data: this.candidateSearch.gender,
                            });
                            break;
                        default:
                            params.push({
                                title: item,
                                value: searchList[item]
                            });
                    }
                }
            })

            let excludeCondition = [];
            if(searchList.isExcludeOnboardGuarantee) {
                excludeConditionText.push("在保护期的人选");
                excludeCondition.push('isExcludeOnboardGuarantee');
            }
            if(searchList.isExcludeEmptyMobile) {
                excludeConditionText.push("无效号码");
                excludeCondition.push('isExcludeEmptyMobile');
            }
            if(excludeConditionText.length > 0) {
                params.push({
                    title: 'excludeCondition',
                    value: excludeConditionText.join(', '),
                    data: excludeCondition,
                });
            }

            let backgroundCondition = [];
            if(searchList.isFamousCompany) {
                backgroundConditionText.push("名企");
                backgroundCondition.push('isFamousCompany');
            }
            if(searchList.educationTags.length > 0) {
                backgroundConditionText.push("985/211");
                backgroundCondition.push('educationTags');
            }
            if(backgroundConditionText.length > 0) {
                params.push({
                    title: 'backgroundCondition',
                    value: backgroundConditionText.join(', '),
                    data: backgroundCondition,
                });
            }

            if(this.isSaveFilterParam) {
                this.$emit('add-search-param', params, 2);
            } else {
                this.$emit('add-search-param', params, 1);
            }

            this.isSaveFilterParam = false;
        },
        removeLabel(param) {
            let search = this.candidateSearch;
            this.isSaveFilterParam = true;
            switch(param.title) {
                case 'title':
                case 'notAnalyzedTitle':
                    this.title = search.title = search.notAnalyzedTitle = "";
                    break;
                case 'companyName':
                    this.companyName = search.companyName = "";
                    break;
                case 'notAnalyzedCompanyName':
                    this.companyName =  search.notAnalyzedCompanyName = "";
                    break;
                case 'creators':
                    this.creators = "全部成员";
                    this.$nextTick(()=> {
                        this.$refs.teamSelect && this.$refs.teamSelect.handleClear();
                    });
                    search.creatorIds = [];
                    break;
                case 'updatedTime':
                    this.updatedTime = [];
                    search.updatedStartTime = "";
                    search.updatedEndTime = "";
                    break;
                case 'lastViewTime':
                    this.lastViewTimeText = "";
                    search.lastViewTime = "";
                    break;
                case 'excludeCondition':
                    search.isExcludeOnboardGuarantee = false;
                    search.isExcludeEmptyMobile = false;
                    break;
                case 'backgroundCondition':
                    search.isFamousCompany = false;
                    search.educationTags = [];
                    break;
                case 'recommendTime':
                    this.recommendTime = [];
                    search.recommendStartTime = "";
                    search.recommendEndTime = "";
                    break;
                case 'recommendJobName':
                    this.recommendJobName = search.recommendJobName = "";
                    break;
                case 'customer':
                    this.customerName = "";
                    search.customerId = "";
                    this.$nextTick(()=> {
                        this.$refs.customerSelect.customerId = "";
                    })
                    break;
                case 'recommenders':
                    this.recommenders = "全部成员";
                    this.$nextTick(()=> {
                        this.$refs.teamSelect && this.$refs.teamSelect.handleClear();
                    })
                    search.recommendCreatorIds = [];
                    break;
                case 'inquiryLogTime':
                    this.inquiryLogTime = [];
                    search.inquiryLogStartTime = "";
                    search.inquiryLogEndTime = "";
                    break;
                case 'inquiryLogCreators':
                    this.inquiryLogCreators = "全部成员";
                    this.$nextTick(()=> {
                        this.$refs.teamSelect && this.$refs.teamSelect.handleClear();
                    })
                    search.inquiryLogCreatorIds = [];
                    break;
                case 'minAge':
                    this.$refs.minAgeInput && (this.$refs.minAgeInput.value = "");
                    search.minAge = "";
                    break;
                case 'maxAge':
                    this.$refs.maxAgeInput && (this.$refs.maxAgeInput.value = "");
                    search.maxAge = "";
                case 'expectationTypeIds':
                    this.thinkingTime = [];
                    search.expectationTypeIds = [];
                    break;
                case 'expectationNewCityLocations':
                    this.thinkingCity = [];

                    this.newCityList = [];
                    search.expectationNewCityAddress = null;

                    search.expectationNewCityLocations = [];
                    break;
                case 'recommendLocation':
                    this.$refs.citySelect && this.$refs.citySelect.clearCity();
                    search.recommendLocation = "";
                    break;
                case 'locations':
                case 'expectationLocations':
                    search.locations = [];
                    search.expectationLocations = [];
                    this.cityArrValue = '';
                    this.$refs.provinceCitySelect?.resetCity();
                    break;
                default:
                    if(search.hasOwnProperty(param.title)) {
                        search[param.title] = "";
                    }
            }
            if(param.title === 'label') {
                this.labelFilter();
            }
            this.$emit('remove-search-label', param.title);
            this.addParams();
        },
        setLocalStorage(type) {
            switch(type){
                case 'recommendTip':
                    localStorage.setItem('recommendTipShowed',true);
                    this.isRecommendTipShow=false;
                    break;
                case 'recordTip':
                    localStorage.setItem('recordTipShowed',true);
                    this.isRecordTipShow=false;
                    break;
                default:break;
            }
        },
        switchOldCandidatePage() {
            this.$store.dispatch('setCandidateListUrl', '/candidates/enterprise');
            localStorage.setItem('isOldCandidateList', true);
            this.$router.push({
                path: "/candidates/enterprise"
            });
        },
        handleClearSearch(isSearch = true) {
            this.title = "";
            this.companyName = "";
            this.creators = "全部成员";
            this.updatedTime = [];
            this.lastViewTimeText = "";
            this.recommendTime = [];
            this.recommendJobName = "";
            this.customerName = "";

            this.cityArrValue = '';
            this.$refs.provinceCitySelect?.resetCity();

            this.$nextTick(()=> {
                this.$refs.customerSelect.customerId = "";
            })
            this.recommenders = "全部成员";
            this.inquiryLogTime = [];
            this.inquiryLogCreators = "全部成员";
            this.$nextTick(()=> {
                this.$refs.teamSelect && this.$refs.teamSelect.handleClear();
            })
            this.$refs.minAgeInput && (this.$refs.minAgeInput.value = "");
            this.$refs.maxAgeInput && (this.$refs.maxAgeInput.value = "");

            // 看新一线机会清空
            this.thinkingTime = [];
            this.thinkingCity = [];
            this.newCityList = [];

            // 推荐城市清空
            this.$refs.citySelect && this.$refs.citySelect.clearCity();
            
            this.candidateSearch = {
                keywords: "",
                keywordFilterType: 1,
                title: "",
                notAnalyzedTitle: "",
                companyName: "",
                notAnalyzedCompanyName: "",
                companyQueryType: 3,
                realName: "",
                mobile: "",
                email: "",
                label: "",
                gender: "",
                creatorIds: [],
                updatedStartTime: "",
                updatedEndTime: "",
                minAge: "",
                maxAge: "",
                lastViewTime: "",
                isExcludeOnboardGuarantee: false,
                isExcludeEmptyMobile: false,
                isFamousCompany: false,
                educationTags: [],
                isJobHunting: false,
                sort: 1,
                recommendStartTime: "",
                recommendEndTime: "",
                recommendJobName: "",
                recommendLocation: "",
                customerId: "",
                recommendCreatorIds: [],
                inquiryLogStartTime: "",
                inquiryLogEndTime: "",
                inquiryLogContent: "",
                inquiryLogCreatorIds: [],
                jobHoppingOccasion: "",

                expectationLocations: [],
                expectationTypeIds: [],
                expectationNewCityLocations: [],
                expectationNewCityAddress: null,
            };
            this.labelFilter();
            this.$emit('clear-search', isSearch);
        },
        handleSaveSearch() {
            console.log(this.searchConditions);
            if(this.saveSearchLoading) return;
            const _filterJson = {
                tabType: this.tabType,
                filters: this.searchConditions
            };
            const params = {
                searchFilterType: 0,
                title: JSON.stringify(_filterJson).substr(0, 10),
                searchFilterJson: JSON.stringify(_filterJson)
            };
            
            if(this.searchFiltersList.length >= 10) {
                return shortTips(`最多保存10条搜索记录！`);
            }

            this.saveSearchLoading = true;
            candidateService.addSearchFilters(params)
                .then(res => {
                    shortTips(`保存成功！`);
                    this.getSearchRecordList();
                }).finally(() => {
                    this.saveSearchLoading = false;
                });
        },

        // 获取保存的搜索启记录
        getSearchRecordList() {
            candidateService
            .getSearchRecordList(0)
            .then(res => {
                if(res && res.length > 0) {
                    this.searchFiltersList = res.map(item => {
                        const _searchFilterJson = JSON.parse(item.searchFilterJson);
                        return {
                            ...item,
                            ..._searchFilterJson
                        };
                    });
                } else {
                    this.searchFiltersList = [];
                }
            });
        },
        labelFilter(val) {
            if (val) {
                let tagDataList = [],
                    labelFilterList = [];
                this.labelList.forEach(category => {
                    if(category.tagDataList) {
                        tagDataList = category.tagDataList.filter(tag => tag.tagName.indexOf(val) > -1 || tag.tagName.toUpperCase().indexOf(val.toUpperCase()) > -1);
                        labelFilterList.push({
                            categoryId: category.categoryId,
                            categoryName: category.categoryName,
                            tagDataList: tagDataList
                        })
                    }
                })
                this.labelFilterList = labelFilterList;
            } else {
                this.labelFilterList = this.labelList;
            }
        },
        handleCitySelected(province, city) {
            this.candidateSearch.recommendLocation = city;
            this.addParams();
        },

        useSearchRecord(item) {
            console.log(`user item:`, item)
            this.handleClearSearch(false); // 清掉数据
            // 使用核心业务代码在这里
            this.tabType = item.tabType;

            // 获取期待或者现居位置
            const expectAndNowLocations = item.filters.filter(it => ['locations', 'expectationLocations',].includes(it.title));

            item.filters.forEach(item => {
                // console.log(item);
                if( ['locations', 'degree', 'yearOfExperienceRegions', 'skillStandardTags', 'workExperienceCompaniesName', 'customer', 'recommendStatus', 'recommendLocation', 'expectationTypeIds', 'expectationNewCityLocations','expectationLocations',].includes(item.title) ) {
                    //  'recommendJobName',
                    this.$parent.addSearchParam([item], 3, false);
                    if(['locations', 'expectationLocations'].includes(item.title)) {
                        this.candidateSearch[item.title] = item.data;
                        this.cityArrValue = item.value;

                        if(!this.isShowMoreSearch) this.showMoreSearch();
                        this.$nextTick(() => {
                            this.$refs.provinceCitySelect?.setDefaultParams(expectAndNowLocations);
                        });
                    }
                    if(item.title == 'customer') {
                        this.customerName = item.value;
                        this.candidateSearch.customerId = item.data;
                        this.$nextTick(() => {
                            this.$refs.customerSelect.setData(item.data);
                        });
                    }

                    if(item.item == 'recommendLocation') {
                        this.candidateSearch.recommendLocation = item.data;
                        this.$nextTick(() => {
                            this.$refs.citySelect.setDefaultCity(item.data);
                        })
                    }

                    if(item.title == 'expectationTypeIds') {
                        this.thinkingTime = item.data;
                        this.candidateSearch.expectationTypeIds = item.data;
                    }
                    if(item.title == 'expectationNewCityLocations') {
                        if(item.value.includes('其他')) {
                            this.candidateSearch.expectationNewCityAddress = "";
                            let tempCityList = item.data.slice();
                            tempCityList.push('其他');
                            this.thinkingCity = tempCityList;
                            this.newCityList = tempCityList;
                        } else {
                            this.candidateSearch.expectationNewCityAddress = null;
                            this.thinkingCity = item.data;
                            this.newCityList = item.data;
                        }

                        this.candidateSearch.expectationNewCityLocations = item.data;
                    }
                } else {
                    
                    if (item.title == 'title') {
                        this.isInputTitle = true;
                        this.title = this.candidateSearch.title = item.value;
                    } 
                    else if(item.title == 'notAnalyzedTitle') {
                        this.isInputTitle = false;
                        this.title = this.candidateSearch.notAnalyzedTitle = item.value;
                    }
                    else if(item.title == 'keywords') {
                        this.candidateSearch.keywordFilterType = item.data;
                        this.candidateSearch[item.title] = item.value;
                    } else if(item.title == 'lastViewTime'){
                        switch(item.value){
                            case '三个月内':
                                this.candidateSearch.lastViewTime = moment().subtract(3, 'month').format('YYYY-MM') + '-01';
                                break;
                            case '六个月内':
                                this.candidateSearch.lastViewTime = moment().subtract(6, 'month').format('YYYY-MM') + '-01';
                                break;
                            case '全部时间':
                                this.candidateSearch.lastViewTime = "1970-01-01";
                                break;
                            default: 
                                this.candidateSearch.lastViewTime = "";
                        }
                        this.lastViewTimeText = item.value;
                    } else if (item.title == 'companyName'){
                        this.companyName = this.candidateSearch.companyName = item.value;
                        this.candidateSearch.companyQueryType =  item.data;
                    } else if (item.title == 'notAnalyzedCompanyName'){
                        this.companyName = this.candidateSearch.notAnalyzedCompanyName = item.value;
                        this.candidateSearch.companyQueryType =  item.data;
                    } else if (item.title == 'creators') {
                        this.candidateSearch.creatorIds = item.data;
                        this.creators = item.value;
                    } else if(item.title == 'updatedTime') {
                        this.updatedTime = item.data;
                        this.candidateSearch.updatedStartTime = item.data[0];
                        this.candidateSearch.updatedEndTime = item.data[1];
                    } else if(item.title == 'minAge') {
                        this.candidateSearch[item.title] = item.value;

                        if(!this.isShowMoreSearch) this.showMoreSearch();
                        this.$nextTick(() => {
                            this.$refs.minAgeInput && (this.$refs.minAgeInput.value = item.value);
                        });
                    } else if(item.title == 'maxAge') {
                        this.candidateSearch[item.title] = item.value;
                        
                        this.$nextTick(() => {
                            this.$refs.maxAgeInput && (this.$refs.maxAgeInput.value = item.value);
                        });
                    } else if(item.title == 'excludeCondition') {
                        if(item.data.includes('isExcludeOnboardGuarantee')){
                            this.candidateSearch.isExcludeOnboardGuarantee = true;
                        }
                        if(item.data.includes('isExcludeEmptyMobile')){
                            this.candidateSearch.isExcludeEmptyMobile = true;
                        }
                    } else if(item.title == 'backgroundCondition') {
                        if(item.data.includes('isFamousCompany')){
                            this.candidateSearch.isFamousCompany = true;
                        }
                        if(item.data.includes('educationTags')){
                            this.candidateSearch.educationTags = ["985", "211"];
                        }
                    } else if(item.title == 'recommendTime') {
                        this.recommendTime = item.data;
                        this.candidateSearch.recommendStartTime = item.data[0];
                        this.candidateSearch.recommendEndTime = item.data[1];
                    } else if(item.title == 'recommendJobName') {
                        this.recommendJobName = this.candidateSearch.recommendJobName = item.value;
                    } else if(item.title == 'customerId') {
                        this.customerName = item.value;
                        this.candidateSearch.customerId = item.data;
                    } else if (item.title == 'recommenders') {
                        this.candidateSearch.recommendCreatorIds = item.data;
                        this.recommenders = item.value;
                    } else if(item.title == 'inquiryLogTime') {
                        this.inquiryLogTime = item.data;
                        this.candidateSearch.inquiryLogStartTime = item.data[0];
                        this.candidateSearch.inquiryLogEndTime = item.data[1];
                    } else if (item.title == 'inquiryLogContent') {
                        this.candidateSearch.inquiryLogContent = item.value;
                    } else if(item.title == 'inquiryLogCreators') {
                        this.inquiryLogCreators = item.value;
                        this.candidateSearch.inquiryLogCreatorIds = item.data;
                    } else if(item.title == 'jobHoppingOccasion') {
                        this.candidateSearch.jobHoppingOccasion = item.value;
                    } else if(item.title == 'gender') {
                        this.candidateSearch[item.title] = item.data;
                        if(!this.isShowMoreSearch) this.showMoreSearch();
                    } else {
                        this.candidateSearch[item.title] = item.value;
                    }
                }
            });

            this.addParams();
        },
        deleteSearchRecord(id) {
            this.$confirm('操作将删除本条搜索记录，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    candidateService.deleteSearchFilters(id).then(res => {
                        shortTips(`搜索记录删除成功!`);
                        this.getSearchRecordList();
                    });
                }).catch(() => {});
        },
        handleCompanyQueryType() {
            if(this.candidateSearch.companyQueryType != '1') {
                this.companyName = this.candidateSearch.notAnalyzedCompanyName = "";
                this.removeLabel({
                    title:"notAnalyzedCompanyName",
                    data: 1,
                });
            }
        },

        // 这里为处于期望城市和现居城市两个字段合并的代码 Start
        isShowTag(search) {
            // this.searchConditions.some(it => ['locations', 'expectationLocations'].includes(it.title))
            if(['locations', 'expectationLocations'].includes(search.title)){
                const _locationArray = this.searchConditions.filter(it => ['locations', 'expectationLocations'].includes(it.title));
                if(_locationArray.length == 2 && search.title == 'expectationLocations') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return search.value;
            }
        },
        generateLocationStr() {
            const _locationArray = this.searchConditions.filter(it => ['locations', 'expectationLocations'].includes(it.title));
            let preStr = '';
            if(_locationArray.length == 1){
                if(_locationArray[0].title == 'locations') {
                    preStr = '现居城市：';
                } else {
                    preStr = '期望城市：';
                }
            }
            if(_locationArray.length == 2){
                preStr = '期望现居城市：';
            }
            return preStr + _locationArray[0]?.value || preStr;
        },
        isShowFilterTag(item, filters) {
            if(['locations', 'expectationLocations'].includes(item.title)){
                const _locationArray = filters.filter(it => ['locations', 'expectationLocations'].includes(it.title));
                if(_locationArray.length == 2 && item.title == 'expectationLocations') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return item.value;
            }
        },
        generateFilterLocationStr(filters) {
            const _locationArray = filters.filter(it => ['locations', 'expectationLocations'].includes(it.title));
            let preStr = '';
            if(_locationArray.length == 1){
                if(_locationArray[0].title == 'locations') {
                    preStr = '现居城市：';
                } else {
                    preStr = '期望城市：';
                }
            }
            if(_locationArray.length == 2){
                preStr = '期望现居城市：';
            }
            return preStr + _locationArray[0]?.value || preStr;
        },
        // 这里为处于期望城市和现居城市两个字段合并的代码 End


    }
}
</script>

<style lang="scss" scope>

.city-select-popver.el-popover {
    padding: 0;
    .city-select-popver-content {
        padding: 15px 15px 0;
        .city-type {
            margin-bottom: 20px;
        }

        .selected-cities {
            span {
                display: inline-block;
                padding: 3px 8px;
                margin-right: 5px;
                background-color: #fff;
                font-size: 14px;
                color: #38BC9D;
                border: 1px solid #D3D3D3;

                i {
                    color: #D3D3D3;
                }
            }
        }

        .search-city {
            margin: 20px 0;
        }
    }

    .el-cascader-panel {
        .el-cascader-menu {
            border: none;
        }
        .el-cascader-menu__wrap {
            height: 290px !important;
            .el-cascader-menu__list {
                padding: 0;
            }
        }
        .el-cascader-menu:nth-child(1) {
            .el-cascader-node .el-checkbox {
                display: none;
            }
        }
        .el-cascader-menu:nth-child(2) {
            background-color: #F5F5F5;
        }
        &.is-bordered {
            border: none;
            border-radius: 0;
        }

        .el-cascader-node:not(.is-disabled):hover {
            // background-color: #F5F5F5 !important;
            color: #38BC9D;
        }
        .el-cascader-node:not(.is-disabled):focus {
            background-color: #F5F5F5 !important;
            color: #38BC9D;
            font-weight: normal;
        }
        .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active {
            color: #38BC9D;
            font-weight: normal;
            background-color: #F5F5F5;
        }
    }

    .city-select-popver-footer {
        border-top: 1px solid #CCCCCC;
        padding: 10px;
        text-align: right;
    }
}

.city-select-shield {
    .el-select-dropdown__list {
        min-width: 225px;
        padding-bottom: 50px !important;
    }
    .other-city {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
    }
    .select-btns-wrap {
        padding: 0 10px;
        width: 100%;
        height: 50px;
        background-color: #fff;
        position: absolute;
        bottom: -1px;
        right: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        border-top: 1px solid #ddd;
        justify-content: end;
        .btn {
            padding: 0;
            width: 70px;
            height: 30px;
            line-height: 30px;
            background-color: #EEEEEE;
            color: #999999;
            font-size: 14px;
            border-radius: 2px;
        }
        .sure{
            margin-left: 10px;
            background: #46d6b5;
            color: white;
            &:hover{
                background: #52e0bf;
            }
        }
    }
}


    .candidate-search {
        // background: #f6f6f6;
        background-color: #ffffff;
        width: 100%;
        // margin-bottom: 20px;
        padding: 16px 16px 0;
        // padding-bottom: 10px;
        // padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        // border-bottom: 1px solid #ddd;
        border-radius: 0 0 5px 5px;

        .el-checkbox__inner {
            width: 18px;
            height: 18px;
            border-color: #999;

            &:after {
                width: 4px;
                height: 8px;
                top: 2px;
                left: 5px;
            }
        }
        .el-checkbox__input.is-focus .el-checkbox__inner, .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: $primary;
        }

        .candidate-search-form {
            background-color: #fff;
            border-radius: 8px;
        }
        .switch-page-btn {
            padding: 0;
            min-width: 76px;
            position: absolute;
            top: 16px;
            right: 20px;
        }
        .form-container{
            position: relative;
            .use-saved-btn{
                // position: absolute;
                // bottom: 0;
                // left: 120px;
                // margin-left: 40px;
                color: $primary;
                font-size: 14px;
                cursor: pointer;

                &.use-saved-btn-normal {
                    display: inline-block;
                    margin-bottom: 20px;
                }
            }
            .text-operate-btn {
                // margin-right: 40px;
            }

            .form-item-container {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 12px;
                grid-row-gap: 12px;
                padding: 16px 10px;
                margin-top: 12px;
                background-color: #F6F6F6;
                border-radius: 8px;
            }
        }
        
    }
    .search-type-list.el-radio-group {
        height: 36px;
        margin-left: 3px;

        .el-radio {
            margin-right: 40px;

            .el-radio__inner {
                transform: scale(1.5);
            }
        }
        .el-radio.is-checked {
            .el-radio__label {
                color: $primary;
            }
        }
    }
    .search-form {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .el-form-item--mini.el-form-item {
            width: 100%;
            display: inline-block;
            margin-bottom: 0;
            &:first-child {
                width: 100%;
            }
            .el-form-item__content .el-input__inner,
            .el-form-item__label {
                height: 34px;
                line-height: 34px;
            }
            .el-form-item__content {
                width: 100%;
                position: relative;

                .search-block {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .search-select-box {
                    position: relative;
                    flex: 1;
                    height: 46px;
                    background-color: $primary;
                    border-radius: 10px;
                    border: 2px solid $primary;
                    overflow: hidden;

                    .do-search-btn {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        padding: 0 30px;
                        font-size: 18px;
                        color: #fff;
                        cursor: pointer;
                    }
                }

                .more-select-btn {
                    margin-left: 174px;
                    flex-shrink: 0;
                }

                .form-item-label {
                    height: 32px;
                    width: 68px;
                    text-align: center;
                    display: inline-block;
                    position: absolute;
                    z-index: 1;
                    top: 1px;
                    left: 1px;
                    white-space: nowrap;
                    background: #fff;
                    line-height: 32px;
                    font-size: 14px;
                    color: #666;
                    border-radius: 4px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 18px;
                        background: #ddd;
                        position: absolute;
                        right: 0;
                        top: 8px;
                    }
                    &.form-item-select {
                        .el-input__inner {
                            padding: 0 20px;
                            border: none;
                            height: 32px;
                            line-height: 32px;
                            font-size: 14px;
                            color: #333;
                        }
                    }
                    &.search-select-key {
                        top: 0;
                        left: 0;
                        width: 140px;
                        height: 42px;
                        line-height: 42px;
                        border-radius: 0;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        &::after {
                            display: none;
                        }

                        .el-icon-arrow-up:before {
                            content: '\e78f';
                            color: #999;
                        }

                        .el-input__inner {
                            padding: 0 20px;
                            border: none;
                            height: 42px;
                            line-height: 42px;
                            font-size: 16px;
                            color: #333;
                            border: 0 none;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }
                    }
                    &.search-select-companyname {
                        width: 106px;
                    }
                    .el-input__icon {
                        width: 20px;
                        line-height: 32px;
                        color: #333;
                    }
                }
                .form-item-label.new-first-city-label {
                    width: 100px;
                }
            }
            .el-input__inner {
                padding-left: 78px;
                font-size: 14px;
                color: #666;
                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }
        }
        .form-select-item.el-form-item {
            .search-input.el-date-editor {
                .el-input__icon {
                    display: none;
                }
            }
            .el-input__inner {
                // width: 397px;
                width: 100%;
            }
        }

        .new-first-city-form-select-item {
            border: 1px solid #ddd;
            border-radius: 4px;
            .el-range-city-select {
                display: flex;
                margin-left: 100px;

                .el-range-separator {
                    background-color: #fff;
                }

                .el-select {
                    .el-input__inner {
                        text-align: center;
                        padding: 0 10px;
                        border: none;
                        outline: none;
                    }
                    .el-icon-arrow-up:before {
                        display: none;
                    }

                    .el-select__tags {
                        max-width: 100% !important;

                        .el-tag.el-tag--info {
                            font-size: 14px;
                            height: 18px;
                            line-height: 18px;
                            background-color: #fff;
                            border: none;
                            color: #666;
                            padding: 0;
                            &:nth-of-type(1) {
                                padding-left: 8px;
                            }
                            &:nth-of-type(n+2) {
                                &::before {
                                    content: ",";
                                    margin-right: 6px;
                                }
                            }
                            .el-tag__close {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .el-age-editor.el-date-editor.el-input__inner:hover {
            border-color: #ddd;

            &.is-active:hover {
                border-color: $primary;
            }
        }
        .search-label-item {
            width: 100%;
            height: 43px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-left {
                display: flex;
                align-items: center;
                flex: 1;

                .search-input-label.el-form-item {
                    margin-bottom: 0;
                }
            }

            &-right {
                width: 150px;
                flex-shrink: 0;
                text-align: right;

                &.inline {
                    line-height: 34px;
                    text-align: left;
                }
            }

            .el-form-item--mini.el-form-item:first-child {
                width: 184px;
            }
            .el-form-item--mini.el-form-item:nth-child(2) {
                width: 430px;
            }
            .el-form-item--mini.el-form-item:nth-child(3) {
                width: 252px;
            }
        }
        .search-input-label.el-form-item {
            // width: 397px;
            width: 23%;
            margin-bottom: 10px;

            .el-form-item__label {
                color: #333;
                margin: 0;
            }
            .button {
                height: 34px;
                line-height: 34px;
            }
            .el-form-item__content {
                line-height: 34px;
                // width: 550px;
            }

            .el-dropdown-link {
                color: #333;
            }

            .el-dropdown-link-empty {
                color: #999;
            }
        }
        .customer-select-input,
        .search-input.el-input,
        .search-input.el-select,
        .search-input.el-autocomplete {
            // width: 397px;
            width: 100%;
            color: #666;
            &.search-input-key {
                // width: 815px;
                width: 100%;
                height: 42px;
                padding-right: 90px;
                .el-input__inner {
                    width: 100%;
                    height: 42px;
                    line-height: 42px;
                    padding: 0 20px 0 160px;
                    font-size: 16px;
                    color: #333;
                    border: 0 none;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
            &.company-name {
                .el-input__inner {
                    padding: 0;
                    padding-left: 116px;
                }
            }
        }
        .search-input.el-button-group {
            .el-button.el-button--default {
                padding: 0;
                width: 76px;
                text-align: center;
                font-size: 14px;
                background: #fff;
                color: #666;
                border: 1px solid #ddd;
                &:nth-of-type(1) {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px;
                }
                &:nth-last-of-type(1) {
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px;
                }
                &.selected {
                    background: $primary;
                    color: #fff;
                    border-color: $primary;
                }
            }
        }
        .radio-list.el-radio-group {
            .el-radio {
                margin-right: 20px;
                margin-bottom: 0;
                line-height: 36px;
                .el-radio__label {
                    padding-left: 6px;
                }
            }
            .el-radio.is-checked {
                .el-radio__label {
                    color: #666;
                }
            }
        }
        .more-btn {
            // position: absolute;
            // bottom: -5px;
            // right: 0;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    // text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    // text-decoration: underline;
                }
            }
        }
        
        
        .search-tip {
            width: 100%;
            padding: 25px 0;
            border-top: 1px solid #ddd;
            display: flex;
            .tip-title {
                width: 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $primary;
                .icon-font {
                    font-size: 28px;
                    margin-bottom: 6px;
                    color: $primary;
                }
            }
            .tip-content {
                margin-bottom: 0;
                margin-right: 10px;
            }
            .icon-font.icon-quxiao {
                font-size: 20px;
                color: #979797;
                cursor: pointer;
                &:hover {
                    color: red;
                }
            }
        }
    }

    .search-form.search-form-normal {
        .el-form-item--mini.el-form-item {
            width: 23%;
            margin-bottom: 16px;

            &.job-hunting-will {
                // width: 815px;
                width: 100%;
                margin-bottom: 10px;

                .el-form-item__content {
                    width: 580px;
                }
            }
        }
    }

    .team-select-box {
        width: 480px;
        height: 371px;
        margin-top: 5px;
        position: absolute;
        z-index: 10;
        background: #fff;
        box-shadow: 2px 2px 6px #999;
        .team-select.visible {
            .team-select-bd {
                margin-top: 5px;
            }
        }
        .button-list {
            margin-top: 333px;
            margin-left: 15px;
        } 
    }
    .search-param-container {
        // margin: 10px -20px -10px;
        // padding-top: 15px;
        // margin-bottom: 10px;
        min-height: 10px;
        background: #f6f6f6;
        .search-param-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 9px 0;
            background: #f6f6f6;
            // padding: 8px 20px 0;
            .search-param-item.el-tag {
                position: relative;
                max-width: 500px;
                height: 30px;
                line-height: 28px;
                margin: 5px 10px 5px 0;
                padding-right: 30px;
                font-size: 14px;
                border-radius: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .el-icon-close {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    margin-top: -8px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .candidate-total {
                display: inline-block;
                margin-left: 4px;
            }
            .clear-search {
                display: inline-flex;
                align-items: center;
                margin-left: 36px;
                cursor: pointer;

                .el-icon-delete {
                    font-size: 18px;
                    margin-right: 6px;
                }

                .font-icon {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                }

                // &:hover {
                //     color: $primary;
                // }
            }
        }
    }
    .work-hunting {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 46px;
        line-height: 46px;
        background: #fff;
        padding: 0 20px;
        margin-bottom: 10px;
        // border-bottom: 1px solid #EEEEEE;
        border-radius: 5px;

        .el-checkbox__inner {
            width: 18px;
            height: 18px;
            border-color: #999;

            &:after {
                width: 4px;
                height: 8px;
                top: 2px;
                left: 5px;
            }
        }
        .el-checkbox__input.is-focus .el-checkbox__inner, .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: $primary;
        }

        .batch-option {
            display: flex;
            align-items: center;
            margin-right: 30px;

            .batch-btn {
                border-radius: 4px;
                background-color: #F6F6F6;
                color: #999999;
                border: 1px solid #DDDDDD;
            }
            .batch-view {
                color: #fff;
                background-color: #38BC9D;
                border: 1px solid #38BC9D;
                &:hover {
                    background-color: #48CAAC;
                }
            }
            .info-text {
                margin-left: 24px;
                margin-right: 10px;
                color: #333;
                i {
                    color: #F4A623;
                    margin: 0 5px;
                }
            }
        }

        // .el-checkbox {
        //     .el-checkbox__label {
        //         color: #666;
        //         font-size: 14px;
        //     }
        //     &.is-checked {
        //         .el-checkbox__inner {
        //             border-color: $primary;
        //             background: #fff;
        //             &::after {
        //                 top: 0;
        //                 border-color: #38bc9d;
        //             }
        //         }
        //         .el-checkbox__label {
        //             color: #666;
        //         }
        //     }
        // }
        .sort-dropdown {
            .el-dropdown-selfdefine {
                color: #666;

                .sort-text {
                    color: #333;
                }
            }
        }
    }


.saved-search{
    padding: 0;
    .list-dialog{
        width: 500px;
        max-height: 346px;
        background: white;
        // box-shadow: 0px 5px 18px 0px rgba(198, 199, 198, 0.8);
        border-radius: 16px;

        &.list-dialog-empty {
            width: 284px;
        }

        .dialog-title{
            font-size: 14px;
            font-weight: bold;
            color: #666;
            line-height: 50px;
            padding-left: 20px;
            height: 52px;
            box-sizing: border-box;
            border-bottom: 2px solid #ddd;
        }
        .list-dialog-body{
            box-sizing: border-box;
            padding: 20px;
            .body-scroll{
                max-height: 262px;
                overflow-y: auto;
                .search-item{
                    height: auto;
                    background: #F6F6F6;
                    display: flex;
                    padding: 10px 10px 2px;
                    margin-bottom: 10px;
                    .item-l{
                        flex: 1;
                        >span{
                            background: #FFFFFF;
                            border: 1px solid #AFE4D8;
                            border-radius: 4px;
                            font-size: 12px;
                            color: #38BC9D;
                            // line-height: 40px;
                            margin-right: 10px;
                            padding: 7px 11px;
                            margin-bottom: 8px;
                            // word-break: normal;
                            display: inline-block;
                            box-sizing: border-box;
                        }
                    }
                    .item-r{
                        width: 80px;
                        text-align: right;
                        >a{
                            font-size: 14px;
                            color: #38BC9D;
                            line-height: 26px;
                            &:first-child{
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .empty-data{
                    text-align: center;
                    overflow: hidden;

                    &-img {
                        font-size: 200px;
                        margin-top: -20px;
                    }
                    // .empty-img{
                    //     display: inline-block;
                    //     background: url("~@src/assets/images/jobList/empty-bg@2x.png") no-repeat;
                    //     background-size: contain;
                    //     width: 160px;
                    //     height: 160px;
                    // }
                    >p{
                        font-size: 14px;
                        line-height: 20px;
                        color: #999;
                        margin-top: -30px;
                        margin-bottom: 20px;
                    }
                }


            }
        }
    }
}
</style>

<style lang="scss">
.sort-dropdown-menu.el-dropdown-menu {
    margin-top: 5px;
    padding: 0;
    .el-dropdown-menu__item {
        line-height: 40px;
        &.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
}
.search-autocomplete-popper.el-autocomplete-suggestion {
    // width: 397px !important;
    // width: 100% !important;
    .name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .name.selected {
        color: $primary;
        position: relative;
        &::after {
            display: inline-block;
            position: absolute;
            right: 0;
            width: 20px;
            height: 34px;
            line-height: 34px;
            font-family: icon-font !important;
            font-size: 16px;
            font-style: normal;
            content: "\E626";
        }
    }
}
.form-select-popover.el-select-dropdown {
    .el-select-dropdown__item.selected {
        &::after {
            display: inline-block;
            position: absolute;
            right: 20px;
            width: 20px;
            height: 40px;
            line-height: 40px;
            font-family: icon-font !important;
            font-size: 16px;
            font-style: normal;
            content: "\E626";
        }
    }

    .label-category {
        padding: 0 20px;
        margin: 0;
        line-height: 40px;
        font-size: 14px;
        color: #666;
        max-width: 395px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .el-select-dropdown__item {
        padding-left: 40px;
        max-width: 395px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.form-select-gender-popover.el-select-dropdown {
    .el-select-dropdown__item{
        padding-left: 80px;
    }
}
</style>
<template>
  <div class="firm-candidate-item" v-loading="candidateLoading">
    <!-- <div v-show="candidate.isJobHunting" class="hunting-state"></div> -->
    <div class="candidate-isviewed" v-if="candidate.isViewed">
        看过
    </div>
    <div class="information-header">
        <div class="information-header-left">
            <el-checkbox :value="candidate.isSelected" @change="handleCandidateSelect"></el-checkbox>
            <span
                class="candidate-realname ellipsis"
                data-event-id="E_enterprise_talent_item_click"
                @click.prevent="viewCandidateDetail(candidate)"
                :title="candidate.realName ? candidate.realName : '无姓名'"
            >
                {{candidate.realName ? candidate.realName : '无姓名'}}
            </span>
            <span class="candidate-absoluteCompletion"><i class="el-icon-star-on"></i>{{candidate.resumesCompletions}}</span>
            <span v-if="candidate.isJobHunting" class="hunting-state">求职</span>

            <div class="candidate-creator">
                <avatar
                    class="users-avatar"
                    :src="candidate.avatarUrl"
                    :user-id="candidate.updaterId ? candidate.updaterId : candidate.creatorId"
                    v-if="candidate.updaterId || candidate.creatorId"
                ></avatar>
                <img class="users-avatar default-avatar" src="~@src/assets/images/default-user.png" v-else />
                <span class="creator-date">
                    {{candidate.updated | date }} 更新
                </span>

                <span
                    v-if="!candidate.isFromAuthorization && candidate.isFromShare"
                    class="authorize-firm"
                >
                    (来自求分享-{{candidate.tradingType === 0 ? '云币' : '分成'}})
                </span>
                <span
                    v-if="candidate.isFromAuthorization"
                    class="authorize-firm"
                >
                    来自{{candidate.firmShortName}}
                </span>
            </div>
        </div>

        <div class="information-header-right">
            <div 
                v-if="!candidate.isCollected" 
                class="btn-box"
                :data-spm-e-id="`L_search_result_list_item_collect_button.${pager.size}xP${pager.current}-${candidateIndex+1}`"
            >
                <el-button 
                    type="primary" 
                    class="plain" 
                    @click.prevent="onCandidateCollect(candidate)"
                    data-event-id="E_enterprise_talent_item_collect_btn_click"
                >加入收藏</el-button>
            </div>
            <div 
                v-else 
                class="btn-box"
                :data-spm-e-id="`L_search_result_list_item_cancel_collect_button.${pager.size}xP${pager.current}-${candidateIndex+1}`"
            >
                <el-button 
                    class="calcel-collect" 
                    @click.prevent="onCandidateCollect(candidate)"
                    data-event-id="E_enterprise_talent_item_cancel_collect_btn_click"
                >取消收藏</el-button>
            </div>

            <div 
                class="btn-box"
                :data-spm-e-id="`L_search_result_list_item_recommend_button.${pager.size}xP${pager.current}-${candidateIndex+1}`"
            >
                <el-button 
                    type="primary" 
                    @click.prevent="recommendJobs(candidate.id)"
                    data-event-id="E_enterprise_talent_item_recommend_btn_click"
                >推荐候选人</el-button>
            </div>

            <div 
                class="btn-box"
                :data-spm-e-id="`L_search_result_list_item_add_communication_button.${pager.size}xP${pager.current}-${candidateIndex+1}`"
            >
                <el-button 
                    type="primary" 
                    @click.prevent="communicationJobs(candidate.id)"
                    data-event-id="E_enterprise_talent_item_add_communicate_btn_cli"
                >加入待沟通</el-button>
            </div>
            
        </div>



        <!-- <span class="info-text candidate-experience" v-if="candidate.yearOfExperienceInfo">
            {{candidate.yearOfExperienceInfo}}
        </span>
        <span class="info-text candidate-age" v-if="candidate.birthday && candidate.birthday.indexOf('0001-01-01') != 0">
            {{candidate.birthday | birthdayToAge }}
        </span>
        <span class="info-text candidate-location">
            {{candidate.locationInfo}}
        </span>
        <el-tooltip
            effect="dark"
            content="点击进入CSDN主页"
            placement="top"
            v-if="candidate.csdnTag"
        >
            <span>
                <div
                    v-show="candidate.csdnTag.hasBlog && !!candidate.csdnTag.name"
                    class="csdn-tag"
                >
                    <span class="tag-blog-name ellipsis">
                        <a :href="'https://blog.csdn.net/'+ candidate.csdnTag.account" target="_blank">{{candidate.csdnTag.name.toUpperCase()}}</a>
                    </span>
                </div>
                <div v-show="!candidate.csdnTag.hasBlog && !!candidate.csdnTag.name" class="csdn-tag-nolink">
                    <span class="tag-name ellipsis">
                        <a>
                            {{candidate.csdnTag.name}}
                        </a>
                    </span>
                </div>
            </span>
        </el-tooltip> -->
        <!-- <div class="label-list">
            <span
                class="label-item ellipsis"
                v-for="(label, index) in candidate.lables"
                :key="index"
                v-show="index < 3"
            >
                {{label}}
            </span>
        </div> -->
        
        <!-- <span
            class="candidate-isviewed"
            v-if="candidate.isViewed"
        >
            已浏览
        </span> -->
    </div>

    <div class="information-statistics">
        <div class="information-statistics-left">
            <div class="info">
                <span class="info-text candidate-experience" v-if="candidate.yearOfExperienceInfo">
                    {{candidate.yearOfExperienceInfo}}
                </span>
                <i v-if="candidate.yearOfExperienceInfo"></i>
                <span class="info-text candidate-age" v-if="candidate.birthday && candidate.birthday.indexOf('0001-01-01') != 0">
                    {{candidate.birthday | birthdayToAge }}
                </span>
                <i v-if="candidate.birthday && candidate.birthday.indexOf('0001-01-01') != 0"></i>
                <span class="info-text candidate-gender">
                    {{(candidate.gender || candidate.gender == 0) ? genderMap[candidate.gender] : '未知'}}
                </span>
                <i></i>
                <span class="info-text candidate-location">
                    {{candidate.locationInfo}}
                </span>
                <el-tooltip
                    effect="dark"
                    content="点击进入CSDN主页"
                    placement="top"
                    v-if="candidate.csdnTag"
                >
                    <span>
                        <div
                            v-show="candidate.csdnTag.hasBlog && !!candidate.csdnTag.name"
                            class="csdn-tag"
                        >
                            <span class="tag-blog-name ellipsis">
                                <a :href="'https://blog.csdn.net/'+ candidate.csdnTag.account" target="_blank">{{candidate.csdnTag.name.toUpperCase()}}</a>
                            </span>
                        </div>
                        <div v-show="!candidate.csdnTag.hasBlog && !!candidate.csdnTag.name" class="csdn-tag-nolink">
                            <span class="tag-name ellipsis">
                                <a>
                                    {{candidate.csdnTag.name}}
                                </a>
                            </span>
                        </div>
                    </span>
                </el-tooltip>
                <div class="label-list">
                    <span
                        class="label-item ellipsis"
                        v-for="(label, index) in candidate.lables"
                        :key="index"
                        v-show="index < 3"
                        :title="label"
                    >
                        {{label}}
                    </span>
                </div>
            </div>
            <div class="candidate-statistics">
                <div class="statistics-item">
                    <span class="count-text-box">浏览<i class="count-text">{{candidate.viewCount}}</i></span>
                </div>
                <div class="statistics-item statistics-inquiry">
                    <el-popover
                        width="300"
                        trigger="hover"
                        placement="bottom"
                        visible-arrow="false"
                        v-if="candidate.latestInquiryLog"
                        popper-class="enterprise-inquiry"
                    >
                        <ul class="inquiry-status-stage-vue" >
                            <h5 class="record-title">
                                <span class="record-user ellipsis">
                                    {{candidate.latestInquiryLog.creatorName}}
                                </span>
                                <div class="record-date">
                                    {{candidate.latestInquiryLog.updated | datetime}}
                                </div>
                            </h5>
                            <p class="record-reason double-line-ellipsis" :title="candidate.latestInquiryLog.content">
                                {{candidate.latestInquiryLog.content}}
                            </p>
                            <div class="inquire-info">
                                <ul>
                                    <li v-if="candidate.latestInquiryLog.jobHoppingOccasion">
                                        跳槽时机：
                                        <span>
                                            {{candidate.latestInquiryLog.jobHoppingOccasion}}
                                        </span>
                                    </li>
                                    <li v-if="candidate.latestInquiryLog.employerInfo">
                                        雇主画像：
                                        <span>
                                            {{candidate.latestInquiryLog.employerInfo}}
                                        </span>
                                    </li>
                                    <li v-if="candidate.latestInquiryLog.expectSalary">
                                        报酬期望：
                                        <span>
                                            {{candidate.latestInquiryLog.expectSalary}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </ul>
                        <div slot="reference" >
                            <span class="count-text-box">寻访<i class="count-text">{{candidate.inquiryLogCount}}</i></span>
                        </div>
                    </el-popover>
                    <div v-else>
                        <span class="count-text-box">寻访<i class="count-text">{{candidate.inquiryLogCount}}</i></span>
                    </div>
                </div>
                <div class="statistics-item statistics-recommend">
                    <el-popover
                        placement="bottom"
                        width="640"
                        trigger="hover"
                        :visible-arrow="false"
                        :open-delay="500"
                        @show="showRecommendDetails(candidate.id)"
                        v-if="candidate.recommendationCount > 0"
                        popper-class="enterprise-recommend"
                    >
                        <el-alert
                            v-if="isAgreementJob"
                            :title="`当前显示 ${recommendationsList.length} 条`"
                            :closable="false"
                            type="error"
                            style="margin: 4px 0;">
                        </el-alert>
                        <ul class="candidate-status-stage-vue" v-if="recommendationsList.length>0" v-loading="recommendationLoading">
                            <li
                                v-for="(recommend, recommendIndex) in recommendationsList"
                                :key="recommendIndex"
                            >
                                <a
                                    v-if="recommend.canAccessJob"
                                    class="status-summary ellipsis"
                                    target="_blank"
                                    :href="recommend.jobUrl"
                                    :title="recommend.jobInfo"
                                >
                                    {{recommend.jobInfo}}
                                </a>
                                <span
                                    v-if="!recommend.canAccessJob"
                                    class="status-summary ellipsis"
                                    :title="recommend.jobInfo"
                                >
                                    {{recommend.jobInfo}}
                                </span>
                                <!-- :type="recommend.recommendTheme" -->
                                <el-tag
                                    effect="plain"
                                    class="recommend-state"
                                    :class="recommend.recommendTheme"
                                >
                                    {{ recommend.recommendState }}
                                </el-tag>
                                <el-tag
                                    effect="plain"
                                    class="recommend-attachment"
                                >
                                    {{ recommend.recommendAttachment }}
                                </el-tag>
                                <div class="update-time ellipsis">
                                    <a
                                        v-if="recommend.canAccessRecommendation"
                                        class="link-hov"
                                        target="_blank"
                                        :href="recommend.recommendUrl"
                                        :title="recommend.created+' 推荐'"
                                        @click="handleCandidate(candidate.candidateId)"
                                    >
                                        <span>
                                            {{recommend.created | date }} 推荐 
                                        </span>
                                        <!-- <i class="fa fa-angle-double-right"></i> -->
                                        <font-icon class="right-arrow-icon" href="#icon-double-right-arrow"></font-icon>
                                    </a>
                                </div>
                            </li>
                            <li v-if="showMoreRecomendations">
                                <!-- <a
                                    class="more-link"
                                    :href="`/Headhunting/MyCompany.html#/candidateDetail/${candidate.tbdResumeId}/recommendationList`"
                                    target="_blank"
                                    @click="handleCandidate(candidate.candidateId)"
                                >
                                    更多
                                    <i class="fa fa-angle-double-right"></i>
                                </a> -->
                                <a
                                    class="more-link"
                                    :href="`/#/candidateDetail/${candidate.tbdResumeId}/recommendationList`"
                                    target="_blank"
                                    @click="handleCandidate(candidate.candidateId)"
                                >
                                    更多
                                    <!-- <i class="fa fa-angle-double-right"></i> -->
                                    <font-icon href="#icon-double-right-arrow"></font-icon>
                                </a>
                            </li>
                        </ul>
                        <div slot="reference" >
                            <span class="count-text-box">推荐<i class="count-text">{{candidate.recommendationCount}}</i></span>
                        </div>
                    </el-popover>
                    <div v-else>
                        <span class="count-text-box">推荐<i class="count-text">{{candidate.recommendationCount}}</i></span>
                    </div>
                </div>
                <div class="statistics-item">
                    <span
                        v-show="status.name == 8"
                        v-for="(status, statusIndex) in candidate.recommendStatusStatistic"
                        :key="statusIndex"
                        class="count-text-box"
                    >Offer<i class="count-text">{{status.count}}</i></span>
                </div>
            </div>
        </div>
        <div class="information-statistics-right">
            <div class="skill-tags">
                <span 
                    class="tag-item" 
                    v-for="(skill, skillIndex) in candidate.skillStandardTags" 
                    :key="skillIndex"
                    :class="skillTagsActive.length > 0 && (skillTagsActive.indexOf(skill) > -1) ? 'selected' : ''"
                    @click="handleSkillTagClick(skill)"
                    v-show="skillIndex < 4"
                >
                    {{skill}}
                </span>
                <!-- <bubble
                    type="blue"
                    size="small"
                    class="tag-item"
                    :enableCount="false"
                    v-for="(skill, skillIndex) in candidate.skillStandardTags"
                    :key="skillIndex"
                    :isSelect="skillTagsActive.length > 0 && (skillTagsActive.indexOf(skill) > -1)"
                    @click="handleSkillTagClick(skill)"
                    v-show="skillIndex < 4"
                >
                    {{skill}}
                </bubble> -->
            </div>
        </div>
    </div>

    <div class="information-body">
        <div class="information-body-block">
            <div class="employer-info-list" v-if="workExperiences && workExperiences.length > 0">
                <span class="title-line">
                    近期工作：
                </span>
                <!-- <div class="info-item info-item-old">
                    <div
                        class="employer-info-item ellipsis"
                        v-html="employerInfo"
                        v-for="(employerInfo, index) in workExperiences"
                        :key="index"
                    >
                    </div>
                </div> -->
                <div class="info-item" v-for="(employerInfo, index) in workExperiences" :key="index">
                    <div class="info-item-left">
                        <span class="company">{{employerInfo.companyName}}</span>
                        <span class="title">{{employerInfo.title}}</span>
                    </div>
                    <div class="info-item-right" v-if="!(formatToMonth(employerInfo.startDate) == '时间未知' && formatToMonth(employerInfo.endDate) == '时间未知')">
                        <span>{{formatToMonth(employerInfo.startDate)}}-{{formatToMonth(employerInfo.endDate)}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="information-body-block">
            <div class="degree-info-list" v-if="educationExperiences && educationExperiences.length > 0">
                <span class="title-line">
                    最高学历：
                </span>
                <!-- <div class="info-item">
                    <div
                        class="degree-info-item ellipsis"
                        v-html="educationExperiences[0]"
                    ></div>
                </div> -->
                <div class="info-item">
                    <div class="info-item-left">
                        <span class="school">{{educationExperiences[0].schoolName}}</span>
                        -
                        <span class="major">{{educationExperiences[0].majorName}}</span>
                        <span class="degree">{{degreeMap[educationExperiences[0].degree]}}</span>
                    </div>
                    <div class="info-item-right" v-if="!(formatToMonth(educationExperiences[0].startDate) == '时间未知' && formatToMonth(educationExperiences[0].endDate == '时间未知'))">
                        <span>{{formatToMonth(educationExperiences[0].startDate)}}-{{formatToMonth(educationExperiences[0].endDate)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Avatar from '#/component/common/avatar';
import Bubble from '#/component/common/bubble.vue';
import { recommendHashMap } from '#/js/config/recommend-detail.json';
import { candidate as candidateUrl } from '#/js/config/api.json';
import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
export default {
    components: {
        Avatar,
        Bubble
    },
    props: {
        candidate: Object,
        candidateIndex: Number,
        skillTagsSearchParams: Array,
        searchWord: Array,
        pager: Object
    },
    data() {
        return {
            candidateLabels: [],
            isLabelInputVisible: false,
            newLabelName: "",
            recommendationsList: [],
            showMoreRecomendations: false,
            candidateLoading: false,
            recommendationLoading: false,
            collectLoading: false,
            showCollectLabelPopup: false,
            genderMap: ['未知', '男', '女'],
            degreeMap: ['无',"高中及以下","大专","本科","硕士","MBA","博士"],
        }
    },
    computed: {
        token(){
            return this.$store.state.verificationToken.verificationToken;
        },
        educationExperiences() {
            return this.candidate.educations && this.candidate.educations.length > 0 ? this.candidate.educations.slice(0, 2) : [];
        },
        workExperiences() {
            return this.candidate.workExperiences && this.candidate.workExperiences.length > 0 ? this.candidate.workExperiences.slice(0, 2) : [];
        },
        skillTagsActive() {
            return this.skillTagsSearchParams;
        },
        isAgreementJob() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('AgreementJob');
        },
    },
    mounted() {
    },
    filters: {
        birthdayToAge(birthday) {
            return ((new Date).getFullYear() - birthday.slice(0, 4)) + '岁';
        }
    },
    methods: {
        formatToMonth(date) {
            if (!date || date == '0001-01-01') {
                return '时间未知';
            } else if(date.indexOf('9999') == 0){
                return '至今';
            }
            return date.slice(0, 7).replace('-', '.');
        },
        handleCandidateSelect(value) {
            this.$emit('handleCheck', value);
        },
        viewCandidateDetail(candidate){
            if(this.searchWord.length > 0) {
                let lightWords = this.searchWord.join(",");
                // .replace(/#/g, 'sharp')
                // window.open(`/Headhunting/MyCompany.html?keywords=${encodeURI(lightWords)}#/candidateDetail/${candidate.tbdResumeId}`);
                window.open(`/Headhunting/MyCompany.html#/candidateDetail/${candidate.tbdResumeId}?keywords=${encodeURIComponent(lightWords)}`);
                // window.open(`/?keywords=${encodeURIComponent(lightWords)}#/candidateDetail/${candidate.tbdResumeId}`);
            } else {
                window.open(`/Headhunting/MyCompany.html#/candidateDetail/${candidate.tbdResumeId}`);
                // window.open(`/#/candidateDetail/${candidate.tbdResumeId}`);
            }
            let url = candidateUrl.candidate_view.replace(/\%p/ig, candidate.id);
            _request({
                url: candidateUrl.candidate_view.replace(/\%p/ig, candidate.id),
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: this.token
            });
            this.$emit('view-candidate-detail', this.candidateIndex)
            return true;
        },
        handleSkillTagClick(skill) {
            let currentSkillTags = this.skillTagsActive || [];
            if(currentSkillTags.indexOf(skill) > -1) {
                currentSkillTags.splice(currentSkillTags.indexOf(skill), 1);
            } else {
                if(currentSkillTags.length >= 10) {
                    shortTips('技能词筛选最多选择10项');
                    return false;
                }
                currentSkillTags.push(skill);
            }
            if(currentSkillTags && currentSkillTags.length == 0) {
                this.$emit('remove-search-label', 'skillStandardTags');
            }
            this.$emit('add-search-param', [{
                title: 'skillStandardTags',
                value: currentSkillTags.join(", "),
                data: currentSkillTags
            }], 3);
        },
        recommendJobs(id) {
            this.$emit('recommend-candidate', id);
        },
        communicationJobs(id) {
            this.$emit('communication-job', id);
        },
        getCandidateLabels(id){
            this.collectLoading = true;
            _request({
                url: `/Candidate/${id}/GetLabelsByCandidate`,
                method: "GET"
            }).then(res => {
                this.collectLoading = false;
                this.candidateLabels = res;
            }).catch(err => {
                this.collectLoading = false;
            });
        },
        onCandidateCollect(candidate){
            this.candidateLoading = true;
            if(candidate.isCollected) {
                _request({
                    url: `/Resume/${candidate.tbdResumeId}/UnCollect`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((res) => {
                    this.candidateLoading = false;
                    candidate.isCollected = false;
                    shortTips('取消收藏成功');
                }).catch((err) => {
                    this.candidateLoading = false;
                    console.log(err);
                })
            } else {
                _request({
                    url: `/Resume/${candidate.tbdResumeId}/Collect`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((res) => {
                    this.candidateLoading = false;
                    candidate.isCollected = true;
                    shortTips('收藏成功');
                    this.getCandidateLabels(candidate.id);
                    this.newLabelName = '';
                    this.showCollectLabelPopup = true;
                }).catch((err) => {
                    this.candidateLoading = false;
                    console.log(err);
                })
            }
        },
        showCollections(candidate){
            if(candidate.isCollected){
                this.candidateLabels = [];
                this.newLabelName = '';
                this.showCollectLabelPopup = true;
                this.getCandidateLabels(candidate.id);
            }
        },
        hideCollections() {
            this.showCollectLabelPopup = false;
        },
        createLabel(id, name){
            this.collectLoading = true;
            _request({
                url: candidateUrl.create_candidate_label.replace(/\%p/ig, id),
                method: "POST",
                data: {
                    labelName: name
                }
            }).then((res) => {
                this.collectLoading = false;
                shortTips('添加成功');
                this.newLabelName = '';
                this.getCandidateLabels(id);
            }).catch(err => {
                this.collectLoading = false;
                console.log(err);
            });
        },
        removeLabel(id, name){
            this.collectLoading = true;
            _request({
                url: candidateUrl.remove_candidate_label.replace(/\%p/ig, id),
                method: "POST",
                data: {
                    name: name
                }
            }).then((res) => {
                this.collectLoading = false;
                this.getCandidateLabels(id);
            }).catch(err => {
                this.collectLoading = false;
                console.log(err);
            });
        },
        onLabelClick(id, label){
            if(label.isSelected){
                this.removeLabel(id, label.name);
            }else{
                this.createLabel(id, label.name);
            }
        },
        setLabelInputVisible(){
            this.isLabelInputVisible = true;
        },
        addNewLabel(id){
            let newLabelName = this.newLabelName.trim();
            if(!newLabelName){
                shortTips('标签名不能为空');
                return;
            }
            if(newLabelName.length > 20){
                shortTips('标签名不得超过20个字符');
                return;
            }
            if(/(\<[a-zA-Z\!\/\?])|(&#)/.test(newLabelName)) {
                shortTips('您输入的内容包含非法字符，请重新输入');
                return;
            }
            this.createLabel(id, newLabelName);
        },
        showRecommendDetails(id) {
            if(this.recommendationsList && this.recommendationsList.length <= 0) {
                this.recommendationLoading = true;
                _request({
                    url: candidateUrl.lastest_recommendations.replace(/\%p/, id),
                    method: "GET"
                }).then(res => {
                    this.recommendationLoading = false;
                    res.list.forEach(listItem => {
                        // listItem.jobUrl = `/Headhunting/MyCompany.html#/Job/${listItem.jobId}`;
                        listItem.jobUrl = `/#/Job/${listItem.jobId}`;
                        listItem.jobInfo = `${listItem.jobName}[${listItem.locationInfo}]-${listItem.employerName}`;
                        if([24,26].includes(listItem.recommendStatus)) {
                            listItem.recommendState = invoiceSubFlowStatus[listItem.flowStepName];
                            listItem.recommendTheme = 'info';
                        } else {
                            listItem.recommendState = recommendHashMap[String(listItem.recommendStatus)].content;
                            listItem.recommendTheme = recommendHashMap[listItem.recommendStatus].theme || 'info';
                            listItem.recommendAttachment = recommendHashMap[listItem.recommendStatus].attachment || '';
                        }
                        // if(listItem.recommendStatus == 64 && listItem.interviewConfirmedStatus != 1 && listItem.interviewConfirmedStatus != 2) {
                        //     listItem.recommendAttachment = '待确认';
                        // }
                        // listItem.recommendUrl = `/Headhunting/MyCompany.html#/candidateDetail/${id}/recommendation/${listItem.id}`;
                        listItem.recommendUrl = `/#/candidateDetail/${id}/recommendation/${listItem.id}`;
                    });
                    this.recommendationsList = res.list;
                    this.showMoreRecomendations = res.total > 3;
                }).catch(err => {
                    this.recommendationLoading = false;
                })
            }
        },
        handleCandidate(candidateId){
            lbdTrack.track('viewCandidateByRecommend',{
                candidateId: candidateId
            });
        }
    }
}
</script>

<style lang="scss" scope>
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-warning {
    color: #FC7859;
}
.firm-candidate-item {
    width: 100%;
    // height: 162px;
    padding: 18px 16px 10px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #fff;
    // background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // &:last-child {
    //     border-bottom: none !important;
    // }
    &:hover {
        border: 1px solid #38BC9D;

        // background: #F6F6F6;
        .information-header .header-right-container .candidate-operation {
            .icon-kafei,
            .icon-tuijian,
            .candidate-collection {
                display: inline-block;
            }
        }
    }
    .candidate-isviewed {
        position: absolute;
        top: -4px;
        left: -20px;
        width: 60px;
        height: 30px;
        line-height: 40px;
        text-align: center;
        font-size: 12px;
        color: #666;
        background-color: #DCDCDC;
        transform: rotate(-45deg);
    }
    .information-header,
    .information-body {
        display: flex;
        width: 100%;
    }
    .information-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 26px;
        margin-bottom: 14px;

        &-left {
            display: inline-flex;
            align-items: center;
            max-width: calc(100% - 300px);
        }

        &-right {
            display: inline-flex;
            align-items: center;
            position: relative;
            top: 10px;
            background-color: #fff;

            .btn-box + .btn-box {
                margin-left: 10px;
            }

            .el-button.el-button--primary {
                padding: 0 9px;

                &.plain {
                    padding: 0 14px;
                    color: #38BC9D;
                    background-color: #FFFFFF;
                    border: 1px solid #38BC9D;

                    &:hover {
                        background-color: #ebf8f5;
                    }
                }
            }

            .el-button.calcel-collect {
                border-color: #DCDCDC;
                color: #666;
            }
        }

        .el-checkbox {
            height: 18px;
            margin-right: 8px;

            .el-checkbox__input {
                vertical-align: top;
            }
        }

        .el-checkbox__inner {
            width: 18px;
            height: 18px;
            border-color: #999;

            &:after {
                width: 4px;
                height: 8px;
                top: 2px;
                left: 6px;
            }
        }
        .el-checkbox__input.is-focus .el-checkbox__inner, .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: $primary;
        }

        .candidate-realname {
            font-size: 18px;
            color: #333;
            width: fit-content;
            max-width: 150px;
            min-width: 50px;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }
        .info-text {
            color: #666;
            padding: 0 5px;
            white-space: nowrap;
            font-size: 14px;
            align-self: flex-end;
            &.candidate-experience {
                position: relative;
                padding-left: 10px;
                &::after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    background: #666;
                    position: absolute;
                    right: -1px;
                    top: 4px;
                }
            }
            &.candidate-age {
                position: relative;
                &::after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    background: #666;
                    position: absolute;
                    right: -1px;
                    top: 4px;
                }
            }
            &.candidate-location {
                padding-right: 0;
            }
        }
        .csdn-tag,
        .csdn-tag-nolink { 
            height: 22px;
            display: inline-block;
            margin-left: 15px;
            background-color: #D6E9FF;
            .tag-blog-name,
            .tag-name {
                padding: 0 8px;
                font-size: 12px;
                text-align: center;
                vertical-align: middle;
                a {
                    color: #7dabe1;
                }
            }
            .tag-name{
                a {
                    cursor: default;
                }
            }
        }
        .candidate-absoluteCompletion {
            display: inline-flex;
            align-items: center;
            padding: 0 4px;
            margin-left: 4px;
            height: 20px;
            font-size: 12px;
            color: #FF4B4B;
            background: rgba(255,75,75,0.1);
            border-radius: 3px;
            border: 1px solid #FFB6B6;

            i {
                font-size: 14px;
            }
        }
        .hunting-state{
            width: 34px;
            height: 20px;
            margin-left: 6px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;
            color: #FF9E21;
            background: rgba(255,236,199,0.7);
            border-radius: 3px;
            border: 1px solid #FFDDA8;
            flex-shrink: 0;
        }
        .candidate-isviewed {
            font-size: 12px;
            color: #b9b9b9;
            margin-left: 15px;
            white-space: nowrap;
        }

        .candidate-creator {
            display: inline-flex;
            margin-left: 12px;
            white-space: nowrap;
            .users-avatar {
                margin-right: 6px;
                a {
                    width: 24px;
                    height: 24px;
                }
                &.default-avatar {
                    width: 26px;
                    height: 26px;
                    max-height: unset;
                    max-width: unset;
                    border: 1px solid #ececec;
                    border-radius: 50%;
                }
            }
            .creator-date {
                color: #999;
                font-size: 14px;
                line-height: 26px;
            }
        }

        .authorize-firm {
            color: #FF9E21;
            margin-left: 4px;
            font-size: 14px;
            line-height: 26px;
            white-space: nowrap;
        }
        .header-right-container {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            .candidate-operation {
                margin: 0 7px;
                width: 84px;
                .icon-kafei,
                .icon-shoucangjia,
                .icon-tuijian {
                    font-size: 18px;
                    cursor: pointer;
                }
                .icon-kafei,
                .icon-tuijian {
                    margin-right: 15px;
                    display: none;
                }
                .candidate-collection {
                    display: none;
                    float: right;
                }
                .candidate-collection.active{
                    position: relative;
                    display: inline-block;
                    padding-top: 3px;
                    .icon-shoucangjia{
                        display: block;
                        color: $primary;
                    }
                }
                .collect-lable-popup{
                    display: block;
                    border-top: 2px solid $primary;
                    width: 230px;
                    background: #fff;
                    z-index: 999;
                    position: absolute;
                    top: 30px;
                    left: -101px;
                    padding: 15px;
                    text-align: center;
                    box-shadow: 0px 1px 4px 0px rgba(101,112,129,0.60);
                    .icon-agree{
                        color: $primary;
                    }
                    .text-grey{
                        font-size:12px;
                        color:#9b9b9b;
                    }
                    p{
                        line-height: 20px;
                    }
                    .arrow-up{
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        left: 45%;
                        top: -9px;
                        transform: rotate(45deg);
                        border-top: 2px solid $primary;
                        border-left: 2px solid $primary;
                        border-right: none;
                        border-bottom: none;
                        background: #fff;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 30px;
                        top: -30px;
                        left: 0;
                        pointer-events: none;
                    }
                    .add-new-label,.add-label-input{
                        margin-top: 20px;
                        font-size: 12px;
                        text-align: left;
                        line-height: 100%;
                        input{
                            border: 1px solid #dddddd;
                            width: 162px;
                            height: 24px;
                            text-indent: 5px;
                        }
                        .icon-tianjia{
                            color: $primary;
                            vertical-align: text-top;
                            font-size: 12px;
                        }
                        .icon-rec-add{
                            color: $primary;
                            font-size: 26px;
                            vertical-align: middle;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .information-statistics {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        &-left {

            .info {
                display: flex;
                align-items: center;
                height: 20px;
                font-size: 14px;
                color: #666;
                white-space: nowrap;

                > i {
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    margin: 0 8px;
                    background: #DCDCDC;
                }

                .label-list{
                    height: 20px;

                    .label-item{
                        display: inline-block;
                        margin-left: 6px;
                        padding: 0 6px;
                        height: 20px;
                        line-height: 20px;
                        color: $primary;
                        background-color: #EEF7F5;
                        border-radius: 3px;
                        white-space: nowrap;
                        cursor: default;
                        max-width: 84px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &.active{
                            background: $primary;
                            color: #fff;
                        }
                    }
                }
            }

            .candidate-statistics{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 316px;
                height: 34px;
                margin: 4px 20px 0 0;

                .statistics-item {
                    display: inline-block;
                    position: relative;
                    white-space: nowrap;

                    .el-popover__reference {
                        height: 34px;
                        color: #999;
                    }

                    .count-text-box {
                        color: #999;
                        cursor: default;

                        &:hover {
                            color: $primary;

                            .count-text {
                                color: $primary;
                            }
                        }
                    }

                    .count-text {
                        margin-left: 8px;
                        color: #333;
                    }
                }
            }
        }

        &-right {
            text-align: right;

            .skill-tags .tag-item {
                display: inline-block;
                min-width: 50px;
                max-width: 150px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                padding: 0 7px;
                margin-right: 6px;
                color: #999;
                background: #F6F6F6;
                border-radius: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                &:hover{
                    background: $primary;
                    color: #fff;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.selected {
                    color: $primary;
                    background: #EEF7F5;
                }
            }
        }
    }

    .information-body {
        justify-content: space-between;
        padding-top: 12px;
        border-top: 1px solid rgba(235,235,235,0.92);

        &-block {
            flex: 1;
            max-width: 46%;
            line-height: 22px;
            color: #666;
            font-size: 14px;
            flex-shrink: 0;
            overflow: hidden;

            .title-line {
                display: block;
                color: #333;
                line-height: 20px;
                margin-bottom: 6px;
            }

            .info-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2px;

                &.info-item-old {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &-left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                &-right {
                    width: 124px;
                    text-align: right;
                    flex-shrink: 0;
                    color: #999;
                }

                .company, .title, .school, .major {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .company {
                    max-width: 100%;
                    min-width: 60px;
                    padding-right: 16px;
                }
                .title {
                    max-width: 100%;
                    min-width: 60px;
                }
                .school {
                    max-width: 40%;
                }
                .major {
                    max-width: 40%;
                }
                .degree {
                    padding-left: 6px;
                    flex-shrink: 0;
                }
            }

            .info {
                // width: 376px;
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            // .degree-info-item,
            // .employer-info-item {
            //     width: 100%;
            //     margin-bottom: 2px;

            //     &:last-child {
            //         margin-bottom: 0;
            //     }
            // }
        }
        &-right {
            width: 360px;
            justify-content: space-between;
        }
    }
}
</style>

<style lang="scss">
.candidate-status-stage-vue {
    display: block;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    padding: 15px 10px 15px 20px;
    position: relative;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    text-align: left;
    margin-bottom: 0;
    min-height: 66px;
    .more-link {
        color: $primary;
    }
    li {
        padding: 5px 0;
        display: flex;
        flex-wrap: nowrap;
    }
    .status-summary {
        height: 24px;
        width: 280px;
        margin-right: 5px;
        line-height: 24px;
    }
    a.status-summary {
        &:hover {
            color: #38bc9d;
        }
    }
    .recommend-state {
        float: left;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 5px;
        cursor: default;
        &.warning{
            border: 1px solid #ff7200;
            color: #ff7200;
        }
        &.success{
            border: 1px solid #38bc9d;
            color: #38bc9d;
        }
        &.default{
            border: 1px solid #b9b9b9;
            color: #b9b9b9;
        }
        &.info{
            border: 1px solid #39f;
            color: #39f;
        }
        .info-purple {
            border: 1px solid #A8B6FF;
            color: #5560F7;
        }
    }
    .recommend-attachment {
        width: 70px;
        height: 24px;
        padding-left: 5px;
        background: none;
        border: none;
        text-align: left;
        line-height: 24px;
    }
    .update-time {
        width: 135px;
        text-align: right;
        line-height: 24px;
    }
    .right-arrow-icon {
        color: #333;
    }
    // .link-hov:hover .fa{
    //     color: $primary;
    // }
    .link-hov:hover {
        span, .right-arrow-icon{
            color: $primary;    
        }
    }
}

.inquiry-status-stage-vue{
    display: block;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    padding: 15px 0;
    position: relative;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    text-align: left;
    text-indent: 20px;
    margin-bottom: 0;
    .record-user{
        color:#199b7e;
        max-width: 150px;
    }
    .record-date{
        display: inline-block;
        font-size: 12px;
        float: right;
        margin-right: 20px;
    }
    .record-title{
        background: #f8f8f8;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        width: 100%;
    }
    .record-reason{
        line-height: 25px;
        padding: 0 20px;
        text-indent: 0;
        word-break: break-word;
    }
    .inquire-info{
        li{
            list-style: disc inside none;
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.enterprise-inquiry.el-popover{
    padding: 0;
    min-height: 66px;
    box-sizing: content-box;
    margin-top: -5px;
}
.enterprise-recommend.el-popover{
    padding: 0;
    min-height: 66px;
    box-sizing: content-box;
    margin-top: -5px;
}
</style>

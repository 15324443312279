import { render, staticRenderFns } from "./candidate-item.vue?vue&type=template&id=6d87b1cc&"
import script from "./candidate-item.vue?vue&type=script&lang=js&"
export * from "./candidate-item.vue?vue&type=script&lang=js&"
import style0 from "./candidate-item.vue?vue&type=style&index=0&id=6d87b1cc&lang=scss&scope=true&"
import style1 from "./candidate-item.vue?vue&type=style&index=1&id=6d87b1cc&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d87b1cc')) {
      api.createRecord('6d87b1cc', component.options)
    } else {
      api.reload('6d87b1cc', component.options)
    }
    module.hot.accept("./candidate-item.vue?vue&type=template&id=6d87b1cc&", function () {
      api.rerender('6d87b1cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/candidate-list/component/candidate-item.vue"
export default component.exports
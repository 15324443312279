var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "candidate-search" }, [
      _c(
        "div",
        { staticClass: "candidate-search-form" },
        [
          _vm.isFirmSearch
            ? _c(
                "el-radio-group",
                {
                  staticClass: "search-type-list radio-list",
                  model: {
                    value: _vm.tabType,
                    callback: function ($$v) {
                      _vm.tabType = $$v
                    },
                    expression: "tabType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("按简历内容查找"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("按推荐记录查找"),
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("按寻访记录查找"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tabType == 1 || !_vm.isFirmSearch,
                      expression: "tabType == 1 || !isFirmSearch",
                    },
                  ],
                  ref: "candidateSearchForm",
                  staticClass: "search-form",
                  attrs: {
                    size: "mini",
                    model: _vm.candidateSearch,
                    rules: _vm.candidateSearchRules,
                  },
                },
                [
                  _c("el-form-item", [
                    _c("div", { staticClass: "search-block" }, [
                      _c(
                        "div",
                        { staticClass: "search-select-box" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "form-item-label form-item-select search-select-key",
                              attrs: { placeholder: "全部关键字" },
                              model: {
                                value: _vm.candidateSearch.keywordFilterType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.candidateSearch,
                                    "keywordFilterType",
                                    $$v
                                  )
                                },
                                expression: "candidateSearch.keywordFilterType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部关键字", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "任一关键字", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticClass: "search-input search-input-key",
                            attrs: {
                              maxlength: 128,
                              placeholder:
                                "多个关键词使用逗号“,”分隔，不区分中英文",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.candidateSearch.keywords,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidateSearch, "keywords", $$v)
                              },
                              expression: "candidateSearch.keywords",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "do-search-btn",
                              on: { click: _vm.addParams },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "more-select-btn" }, [
                        _vm.tabType == "1"
                          ? _c(
                              "span",
                              {
                                staticClass: "more-btn text-operate-btn",
                                on: { click: _vm.showMoreSearch },
                              },
                              [
                                _vm.isShowMoreSearch
                                  ? [
                                      _vm._v(
                                        "\n                                        收起\n                                        "
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-up",
                                      }),
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                                        更多筛选条件\n                                        "
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-down",
                                      }),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-item-container" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("职位"),
                          ]),
                          _c("el-autocomplete", {
                            staticClass: "search-input",
                            attrs: {
                              maxlength: 128,
                              "popper-class": "search-autocomplete-popper",
                              placeholder: "请选择",
                              "fetch-suggestions": _vm.getTitleSearchList,
                            },
                            on: {
                              select: _vm.handleTitleSelect,
                              input: _vm.handleTitleInput,
                              blur: _vm.handleTitleBlur,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "name",
                                        class: {
                                          selected:
                                            item.title ==
                                            _vm.candidateSearch
                                              .notAnalyzedTitle,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.title) +
                                            "\n                                        ("
                                        ),
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.count,
                                                "",
                                                0
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          ")\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "form-item-label form-item-select search-select-companyname",
                              attrs: { placeholder: "全部公司" },
                              model: {
                                value: _vm.candidateSearch.companyQueryType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.candidateSearch,
                                    "companyQueryType",
                                    $$v
                                  )
                                },
                                expression: "candidateSearch.companyQueryType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部公司", value: 3 },
                              }),
                              _c("el-option", {
                                attrs: { label: "最近公司", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "曾在职公司", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _c("el-autocomplete", {
                            staticClass: "search-input company-name",
                            attrs: {
                              maxlength: 128,
                              "popper-class": "search-autocomplete-popper",
                              placeholder: "请输入",
                              "fetch-suggestions": _vm.getCompanyNameSearchList,
                            },
                            on: {
                              select: _vm.handleCompanyNameSelect,
                              input: _vm.handleCompanyNameInput,
                              blur: _vm.handleCompanyNameBlur,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "name",
                                        class: {
                                          selected:
                                            item.company ==
                                            _vm.candidateSearch
                                              .notAnalyzedCompanyName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.company) +
                                            "\n                                        ("
                                        ),
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.count,
                                                "",
                                                0
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(
                                          ")\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.companyName,
                              callback: function ($$v) {
                                _vm.companyName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("姓名"),
                          ]),
                          _c("el-input", {
                            staticClass: "search-input",
                            attrs: { placeholder: "请输入", maxlength: 20 },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.candidateSearch.realName,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidateSearch, "realName", $$v)
                              },
                              expression: "candidateSearch.realName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mobile" } },
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("手机"),
                          ]),
                          _c("el-input", {
                            staticClass: "search-input",
                            attrs: { placeholder: "请输入" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.candidateSearch.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.candidateSearch, "mobile", $$v)
                              },
                              expression: "candidateSearch.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isShowMoreSearch
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "email" } },
                              [
                                _c("span", { staticClass: "form-item-label" }, [
                                  _vm._v("邮箱"),
                                ]),
                                _c("el-input", {
                                  staticClass: "search-input",
                                  attrs: { placeholder: "请输入" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.addParams.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.candidateSearch.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.candidateSearch,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "candidateSearch.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { staticClass: "form-select-item" },
                              [
                                _c("span", { staticClass: "form-item-label" }, [
                                  _vm._v("标签"),
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "search-input",
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                      "popper-class": "form-select-popover",
                                      "filter-method": _vm.labelFilter,
                                    },
                                    on: { change: _vm.addParams },
                                    model: {
                                      value: _vm.candidateSearch.label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.candidateSearch,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression: "candidateSearch.label",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.labelFilterList,
                                      function (category) {
                                        return [
                                          category.tagDataList &&
                                          category.tagDataList.length > 0
                                            ? _c(
                                                "p",
                                                {
                                                  key:
                                                    "category-" +
                                                    category.categoryId,
                                                  staticClass: "label-category",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        category.categoryName
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            category.tagDataList,
                                            function (tag) {
                                              return _c("el-option", {
                                                key: "tag-" + tag.tagId,
                                                attrs: {
                                                  label: tag.tagName,
                                                  value: tag.tagName,
                                                },
                                              })
                                            }
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm.tabType == 1
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "form-item-label" },
                                      [_vm._v("创建者")]
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          "popper-class": "team-member-popover",
                                          placement: "bottom-start",
                                          width: "700",
                                          trigger: "click",
                                        },
                                        model: {
                                          value: _vm.teamMemberPopoverVisible,
                                          callback: function ($$v) {
                                            _vm.teamMemberPopoverVisible = $$v
                                          },
                                          expression:
                                            "teamMemberPopoverVisible",
                                        },
                                      },
                                      [
                                        _c("team-member-select", {
                                          ref: "teamSelect",
                                          attrs: {
                                            isAllMembers: true,
                                            isShowDimission: false,
                                            visible:
                                              _vm.teamMemberPopoverVisible,
                                            selected: _vm.selectedCreatorIds,
                                          },
                                          on: {
                                            "item-select-cb": _vm.membersSelect,
                                          },
                                        }),
                                        _c("el-input", {
                                          staticClass:
                                            "search-input has-team-select",
                                          attrs: {
                                            slot: "reference",
                                            readonly: true,
                                          },
                                          slot: "reference",
                                          model: {
                                            value: _vm.creators,
                                            callback: function ($$v) {
                                              _vm.creators = $$v
                                            },
                                            expression: "creators",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { staticClass: "form-select-item" },
                              [
                                _c("span", { staticClass: "form-item-label" }, [
                                  _vm._v("创建时间"),
                                ]),
                                _c("el-date-picker", {
                                  staticClass: "search-input",
                                  attrs: {
                                    align: "left",
                                    size: "small",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "range-separator": "-",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "picker-options": _vm.candidateTimeOption,
                                    "popper-class": "candidate-data-range",
                                  },
                                  on: { change: _vm.changeUpdatedTime },
                                  model: {
                                    value: _vm.updatedTime,
                                    callback: function ($$v) {
                                      _vm.updatedTime = $$v
                                    },
                                    expression: "updatedTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { staticClass: "form-select-item" },
                              [
                                _c("span", { staticClass: "form-item-label" }, [
                                  _vm._v("年龄"),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "el-age-editor el-date-editor el-range-editor el-input__inner search-input el-date-editor--daterange el-range-editor--small",
                                    class: _vm.isAgeInputFocused
                                      ? "is-active"
                                      : "",
                                  },
                                  [
                                    _c("input", {
                                      ref: "minAgeInput",
                                      staticClass: "el-range-input",
                                      attrs: {
                                        type: "text",
                                        autocomplete: "off",
                                        placeholder: "岁",
                                        name: "",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.isAgeInputFocused = true
                                        },
                                        blur: (e) =>
                                          _vm.handleAgeBlur(e, "minAge"),
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return ((e) =>
                                            _vm.handleAgeEnter(
                                              e,
                                              "minAge"
                                            )).apply(null, arguments)
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "el-range-separator" },
                                      [_vm._v("-")]
                                    ),
                                    _c("input", {
                                      ref: "maxAgeInput",
                                      staticClass: "el-range-input",
                                      attrs: {
                                        type: "text",
                                        autocomplete: "off",
                                        placeholder: "不限",
                                        name: "",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.isAgeInputFocused = true
                                        },
                                        blur: (e) =>
                                          _vm.handleAgeBlur(e, "maxAge"),
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return ((e) =>
                                            _vm.handleAgeEnter(
                                              e,
                                              "maxAge"
                                            )).apply(null, arguments)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { staticClass: "form-select-item" },
                              [
                                _c("span", { staticClass: "form-item-label" }, [
                                  _vm._v("性别"),
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "search-input",
                                    attrs: {
                                      filterable: "",
                                      "popper-class": "form-select-popover",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.addParams },
                                    model: {
                                      value: _vm.candidateSearch.gender,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.candidateSearch,
                                          "gender",
                                          $$v
                                        )
                                      },
                                      expression: "candidateSearch.gender",
                                    },
                                  },
                                  _vm._l(
                                    _vm.genderList,
                                    function (genderIt, index) {
                                      return _c("el-option", {
                                        key: "gender-" + index,
                                        attrs: {
                                          label: genderIt.label,
                                          value: genderIt.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "form-select-item new-first-city-form-select-item",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "form-item-label new-first-city-label",
                                  },
                                  [_vm._v("看新一线机会")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "el-range-city-select" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "thinkingTimeSelect",
                                        attrs: {
                                          "popper-class": "city-select-shield",
                                          multiple: "",
                                          placeholder: "选择时间",
                                        },
                                        on: {
                                          "visible-change":
                                            _vm.thinkingTimeVisibleChange,
                                        },
                                        model: {
                                          value: _vm.thinkingTime,
                                          callback: function ($$v) {
                                            _vm.thinkingTime = $$v
                                          },
                                          expression: "thinkingTime",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.timeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "select-btns-wrap" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn reset",
                                                on: { click: _vm.resetTime },
                                              },
                                              [_vm._v("重置")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn sure",
                                                on: { click: _vm.onSubmitTime },
                                              },
                                              [_vm._v("确定")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "el-range-separator" },
                                      [_vm._v("-")]
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        ref: "thinkingCitySelect",
                                        attrs: {
                                          "popper-class": "city-select-shield",
                                          multiple: "",
                                          placeholder: "选择城市",
                                        },
                                        on: {
                                          change: _vm.selectNewFirstCityChange,
                                          "visible-change":
                                            _vm.thinkingCityVisibleChange,
                                        },
                                        model: {
                                          value: _vm.thinkingCity,
                                          callback: function ($$v) {
                                            _vm.thinkingCity = $$v
                                          },
                                          expression: "thinkingCity",
                                        },
                                      },
                                      [
                                        _vm._l(_vm.cityList, function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }),
                                        _c(
                                          "el-option",
                                          { attrs: { value: _vm.other } },
                                          [_vm._v("其他")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "select-btns-wrap" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn reset",
                                                on: { click: _vm.resetCity },
                                              },
                                              [_vm._v("重置")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "btn sure",
                                                on: { click: _vm.onSubmitCity },
                                              },
                                              [_vm._v("确定")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("el-form-item", {
                              staticClass: "form-select-item",
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "search-label-item" }, [
                    _c(
                      "div",
                      { staticClass: "search-label-item-left" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "search-input-label",
                            attrs: { label: "我未浏览：" },
                          },
                          [
                            _c(
                              "el-dropdown",
                              {
                                attrs: { placement: "bottom-start" },
                                on: { command: _vm.changeNoViewTime },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-dropdown-link",
                                    class: !_vm.lastViewTimeText
                                      ? "el-dropdown-link-empty"
                                      : "",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.lastViewTimeText || "请选择")
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: 1 } },
                                      [_vm._v("三个月内")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: 2 } },
                                      [_vm._v("六个月内")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: 0 } },
                                      [_vm._v("全部时间")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "search-input-label",
                            attrs: { label: "排除设置：" },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                on: { change: _vm.changeExcludeCondition },
                                model: {
                                  value:
                                    _vm.candidateSearch
                                      .isExcludeOnboardGuarantee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.candidateSearch,
                                      "isExcludeOnboardGuarantee",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "candidateSearch.isExcludeOnboardGuarantee",
                                },
                              },
                              [_vm._v("排除在保护期的人选")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                on: { change: _vm.changeExcludeCondition },
                                model: {
                                  value:
                                    _vm.candidateSearch.isExcludeEmptyMobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.candidateSearch,
                                      "isExcludeEmptyMobile",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "candidateSearch.isExcludeEmptyMobile",
                                },
                              },
                              [_vm._v("排除无效号码")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "search-input-label",
                            attrs: { label: "背景情况：" },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.changeBackground(0)
                                  },
                                },
                                model: {
                                  value: _vm.candidateSearch.isFamousCompany,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.candidateSearch,
                                      "isFamousCompany",
                                      $$v
                                    )
                                  },
                                  expression: "candidateSearch.isFamousCompany",
                                },
                              },
                              [_vm._v("名企")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  value:
                                    _vm.candidateSearch.educationTags.length >
                                    0,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeBackground(1)
                                  },
                                },
                              },
                              [_vm._v("985/211")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-label-item-right" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              trigger: "hover",
                              "popper-class": "saved-search",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "use-saved-btn",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  "\n                                    使用已保存搜索条件\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "list-dialog",
                                class:
                                  _vm.searchFiltersList.length == 0
                                    ? "list-dialog-empty"
                                    : "",
                              },
                              [
                                _c("div", { staticClass: "list-dialog-body" }, [
                                  _c(
                                    "div",
                                    { staticClass: "body-scroll" },
                                    [
                                      _vm._l(
                                        _vm.searchFiltersList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: "searchFilters_" + index,
                                              staticClass: "search-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-l" },
                                                _vm._l(
                                                  item.filters,
                                                  function (
                                                    filterItem,
                                                    subIndex
                                                  ) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.isShowFilterTag(
                                                                filterItem,
                                                                item.filters
                                                              ),
                                                            expression:
                                                              "isShowFilterTag(filterItem, item.filters)",
                                                          },
                                                        ],
                                                        key:
                                                          "filterItem_" +
                                                          subIndex,
                                                      },
                                                      [
                                                        [
                                                          "locations",
                                                          "expectationLocations",
                                                        ].includes(
                                                          filterItem.title
                                                        )
                                                          ? [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    _vm.generateFilterLocationStr(
                                                                      item.filters
                                                                    )
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    filterItem.text
                                                                  ) +
                                                                  "：" +
                                                                  _vm._s(
                                                                    filterItem.value
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "item-r" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.useSearchRecord(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("使用")]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteSearchRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm.searchFiltersList.length == 0
                                        ? _c(
                                            "div",
                                            { staticClass: "empty-data" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "empty-data-img",
                                                attrs: {
                                                  href: "#icon-weibaocunsousuotiaojian1",
                                                },
                                              }),
                                              _c("p", [
                                                _vm._v("暂无保存任何搜索条件"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.isFirmSearch
                ? _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabType == 2,
                          expression: "tabType == 2",
                        },
                      ],
                      ref: "candidateSearchForm",
                      staticClass: "search-form search-form-normal",
                      attrs: {
                        size: "mini",
                        inline: true,
                        model: _vm.candidateSearch,
                        rules: _vm.candidateSearchRules,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isRecommendTipShow,
                              expression: "isRecommendTipShow",
                            },
                          ],
                          staticClass: "search-tip",
                        },
                        [
                          _c("span", { staticClass: "tip-title" }, [
                            _c("i", { staticClass: "icon-font icon-tips" }),
                            _vm._v(
                              "\n                            找人技巧\n                        "
                            ),
                          ]),
                          _c("p", { staticClass: "tip-content" }, [
                            _vm._v(
                              "\n                            你可以在这里找到所有曾被推荐过的候选人。"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            根据他们被推荐的职位，找到你可能想要的人。"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            比如，试试看找一下去年被推荐到北京的JAVA工程师？\n                        "
                            ),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.setLocalStorage("recommendTip")
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "form-select-item" },
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("推荐时间"),
                          ]),
                          _c("el-date-picker", {
                            staticClass: "search-input",
                            attrs: {
                              align: "left",
                              size: "small",
                              type: "daterange",
                              format: "yyyyMMdd",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.candidateTimeOption,
                              "popper-class": "candidate-data-range",
                            },
                            on: { change: _vm.changeRecommendTime },
                            model: {
                              value: _vm.recommendTime,
                              callback: function ($$v) {
                                _vm.recommendTime = $$v
                              },
                              expression: "recommendTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("推荐职位"),
                          ]),
                          _c("el-autocomplete", {
                            staticClass: "search-input",
                            attrs: {
                              "popper-class": "search-autocomplete-popper",
                              "fetch-suggestions":
                                _vm.getRecommendJobNameSearchList,
                            },
                            on: {
                              blur: _vm.handleRecommendJobNameBlur,
                              select: _vm.handleRecommendJobNameSelect,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                              input: function ($event) {
                                return _vm.handleRecommendJobNameInput.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "name",
                                          class: {
                                            selected:
                                              item.itemValue ==
                                              _vm.candidateSearch
                                                .recommendJobName,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.itemText) +
                                              "\n                                    ("
                                          ),
                                          _c("i", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.count,
                                                  "",
                                                  0
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            ")\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1446652517
                            ),
                            model: {
                              value: _vm.recommendJobName,
                              callback: function ($$v) {
                                _vm.recommendJobName = $$v
                              },
                              expression: "recommendJobName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "form-select-item" },
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("推荐城市"),
                          ]),
                          _c("city-select", {
                            ref: "citySelect",
                            on: { citySelected: _vm.handleCitySelected },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("客户"),
                          ]),
                          _c("customer-select", {
                            ref: "customerSelect",
                            attrs: { placeholder: "" },
                            on: {
                              customerSelected: _vm.handleCustomerSelected,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tabType == 2
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", { staticClass: "form-item-label" }, [
                                _vm._v("推荐人"),
                              ]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    "popper-class": "team-member-popover",
                                    placement: "bottom-start",
                                    width: "700",
                                    trigger: "click",
                                  },
                                  model: {
                                    value: _vm.teamMemberPopoverVisible,
                                    callback: function ($$v) {
                                      _vm.teamMemberPopoverVisible = $$v
                                    },
                                    expression: "teamMemberPopoverVisible",
                                  },
                                },
                                [
                                  _c("team-member-select", {
                                    ref: "teamSelect",
                                    attrs: {
                                      isAllMembers: true,
                                      isShowDimission: false,
                                      visible: _vm.teamMemberPopoverVisible,
                                      selected: _vm.selectedCreatorIds,
                                    },
                                    on: { "item-select-cb": _vm.membersSelect },
                                  }),
                                  _c("el-input", {
                                    staticClass: "search-input has-team-select",
                                    attrs: {
                                      slot: "reference",
                                      readonly: true,
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.recommenders,
                                      callback: function ($$v) {
                                        _vm.recommenders = $$v
                                      },
                                      expression: "recommenders",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "search-label-item-right inline" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  trigger: "hover",
                                  "popper-class": "saved-search",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "use-saved-btn",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    使用已保存搜索条件\n                                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-dialog",
                                    class:
                                      _vm.searchFiltersList.length == 0
                                        ? "list-dialog-empty"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-dialog-body" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "body-scroll" },
                                          [
                                            _vm._l(
                                              _vm.searchFiltersList,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "searchFilters_" + index,
                                                    staticClass: "search-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "item-l" },
                                                      _vm._l(
                                                        item.filters,
                                                        function (
                                                          filterItem,
                                                          subIndex
                                                        ) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.isShowFilterTag(
                                                                      filterItem,
                                                                      item.filters
                                                                    ),
                                                                  expression:
                                                                    "isShowFilterTag(filterItem, item.filters)",
                                                                },
                                                              ],
                                                              key:
                                                                "filterItem_" +
                                                                subIndex,
                                                            },
                                                            [
                                                              [
                                                                "locations",
                                                                "expectationLocations",
                                                              ].includes(
                                                                filterItem.title
                                                              )
                                                                ? [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          _vm.generateFilterLocationStr(
                                                                            item.filters
                                                                          )
                                                                        ) +
                                                                        "\n                                                        "
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          filterItem.text
                                                                        ) +
                                                                        "：" +
                                                                        _vm._s(
                                                                          filterItem.value
                                                                        ) +
                                                                        "\n                                                        "
                                                                    ),
                                                                  ],
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "item-r" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:;",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.useSearchRecord(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("使用")]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:;",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSearchRecord(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm.searchFiltersList.length == 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "empty-data" },
                                                  [
                                                    _c("font-icon", {
                                                      staticClass:
                                                        "empty-data-img",
                                                      attrs: {
                                                        href: "#icon-weibaocunsousuotiaojian1",
                                                      },
                                                    }),
                                                    _c("p", [
                                                      _vm._v(
                                                        "暂无保存任何搜索条件"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item"),
                      _c("el-form-item"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isFirmSearch
                ? _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabType == 3,
                          expression: "tabType == 3",
                        },
                      ],
                      ref: "candidateSearchForm",
                      staticClass: "search-form search-form-normal",
                      attrs: {
                        size: "mini",
                        inline: true,
                        model: _vm.candidateSearch,
                        rules: _vm.candidateSearchRules,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isRecordTipShow,
                              expression: "isRecordTipShow",
                            },
                          ],
                          staticClass: "search-tip",
                        },
                        [
                          _c("span", { staticClass: "tip-title" }, [
                            _c("i", { staticClass: "icon-font icon-tips" }),
                            _vm._v(
                              "\n                            找人技巧\n                        "
                            ),
                          ]),
                          _c("p", { staticClass: "tip-content" }, [
                            _vm._v(
                              "\n                            你可以在这里找到所有同事寻访过的候选人。"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            寻访记录里存在大量候选人的重要信息，"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            来看看哪些正是你需要的人吧！\n                        "
                            ),
                          ]),
                          _c("i", {
                            staticClass: "icon-font icon-quxiao",
                            on: {
                              click: function ($event) {
                                return _vm.setLocalStorage("recordTip")
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "form-select-item" },
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("寻访时间"),
                          ]),
                          _c("el-date-picker", {
                            staticClass: "search-input",
                            attrs: {
                              align: "left",
                              size: "small",
                              type: "daterange",
                              format: "yyyyMMdd",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "picker-options": _vm.candidateTimeOption,
                              "popper-class": "candidate-data-range",
                            },
                            on: { change: _vm.changeInquiryTime },
                            model: {
                              value: _vm.inquiryLogTime,
                              callback: function ($$v) {
                                _vm.inquiryLogTime = $$v
                              },
                              expression: "inquiryLogTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("span", { staticClass: "form-item-label" }, [
                            _vm._v("寻访内容"),
                          ]),
                          _c("el-input", {
                            staticClass: "search-input",
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParams.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.candidateSearch.inquiryLogContent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.candidateSearch,
                                  "inquiryLogContent",
                                  $$v
                                )
                              },
                              expression: "candidateSearch.inquiryLogContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tabType == 3
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", { staticClass: "form-item-label" }, [
                                _vm._v("寻访人"),
                              ]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    "popper-class": "team-member-popover",
                                    placement: "bottom-start",
                                    width: "700",
                                    trigger: "click",
                                  },
                                  model: {
                                    value: _vm.teamMemberPopoverVisible,
                                    callback: function ($$v) {
                                      _vm.teamMemberPopoverVisible = $$v
                                    },
                                    expression: "teamMemberPopoverVisible",
                                  },
                                },
                                [
                                  _c("team-member-select", {
                                    ref: "teamSelect",
                                    attrs: {
                                      isAllMembers: true,
                                      isShowDimission: false,
                                      visible: _vm.teamMemberPopoverVisible,
                                      selected: _vm.selectedCreatorIds,
                                    },
                                    on: { "item-select-cb": _vm.membersSelect },
                                  }),
                                  _c("el-input", {
                                    staticClass: "search-input has-team-select",
                                    attrs: {
                                      slot: "reference",
                                      readonly: true,
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.inquiryLogCreators,
                                      callback: function ($$v) {
                                        _vm.inquiryLogCreators = $$v
                                      },
                                      expression: "inquiryLogCreators",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "search-label-item-right inline" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  trigger: "hover",
                                  "popper-class": "saved-search",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "use-saved-btn",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    使用已保存搜索条件\n                                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-dialog",
                                    class:
                                      _vm.searchFiltersList.length == 0
                                        ? "list-dialog-empty"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-dialog-body" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "body-scroll" },
                                          [
                                            _vm._l(
                                              _vm.searchFiltersList,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "searchFilters_" + index,
                                                    staticClass: "search-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "item-l" },
                                                      _vm._l(
                                                        item.filters,
                                                        function (
                                                          filterItem,
                                                          subIndex
                                                        ) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.isShowFilterTag(
                                                                      filterItem,
                                                                      item.filters
                                                                    ),
                                                                  expression:
                                                                    "isShowFilterTag(filterItem, item.filters)",
                                                                },
                                                              ],
                                                              key:
                                                                "filterItem_" +
                                                                subIndex,
                                                            },
                                                            [
                                                              [
                                                                "locations",
                                                                "expectationLocations",
                                                              ].includes(
                                                                filterItem.title
                                                              )
                                                                ? [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          _vm.generateFilterLocationStr(
                                                                            item.filters
                                                                          )
                                                                        ) +
                                                                        "\n                                                        "
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          filterItem.text
                                                                        ) +
                                                                        "：" +
                                                                        _vm._s(
                                                                          filterItem.value
                                                                        ) +
                                                                        "\n                                                        "
                                                                    ),
                                                                  ],
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "item-r" },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:;",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.useSearchRecord(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("使用")]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:;",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSearchRecord(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm.searchFiltersList.length == 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "empty-data" },
                                                  [
                                                    _c("font-icon", {
                                                      staticClass:
                                                        "empty-data-img",
                                                      attrs: {
                                                        href: "#icon-weibaocunsousuotiaojian1",
                                                      },
                                                    }),
                                                    _c("p", [
                                                      _vm._v(
                                                        "暂无保存任何搜索条件"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "search-input-label job-hunting-will",
                          attrs: { label: "求职意愿：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "search-input radio-list",
                              on: { change: _vm.changeJobHoppingOccasion },
                              model: {
                                value: _vm.candidateSearch.jobHoppingOccasion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.candidateSearch,
                                    "jobHoppingOccasion",
                                    $$v
                                  )
                                },
                                expression:
                                  "candidateSearch.jobHoppingOccasion",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "看职业机会" } },
                                [_vm._v("看职业机会")]
                              ),
                              _c("el-radio", { attrs: { label: "离职状态" } }, [
                                _vm._v("离职状态"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "6个月内不考虑" } },
                                [_vm._v("6个月内不考虑")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "12个月内不考虑" } },
                                [_vm._v("12个月内不考虑")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm.isShowSearchCondition
      ? _c("div", { staticClass: "search-param-container" }, [
          _c(
            "div",
            { staticClass: "search-param-list" },
            [
              _vm._v("\n            您的搜索结果数：\n            "),
              _vm._l(_vm.searchConditions, function (search, index) {
                return _c(
                  "el-tag",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowTag(search),
                        expression: "isShowTag(search)",
                      },
                    ],
                    key: index,
                    staticClass: "search-param-item",
                    attrs: { closable: "", effect: "plain" },
                    on: {
                      close: function ($event) {
                        return _vm.removeLabel(search)
                      },
                    },
                  },
                  [
                    ["locations", "expectationLocations"].includes(search.title)
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.generateLocationStr()) +
                              "\n                "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                    " +
                              _vm._s(search.text) +
                              ": " +
                              _vm._s(search.value) +
                              "\n                "
                          ),
                        ],
                  ],
                  2
                )
              }),
              _c("span", { staticClass: "candidate-total" }, [
                _vm._v("\n                共 "),
                _c("span", { staticClass: "text-primary" }, [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.candidateTotal, "", 0))),
                ]),
                _vm._v(" 人\n            "),
              ]),
              _c(
                "span",
                {
                  staticClass: "clear-search",
                  on: { click: _vm.handleClearSearch },
                },
                [
                  _c("i", { staticClass: "el-icon-delete" }),
                  _vm._v("\n                清空搜索条件\n            "),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "clear-search text-primary",
                  attrs: { disabled: _vm.saveSearchLoading },
                  on: { click: _vm.handleSaveSearch },
                },
                [
                  _c("font-icon", { attrs: { href: "#icon-baocun" } }),
                  _vm._v("\n                保存当前搜索条件\n            "),
                ],
                1
              ),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "search-param-container" }),
    _c(
      "div",
      { staticClass: "work-hunting" },
      [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "div",
              { staticClass: "batch-option" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("\n                    全选\n                ")]
                ),
                _c("span", { staticClass: "info-text" }, [
                  _vm._v("已选择"),
                  _c("i", [_vm._v(_vm._s(_vm.checkedOptions.length))]),
                  _vm._v("位候选人"),
                ]),
                _c(
                  "el-button",
                  {
                    class: {
                      "batch-btn": true,
                      "batch-view": _vm.checkedOptions.length > 0,
                    },
                    attrs: { disabled: _vm.checkedOptions.length == 0 },
                    on: { click: _vm.batchView },
                  },
                  [_vm._v("\n                    批量查看\n                ")]
                ),
              ],
              1
            ),
            _c(
              "el-checkbox",
              {
                on: { change: _vm.addParams },
                model: {
                  value: _vm.candidateSearch.isJobHunting,
                  callback: function ($$v) {
                    _vm.$set(_vm.candidateSearch, "isJobHunting", $$v)
                  },
                  expression: "candidateSearch.isJobHunting",
                },
              },
              [_vm._v("\n                只看有求职意向的人\n            ")]
            ),
          ],
          1
        ),
        _c(
          "el-dropdown",
          {
            staticClass: "sort-dropdown",
            attrs: { trigger: "click" },
            on: { command: _vm.handleSort },
          },
          [
            _c("span", [
              _vm._v("\n                排序方式："),
              _c("i", { staticClass: "sort-text" }),
              _vm._v(_vm._s(_vm.sortText) + "\n                "),
              _c("i", { staticClass: "el-icon-arrow-down sort-icon" }),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "sort-dropdown-menu",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    class: { active: _vm.sortText == "更新时间排序" },
                    attrs: { command: 1 },
                  },
                  [
                    _vm._v(
                      "\n                    更新时间排序\n                "
                    ),
                  ]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    class: { active: _vm.sortText == "综合排序" },
                    attrs: { command: 0 },
                  },
                  [_vm._v("\n                    综合排序\n                ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }